import React, { useEffect, useState } from "react";
import { cmsExamGet, getCmsSessionDetails } from "../graphql/queries";
import { API } from "aws-amplify";
import {
  Box,
  Card,
  CardBody,
  TextInput,
  Text,
  Button,
  CardHeader,
  TextArea,
  Select,
  CardFooter,
  Notification,
  Paragraph,
} from "grommet";
import { updateCmsRegistration, updateCmsSession } from "../graphql/mutations";

function CmsNotesCard(props) {
  const [exam, setExam] = useState({});
  const [registration, setRegistration] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [notes, setNotes] = useState([
    {
      note: "",
      severity: "",
    },
  ]);
  const [candidateId, setCandidateId] = useState(null);
  const [proctor, setProctor] = useState("");
  const [caveonLinks, setCaveonLinks] = useState(["", ""]);
  const [saved, setSaved] = useState(false);
  const [finished, setFinished] = useState(false);
  const [isLinks, setIsLinks] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  //get session

  useEffect(() => {
    async function getSession() {
      try {
        const data = await API.graphql({
          query: getCmsSessionDetails,
          variables: {
            registration_id: props.item.registration_id,
            exam_id: props.item.exam_id,
            session_id: props.item.session_id,
          },
          authMode: "API_KEY",
        });
        console.log("data", data.data);
        setExam(data.data.getCmsExam);
        setRegistration(data.data.getCmsRegistration);
        var session = data.data.getCmsSession;
        setName(session.first_name + " " + session.last_name);
        setEmail(session.email_address);
        if (session.start_time != null) {
          setStart(session.start_time);
        }
        if (session.end_time != null) {
          setEnd(session.end_time);
        }
        if (session.notes != null) {
          setNotes(session.notes);
        }
        if (session.proctor_id != null) {
          setProctor(session.proctor_id);
        }
        if (session.candidate_id != null) {
          setCandidateId(session.candidate_id);
        }
        if (session.progress !== "proctor-data-finalized") {
          await API.graphql({
            query: updateCmsRegistration,
            variables: {
              input: {
                registration_id: props.item.registration_id,
                status: "in-progress",
              },
            },
            authMode: "API_KEY",
          });
        }
      } catch (error) {
        console.log(error);
      }
    }

    getSession();
  }, []);

  function handleStart() {
    const date = new Date();
    setStart(date.toISOString());
  }

  function handleEnd() {
    const date = new Date();
    setEnd(date.toISOString());
  }

  async function getProctorLinks() {
    try {
      const data = await API.graphql({
        query: cmsExamGet,
        variables: {
          exam_id: exam.sei_exam_id,
          delivery: registration.sei_delivery_id,
        },
        authMode: "API_KEY",
      });
      console.log(data.data);
      const str = JSON.parse(data.data.cmsExamGet);
      setCaveonLinks([
        "https://scorpion.caveon.com/proctor/" + str.proctor_token,
        "https://scorpion.caveon.com/take?launch_token=" + str.launch_token,
      ]);
      setIsLinks(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSave() {
    try {
      await API.graphql({
        query: updateCmsSession,
        variables: {
          input: {
            session_id: props.item.session_id,
            start_time: start,
            end_time: end,
            notes: notes,
            proctor_id: proctor,
          },
        },
        authMode: "API_KEY",
      });
      setSaved(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleFinish() {
    try {
      await API.graphql({
        query: updateCmsSession,
        variables: {
          input: {
            session_id: props.item.session_id,
            start_time: start,
            end_time: end,
            notes: notes,
            proctor_id: proctor,
            progress: "proctor-data-finalized",
          },
        },
        authMode: "API_KEY",
      });
      await API.graphql({
        query: updateCmsRegistration,
        variables: {
          input: {
            registration_id: props.item.registration_id,
            status: "proctor-data-finalized",
          },
        },
        authMode: "API_KEY",
      });
      setFinished(true);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      {saved && (
        <Notification
          toast
          status="normal"
          title="Saved"
          message="Your changes have been saved."
          onClose={() => setSaved(false)}
        />
      )}
      {isCopied && (
        <Notification
          toast
          status="normal"
          title="Copied"
          message="The link has been copied to your clipboard."
          onClose={() => setIsCopied(false)}
        />
      )}
      <Card background={{ color: "background-front" }} width="large">
        <CardHeader
          align="center"
          direction="row"
          flex={false}
          justify="center"
          gap="medium"
        ></CardHeader>
        <CardBody pad="small" gap="xxsmall">
          <Box align="center" justify="center">
            <TextInput disabled value={name} />
          </Box>
          <Box align="center" justify="center">
            <TextInput placeholder="Email" disabled value={email} />
          </Box>
          <Box align="center" justify="center">
            <TextInput placeholder="Exam" disabled value={exam.name} />
          </Box>
          <Box align="center" justify="center">
            <TextInput
              placeholder="Proctor"
              value={proctor}
              onChange={(e) => setProctor(e.target.value)}
            />
          </Box>
          <Box align="center" justify="center" direction="column">
            <Text>Proctor Instructions:</Text>
            {exam.proctor_instructions != null && (
              <Text>{exam.proctor_instructions}</Text>
            )}
            {candidateId != null && (
              <Box direction="column" gap="small">
                <Text>Candidate ID: {candidateId}</Text>
                <Text>Verify Candidate ID matches printed materials</Text>
              </Box>
            )}
          </Box>
          <Box align="center" justify="center" direction="column">
            <Text>Candidate Instructions:</Text>
            {exam.candidate_instructions != null && (
              <Text>{exam.candidate_instructions}</Text>
            )}
          </Box>
          <Box align="center" justify="center" direction="column">
            {exam.exam_url != null && <Text>Exam URL: {exam.exam_url}</Text>}
          </Box>
          <Box align="center" justify="center" direction="column">
            {exam.exam_type == "caveon" && (
              <Box pad="xxsmall" gap="small">
                <Text>Exam Type: Caveon</Text>
                <Button
                  label="Generate Links"
                  primary
                  onClick={getProctorLinks}
                />
                <Box overflow="auto">
                  <Button
                    label="Open Proctor URL"
                    primary
                    onClick={() => window.open(caveonLinks[0], "_blank")}
                    disabled={!isLinks}
                  />
                </Box>
                <Box overflow="auto">
                  <Button
                    label="Copy Candidate URL"
                    primary
                    onClick={() => {
                      navigator.clipboard.writeText(caveonLinks[1]);
                      setIsCopied(true);
                    }}
                    disabled={!isLinks}
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box align="center" justify="between" direction="row" gap="xxsmall">
            <TextInput placeholder="Start Time" value={start} />
            <Box align="center" justify="center">
              <Button label="Set Start Time" onClick={handleStart} />
            </Box>
          </Box>
          <Box align="center" justify="between" direction="row" gap="xxsmall">
            <TextInput placeholder="End Time" value={end} />
            <Box align="center" justify="center">
              <Button label="Set End Time" onClick={handleEnd} />
            </Box>
          </Box>
          <Box>
            {notes.map((item, index) => (
              <Box key={index}>
                <Box
                  align="stretch"
                  justify="center"
                  direction="row"
                  gap="xxsmall"
                >
                  <TextArea
                    placeholder="Single Note"
                    value={notes[index].note}
                    onChange={(event) => {
                      let newNotes = [...notes];
                      newNotes[index].note = event.target.value;
                      setNotes(newNotes);
                    }}
                  />
                  <Select
                    options={[
                      "General Comment",
                      "General Issue",
                      "Possible Infraction",
                      "Infraction",
                    ]}
                    placeholder="Severity"
                    value={notes[index].severity}
                    onChange={({ option }) => {
                      let newNotes = [...notes];
                      newNotes[index].severity = option;
                      setNotes(newNotes);
                    }}
                  />
                </Box>
              </Box>
            ))}
            <Box align="center" justify="center">
              <Button
                label="Add a note"
                onClick={() => {
                  setNotes([...notes, { note: "", severity: "" }]);
                }}
              />
            </Box>
          </Box>
        </CardBody>
        <CardFooter
          align="center"
          direction="row"
          flex={false}
          justify="center"
          gap="medium"
          pad="small"
        >
          <Button label="Save" disabled={finished} onClick={handleSave} />
          <Button
            label="Finish Tester"
            primary
            disabled={finished}
            onClick={handleFinish}
          />
        </CardFooter>
      </Card>
    </div>
  );
}
export default CmsNotesCard;
