import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Heading,
  CardBody,
  TextInput,
  Select,
  CardFooter,
  Button,
  //DateInput,
  Box,
  Notification,
  Text,
  Spinner,
} from "grommet";
import { v4 as uuidv4 } from "uuid";
import { API } from "aws-amplify";
import {
  addCmsAppointmentEmail,
  updateCmsRegistration,
} from "../graphql/mutations";

import {
  //DateTimePicker,
  //DesktopDateTimePicker,
  LocalizationProvider,
  MobileDateTimePicker,
  //StaticDateTimePicker,
  renderDigitalClockTimeView,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

var minMax = require("dayjs/plugin/minMax");
dayjs.extend(minMax);

// all times from midnight to 11:30pm

function CmsScheduleCard(props) {
  const [newExamDate, setNewExamDate] = useState("");
  const [dateError, setDateError] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [windowStart, setWindowStart] = useState("");
  const [windowEnd, setWindowEnd] = useState("");
  const [err, setErr] = useState(false);
  const [timeError, setTimeError] = useState(false);
  //const [proceed, setProceed] = useState(false);
  const [selectionError, setSelectionError] = useState(false);

  const [twoFourError, setTwoFourError] = useState(false);
  const [unavailableError, setUnavailableError] = useState(false);
  // block off date 01/28/2023 11:30 AM Mountain Time to 01/28/2023 5:30 PM Mountain Time

  useEffect(() => {
    if (props.window) {
      const windowArray = atob(props.window).split("&");

      /*
      Buffer.from(props.window, "base64")
        .toString("ascii")
        .split("&");
        */
      console.log("WINDOW ARRAY", windowArray);
      console.log("start", props.start);
      console.log("end", props.end);

      setWindowStart(
        dayjs.max([
          dayjs().add(1, "day"),
          dayjs(props.start),
          dayjs(windowArray[0]),
        ])
      );
      setWindowEnd(dayjs.min([dayjs(props.end), dayjs(windowArray[1])]));

      setNewExamDate(
        dayjs.max([
          dayjs().add(1, "day"),
          dayjs(props.start),
          dayjs(windowArray[0]),
        ])
      );
    } else {
      setWindowStart(dayjs.max([dayjs().add(1, "day"), dayjs(props.start)]));
      setWindowEnd(dayjs(props.end));
      setNewExamDate(dayjs.max([dayjs().add(1, "day"), dayjs(props.start)]));
    }

    if (props.appt !== null) {
      setNewExamDate(dayjs(props.appt));
    }

    setInProgress(true);
  }, []);

  async function handleSubmit() {
    console.log("Exam Date", dayjs(newExamDate).toDate().toISOString());
    let temp = dayjs(newExamDate).toDate();

    setInProgress(false);

    try {
      let stat;
      if (props.action === "reschedule") {
        stat = "rescheduled";
      } else if (props.action === "schedule") {
        stat = "scheduled";
      } else {
        stat = null;
      }

      const data = await API.graphql({
        query: updateCmsRegistration,
        variables: {
          input: {
            registration_id: props.registration,
            appointment: {
              appointment_id: uuidv4(),
              exam_date: temp.toISOString(),
              local_date: temp.toLocaleString(),
            },
            status: stat,
          },
        },
        authMode: "API_KEY",
      });
      console.log(data);
      const zap = await API.graphql({
        query: addCmsAppointmentEmail,
        variables: {
          body: {
            name: props.first + " " + props.last,
            email: props.email,
            exam: props.examName,
            date: temp.toISOString(),
          },
        },
        authMode: "API_KEY",
      });

      console.log(zap);

      if (data.data.updateCmsRegistration.appointment.exam_date !== null) {
        setSuccess(true);
        setLoading(false);
      } else {
        setInProgress(true);
        setLoading(false);
        setErr(true);
      }
    } catch (err) {
      console.log(err);
      setErr(true);
      setLoading(false);
      setInProgress(true);
    }
    /*
    //check that date and time are selected
    //check date is at least 24 hours in advance
    const nowMins = convertTimeToMinutes(currentTime);
    const selMins = convertTimeToMinutes(time);
    // get tomorrow's date
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const tomorrowDate = dateStringToISODateString(tomorrow);
    console.log(examDate);
    console.log("TOMORROW DATE", tomorrowDate);
    let proceed = true;
    if (examDate === "" || time === "") {
      setSelectionError(true);
      proceed = false;
    } else if (examDate < todayDate) {
      setDateError(true);
      proceed = false;
    } else if (examDate === todayDate) {
      setTwoFourError(true);
      proceed = false;
    } else if (examDate === tomorrowDate && nowMins > selMins) {
      setTwoFourError(true);
      proceed = false;
    }
    */
    /*
    if (proceed) {
      setLoading(true);
      setInProgress(false);
      // create local date object based off of selected date and time

      // convert selMins to to HH:MM:SS
      const hours = Math.floor(selMins / 60);
      const minutes = selMins % 60;
      let hoursString;
      let minutesString;
      if (hours < 10) {
        hoursString = "0" + hours;
      } else {
        hoursString = hours;
      }
      if (minutes < 10) {
        minutesString = "0" + minutes;
      } else {
        minutesString = minutes;
      }
      const timeString = hoursString + ":" + minutesString + ":00";

      const temp = new Date(examDate + " " + timeString);
      console.log("checks");
      console.log("TEMP", temp);
      console.log("ISO TEMP", temp.toISOString());
      console.log(examDate + " " + timeString);

      //make request

      try {
        let stat;
        if (props.action === "reschedule") {
          stat = "rescheduled";
        } else if (props.action === "schedule") {
          stat = "scheduled";
        } else {
          stat = null;
        }

        const data = await API.graphql({
          query: updateCmsRegistration,
          variables: {
            input: {
              registration_id: props.registration,
              appointment: {
                appointment_id: uuidv4(),
                exam_date: temp.toISOString(),
                local_date: examDate + " " + time,
              },
              status: stat,
            },
          },
          authMode: "API_KEY",
        });

        const zap = await API.graphql({
          query: addCmsAppointmentEmail,
          variables: {
            body: {
              name: props.first + " " + props.last,
              email: props.email,
              exam: props.examName,
              date: temp.toISOString(),
            },
          },
          authMode: "API_KEY",
        });

        console.log(zap);

        if (data.data.updateCmsRegistration.appointment.exam_date !== null) {
          setSuccess(true);
          setLoading(false);
        } else {
          setInProgress(true);
          setLoading(false);
          setErr(true);
        }
      } catch (err) {
        console.log(err);
        setErr(true);
        setLoading(false);
        setInProgress(true);
      }

      // post data to zapier webhook
    } else {
      console.log("not proceeding");
      setTimeError(true);
    }
    */
  }

  return (
    <Box align="center" justify="center" pad="small">
      {dateError && (
        <Notification
          toast
          title="Error"
          status="critical"
          message="Date must be between start and end date, and not in the past."
          onClose={() => setDateError(false)}
        />
      )}
      {err && (
        <Notification
          toast
          title="Error"
          status="critical"
          message="There was an error updating the appointment"
          onClose={() => setErr(false)}
        />
      )}
      {timeError && (
        <Notification
          toast
          title="Error"
          status="critical"
          message="Time and Date must be at least 24 hours in the future."
          onClose={() => setTimeError(false)}
        />
      )}
      {selectionError && (
        <Notification
          toast
          title="Error"
          status="critical"
          message="Please select a date and/or a time."
          onClose={() => setSelectionError(false)}
        />
      )}
      {twoFourError && (
        <Notification
          toast
          title="Error"
          status="critical"
          message="Please select a time at least 24 hours in advance."
          onClose={() => setTwoFourError(false)}
        />
      )}
      {unavailableError && (
        <Notification
          toast
          title="Error"
          status="critical"
          message="That date is not available. Please select another date."
          onClose={() => setUnavailableError(false)}
        />
      )}
      <Card
        background={{ color: "background-front" }}
        pad="small"
        gap="medium"
        size="medium"
        overflow="auto"
      >
        {inProgress && (
          <Box>
            <CardHeader
              align="center"
              direction="row"
              flex={false}
              justify="center"
              gap="medium"
            >
              <Heading
                textAlign="center"
                size="small"
                level="3"
                truncate={false}
              >
                Please select an exam date and start time
              </Heading>
            </CardHeader>
            <CardBody pad="small" gap="xxsmall">
              <TextInput
                value={props.first + " " + props.last}
                placeholder="First Last"
                disabled
              />
              <TextInput placeholder="Email" value={props.email} disabled />
              <TextInput placeholder="Exam" value={props.examName} disabled />

              <Text weight="bold">Select a Date and Time</Text>
              <NewDatePicker
                startDate={windowStart}
                endDate={windowEnd}
                value={newExamDate}
                onChange={(date) => {
                  setNewExamDate(date);
                }}
              />
            </CardBody>
            <CardFooter
              align="center"
              direction="row"
              flex={false}
              justify="center"
              gap="medium"
              pad="small"
            >
              <Button
                label="Submit"
                primary
                size="large"
                onClick={handleSubmit}
              />
            </CardFooter>
          </Box>
        )}
        {loading && (
          <Box
            align="center"
            justify="center"
            pad="small"
            height="medium"
            width="medium"
          >
            <Spinner />
          </Box>
        )}
        {success && (
          <Box
            align="center"
            justify="center"
            pad="small"
            height="medium"
            width="medium"
          >
            <Text weight="bold" textAlign="center">
              Appointment Scheduled!
            </Text>
            <Text textAlign="center">
              If you have any questions about your appointment, please email
              schedule@monitoredu.com
            </Text>
          </Box>
        )}
      </Card>
    </Box>
  );
}

export default CmsScheduleCard;

function NewDatePicker({ startDate, endDate, value, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDateTimePicker
        disablePast={true}
        viewRenderers={{
          hours: renderDigitalClockTimeView,
          minutes: renderDigitalClockTimeView,
        }}
        value={dayjs(value)}
        onChange={onChange}
        minDateTime={dayjs(startDate)}
        maxDateTime={dayjs(endDate)}
      />
    </LocalizationProvider>
  );
}
