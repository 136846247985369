import { API } from "aws-amplify";
import { Box, Card, CardBody, Text } from "grommet";
import React, { useEffect, useState } from "react";
import CmsNotesCard from "../Components/CmsNotesCard";
import { getCmsCandidate, getCmsSessionByProgress } from "../graphql/queries";
import { updateCmsSession } from "../graphql/mutations";

function CmsSession() {
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [update, setUpdate] = useState(false);
  //call api Select component for selecting tester from the queue
  // on select check for a current session, create a new session if none exists and render the notes card
  // in the notes card get exam, user, and registration
  // update session through the notes card

  useEffect(() => {
    getData().then((res) => {
      res.forEach((itm) => {
        if (
          itm.first_name === null ||
          itm.last_name === null ||
          itm.email_address === null
        ) {
          setLoading(false);
          console.log("bad data found");
          getTesterData(itm.user_id, itm.session_id);
        }
      });
      setLoading(true);
    });
  }, [update]);

  async function getTesterData(user_id, session_id) {
    try {
      const data = await API.graphql({
        query: getCmsCandidate,
        variables: { user_id: user_id },
      });

      updateCandSession(
        session_id,
        data.data.getCmsCandidate.first_name,
        data.data.getCmsCandidate.last_name,
        data.data.getCmsCandidate.candidate_id,
        data.data.getCmsCandidate.email_address
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function updateCandSession(
    session_id,
    first_name,
    last_name,
    candidate_id,
    email_address
  ) {
    try {
      console.log("updating session");
      const data = await API.graphql({
        query: updateCmsSession,
        variables: {
          input: {
            session_id: session_id,
            first_name: first_name,
            last_name: last_name,
            candidate_id: candidate_id,
            email_address: email_address,
          },
        },
        authMode: "API_KEY",
      });

      setUpdate(!update);
    } catch (error) {
      console.log(error);
    }
  }

  async function getData() {
    try {
      const data = await API.graphql({
        query: getCmsSessionByProgress,
        variables: { progress: "queue", limit: 300 },
        authMode: "API_KEY",
      });
      console.log(data.data);
      if (data.data.sessionByProgress.items.length > 0) {
        setItems(data.data.sessionByProgress.items);

        if (data.data.sessionByProgress.nextToken) {
          const next = getNextData(
            data.data.sessionByProgress.items,
            data.data.sessionByProgress.nextToken
          );
          return next;
        } else {
          return data.data.sessionByProgress.items;
        }
      }
      setLoading(true);
    } catch (error) {
      console.log(error);
    }
  }

  async function getNextData(curr, nextToken) {
    try {
      const data = await API.graphql({
        query: getCmsSessionByProgress,
        variables: { progress: "queue", limit: 300, nextToken: nextToken },
        authMode: "API_KEY",
      });
      console.log(data.data);
      let temp = [...curr, ...data.data.sessionByProgress.items];
      if (data.data.sessionByProgress.items.length > 0) {
        setItems(temp);
        if (data.data.sessionByProgress.nextToken) {
          getNextData(temp, data.data.sessionByProgress.nextToken);
        } else {
          return temp;
        }
      }
      setLoading(true);
    } catch (error) {
      console.log(error);
    }
  }

  function handleCardClick(item) {
    setSelected([...selected, item]);
    if (!isSelected) {
      setIsSelected(true);
    }
    handleCardClose(item);
  }

  async function handleCardClose(item) {
    try {
      await API.graphql({
        query: updateCmsSession,
        variables: {
          input: {
            session_id: item.session_id,
            progress: "queue",
          },
        },
        authMode: "API_KEY",
      });
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <Box align="start" direction="row" justify="start" gap="small" id="box">
        <Box
          align="center"
          justify="start"
          direction="column"
          gap="small"
          background={{ color: "background-contrast" }}
          overflow="scroll"
          id="scrollbox"
        >
          <Box align="center" justify="center" fill="vertical" pad="xsmall">
            <Card background={{ color: "background-front" }} fill="vertical">
              <CardBody pad="small" justify="center">
                <Text>Queue {items.length}</Text>
              </CardBody>
            </Card>
          </Box>
          <Box direction="column" gap="small" overflow="scroll" pad="xsmall">
            {loading &&
              items.map((itm, idx) => {
                return (
                  <Box
                    hoverIndicator={{
                      background: {
                        color: "background-contrast",
                      },
                      elevation: "medium",
                    }}
                    onClick={() => handleCardClick(itm)}
                    key={idx}
                  >
                    <Card background={{ color: "background-front" }}>
                      <CardBody pad="small" direction="column">
                        <Text>
                          {itm.first_name} {itm.last_name}
                        </Text>
                        <Text>{itm.email_address}</Text>
                      </CardBody>
                    </Card>
                  </Box>
                );
              })}
          </Box>
        </Box>
        <Box align="center" justify="center" fill>
          <h2>FILL OUT FORM AND CLICK FINISH TESTER BUTTON</h2>
          {isSelected &&
            selected.map((itm, idx) => {
              return <CmsNotesCard key={itm.session_id} item={itm} />;
            })}
        </Box>
      </Box>
    </>
  );
}

export default CmsSession;
