import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { webcamRoomByDate } from "../graphql/queries";
import { WebcamValues } from "../Global/webcams";
import { Box, Heading, Main, Select, Text } from "grommet";
import OtWebcam from "../Components/OtWebcam";

function Webcam() {
  const [isWebRoom, setIsWebRoom] = useState(false);
  const [webcams, setWebcams] = useState(null);
  const [wRoom, setWRoom] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getWebcams();
  }, []);

  async function getWebcams() {
    try {
      const webcamData = await API.graphql(
        graphqlOperation(webcamRoomByDate, {
          sortDirection: "ASC",
          type: "WebcamRoom",
        })
      );
      const web = webcamData.data.WebcamRoomByDate.items;
      setWebcams(web);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }

  function handleWebChange(val) {
    const index = webcams.map((obj) => obj.id).indexOf(val);
    setWRoom(webcams[index]);
    setIsWebRoom(true);
  }

  return (
    <>
      <Box align="Center" justify="center" pad="small">
        <Heading textAlign="center" size="small">
          Webcam Rooms
        </Heading>
        <Box align="center" justify="center" direction="row">
          {isLoaded ? (
            <Select
              name="Select Webcam Room"
              placeholder="Select Webcam Room"
              labelKey={"ScCode"}
              valueKey={{ key: "id", reduce: true }}
              options={webcams}
              onChange={({ value: nextValue }) => handleWebChange(nextValue)}
            />
          ) : (
            <Box></Box>
          )}
        </Box>
        <Main
          fill="vertical"
          flex="grow"
          align="center"
          overflow="auto"
          responsive={true}
        >
          {isWebRoom ? (
            <>
              <OtWebcam
                rooms={WebcamValues.RoomA}
                embed={wRoom.embedCode}
                roomID={wRoom.name}
              />
              <OtWebcam
                rooms={WebcamValues.RoomB}
                embed={wRoom.embedCode}
                roomID={wRoom.name}
              />
            </>
          ) : (
            <Text>Please Select A Webcam Room</Text>
          )}
        </Main>
      </Box>
    </>
  );
}

export default Webcam;
