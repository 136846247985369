import { Auth, Hub } from "aws-amplify";
import React, { useState, useEffect, useContext } from "react";
import { createContext } from "react";

const UserContext = createContext({});

export default function AuthContext({ children }) {
  const [user, setUser] = useState(null);
  const [isUser, setIsUser] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        setIsUser(true);
        sessionStorage.setItem("user", JSON.stringify(user));
      })
      .catch(() => {
        setUser(null);
        setIsUser(false);
        if (sessionStorage.getItem("user")) {
          sessionStorage.removeItem("user");
        }
      });

    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signOut":
          setUser(null);
          setIsUser(false);
          sessionStorage.removeItem("user");
          break;
        default:
          break;
      }
    });
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, isUser, setIsUser }}>
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () => useContext(UserContext);
