import useTrackSubscription from "./useTrackSubscription";
import useAudioTrack from "./useAudioTrack";
import useVideoTrack from "./useVideoTrack";

export default function useTrack({ participant }) {
  const { audioTrack, videoTrack } = useTrackSubscription({ participant });
  const { audioOn } = useAudioTrack({ audioTrack });
  const { videoOn } = useVideoTrack({ videoTrack });

  return {
    audioTrack,
    videoTrack,
    audioOn,
    videoOn,
  };
}
