import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { Box, Heading, Text } from "grommet";
import TesterCard from "../Components/TesterCard";

function WebcamTesterPage() {
  const [isRoom, setIsRoom] = useState(false);
  const { search } = useLocation();
  const { embed, roomid } = queryString.parse(search);

  useEffect(() => {
    if (roomid != null && embed != null) {
      setIsRoom(true);
    }
  }, [roomid, embed]);

  return (
    <>
      <Box align="center">
        <Heading level="3" margin="none">
          Webcam Page
        </Heading>
        <Box>
          {isRoom ? (
            <TesterCard room={roomid} embed={embed} />
          ) : (
            <Text>Room Code Invalid</Text>
          )}
        </Box>
      </Box>
    </>
  );
}

export default WebcamTesterPage;
