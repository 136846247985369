import { useEffect, useState } from "react";
import { roomByDate, getTwilioAccessToken } from "../graphql/queries";
import { API } from "aws-amplify";
import { Box, Button, Select, Text } from "grommet";

import { useUser } from "../AuthContext";

import VideoProvider from "../testing-twill/src/components/VideoProvider";

const roomIds = [
  "SCA",
  "SCB",
  "SCC",
  "SCD",
  "SCE",
  "SCF",
  "SCG",
  "SCH",
  "SCI",
  "SCJ",
  "SCK",
  "SCL",
  "SCM",
  "SCN",
  "SCO",
  "SCP",
  "SCQ",
  "SCR",
  "SCS",
  "SCT",
];
// nums as strings 1 - 30
const nums = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
];

export default function TwilioSCViewer() {
  const [rooms, setRooms] = useState([]);
  const [proctor, setProctor] = useState(null);

  const user = useUser();

  const handleAddRoom = async () => {
    let identity = user.user.attributes.email.split("@")[0];
    let room;
    if (rooms.length < 1) {
      room = proctor + nums[0];
    } else if (rooms.length < 30) {
      room = proctor + nums[rooms.length];
    }

    try {
      const token = await API.graphql({
        query: getTwilioAccessToken,
        variables: {
          body: JSON.stringify({
            identity: identity + Math.random().toString(),
            room: room,
          }),
        },
      });

      if (rooms.length <= 30) {
        setRooms([
          ...rooms,
          { room: room, token: token.data.getTwilioAccessToken },
        ]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      align="center"
      justify="center"
      direction="column"
      pad="medium"
      gap="small"
      fill
    >
      <Box
        align="center"
        justify="center"
        direction="row"
        pad="small"
        gap="small"
      >
        <Select
          name="Select Proctor Room"
          placeholder="Select Proctor Room"
          options={roomIds}
          onChange={({ value: nextValue }) => {
            setProctor(nextValue);
            setRooms([]);
          }}
        />
        {proctor === null ? (
          <Box></Box>
        ) : (
          <Button label="Add Room" onClick={() => handleAddRoom()} />
        )}
      </Box>
      <Text size="large" weight="bold">
        Max 30 Rooms
      </Text>
      <Box
        align="center"
        justify="center"
        direction="row"
        gap="small"
        wrap={true}
        fill="horizontal"
      >
        {rooms.length > 0 &&
          rooms.map((itm, idx) => (
            /*
            <VideoProvider
              roomId={itm.room}
              token={itm.token}
              key={idx}
              participantType="proctor"
            />
            */
            <VideoProvider
              roomId={itm.room}
              token={itm.token}
              key={idx}
              participantType="proctor"
            />
          ))}
      </Box>
    </Box>
  );
}
