import React, { useEffect, useState, useRef } from "react";
import { Box, Spinner } from "grommet";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { API } from "aws-amplify";
import { getProlydianRegistrationLite } from "../graphql/queries";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

function ProlydianChat() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const tawkMessengerRef = useRef(null);
  const { search } = useLocation();
  const { propertyId, widgetId, registration } = queryString.parse(search);

  useEffect(() => {
    console.log(registration);

    getData();

    async function getData() {
      try {
        await API.graphql({
          query: getProlydianRegistrationLite,
          variables: { id: registration },
          authMode: "API_KEY",
        })
          .then((data) => {
            setName(
              data.data.getProlydianRegistration.firstName +
                " " +
                data.data.getProlydianRegistration.lastName
            );
            setEmail(data.data.getProlydianRegistration.email);
            //setIsLoaded(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      } finally {
        // setIsLoaded(true);
      }
    }
  }, []);

  useEffect(() => {
    if (isLoaded && tawkMessengerRef.current) {
      window.Tawk_API.setAttributes(
        {
          name: name,
          email: email,
        },
        function (error) {
          console.log(error);
        }
      );
    }
  }, [isLoaded]);

  const onload = async () => {
    console.log("Tawk Messenger Loaded");

    window.Tawk_API.maximize();
    /* window.Tawk_API.setAttributes({
      name: name,
      email: email,
    });
    tawkMessengerRef.current.setAttributes({
      name: name,
      email: email,
    });
    */
    setIsLoaded(true);
  };

  return (
    <Box align="center" justify="center" fill="horizontal" height="large">
      <TawkMessengerReact
        ref={tawkMessengerRef}
        propertyId={propertyId}
        widgetId={widgetId}
        onLoad={onload}
      />
    </Box>
  );
}

export default ProlydianChat;
