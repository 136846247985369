import React from "react";
import AudioTrack from "./AudioTrack";
import VideoTrack from "./VideoTrack";
import useTrack from "../hooks/useTrack";

function Participant({ participant, type }) {
  const { videoOn, audioOn, videoTrack, audioTrack } = useTrack({
    participant,
  });

  if (type === "local") {
    return (
      <div style={{ maxHeight: 100, maxWidth: 100 }}>
        {videoOn ? <VideoTrack track={videoTrack} /> : "video off"}
        <br />
        {audioOn ? <AudioTrack track={audioTrack} /> : "audio off"}
      </div>
    );
  } else {
    return (
      <div>
        {videoOn ? <VideoTrack track={videoTrack} /> : "video off"}
        <br />
        {audioOn ? <AudioTrack track={audioTrack} /> : "audio off"}
      </div>
    );
  }
}

export default Participant;
