import React from "react";
import AudioTrack from "./AudioTrack";
import VideoTrack from "./VideoTrack";
import useTrack from "../../useTrack";

function Participant({ participant }) {
  const { videoOn, audioOn, videoTrack, audioTrack } = useTrack({
    participant,
  });

  return (
    <div>
      {videoOn ? <VideoTrack track={videoTrack} /> : ""}
      <br />
      {audioOn ? <AudioTrack track={audioTrack} /> : ""}
    </div>
  );
}

export default Participant;
