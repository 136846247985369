import { useState, useCallback } from "react";
import { createLocalAudioTrack } from "twilio-video";

export default function useToggleMicrophone(room, initialState) {
  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState(initialState);

  const toggleMicrophone = useCallback(() => {
    if (room) {
      if (isMicrophoneEnabled) {
        room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.stop();
          publication.unpublish();
        });

        setIsMicrophoneEnabled(false);
      } else {
        createLocalAudioTrack().then((track) => {
          room.localParticipant.publishTrack(track);
          setIsMicrophoneEnabled(true);
        });
      }
    }
  }, [isMicrophoneEnabled, room]);

  return { isMicrophoneEnabled, toggleMicrophone };
}
