/*
Vonage Room Grid
*/
import { Box, Grid } from "grommet";
import React from "react";

function OtMobile({ rooms, embedCode, proc }) {
  const roomStyle = {
    width: "auto",
    height: "250px",
  };

  return (
    <>
      <Box align="center" justify="start" fill="vertical" responsive={true}>
        <Grid
          columns={["1fr", "1fr", "1fr", "1fr", "1fr"]}
          rows={["250px", "250px", "250px"]}
          gap="small"
          fill={true}
          margin="xxsmall"
          responsive={true}
        >
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${embedCode}&room=${
              proc + rooms[0]
            }&iframe=true`}
            style={roomStyle}
            className="mobile1"
            scrolling="auto"
            allow="microphone; camera"
            title="room1"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${embedCode}&room=${
              proc + rooms[1]
            }&iframe=true`}
            style={roomStyle}
            className="mobile2"
            scrolling="auto"
            allow="microphone; camera"
            title="room2"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${embedCode}&room=${
              proc + rooms[2]
            }&iframe=true`}
            style={roomStyle}
            className="mobile3"
            scrolling="auto"
            allow="microphone; camera"
            title="room3"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${embedCode}&room=${
              proc + rooms[3]
            }&iframe=true`}
            style={roomStyle}
            className="mobile4"
            scrolling="auto"
            allow="microphone; camera"
            title="room4"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${embedCode}&room=${
              proc + rooms[4]
            }&iframe=true`}
            style={roomStyle}
            className="mobile5"
            scrolling="auto"
            allow="microphone; camera"
            title="room5"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${embedCode}&room=${
              proc + rooms[5]
            }&iframe=true`}
            style={roomStyle}
            className="mobile6"
            scrolling="auto"
            allow="microphone; camera"
            title="room6"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${embedCode}&room=${
              proc + rooms[6]
            }&iframe=true`}
            style={roomStyle}
            className="mobile7"
            scrolling="auto"
            allow="microphone; camera"
            title="room7"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${embedCode}&room=${
              proc + rooms[7]
            }&iframe=true`}
            style={roomStyle}
            className="mobile8"
            scrolling="auto"
            allow="microphone; camera"
            title="room8"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${embedCode}&room=${
              proc + rooms[8]
            }&iframe=true`}
            style={roomStyle}
            className="mobile9"
            scrolling="auto"
            allow="microphone; camera"
            title="room9"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${embedCode}&room=${
              proc + rooms[9]
            }&iframe=true`}
            style={roomStyle}
            className="mobile10"
            scrolling="auto"
            allow="microphone; camera"
            title="room10"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${embedCode}&room=${
              proc + rooms[10]
            }&iframe=true`}
            style={roomStyle}
            className="mobile11"
            scrolling="auto"
            allow="microphone; camera"
            title="room11"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${embedCode}&room=${
              proc + rooms[11]
            }&iframe=true`}
            style={roomStyle}
            className="mobile12"
            scrolling="auto"
            allow="microphone; camera"
            title="room12"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${embedCode}&room=${
              proc + rooms[12]
            }&iframe=true`}
            style={roomStyle}
            className="mobile13"
            scrolling="auto"
            allow="microphone; camera"
            title="room13"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${embedCode}&room=${
              proc + rooms[13]
            }&iframe=true`}
            style={roomStyle}
            className="mobile14"
            scrolling="auto"
            allow="microphone; camera"
            title="room14"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${embedCode}&room=${
              proc + rooms[14]
            }&iframe=true`}
            style={roomStyle}
            className="mobile15"
            scrolling="auto"
            allow="microphone; camera"
            title="room15"
          ></iframe>
        </Grid>
      </Box>
    </>
  );
}

export default OtMobile;
