import { Box, Tabs, Tab } from "grommet";
import React, { useState } from "react";
import DbtCodes from "../Components/DbtCodes";

import FDMS from "../Components/FDMS";

import ExamCodeSelector from "../Components/ExamCodeSelector";

function ExamCodes() {
  const [index, setIndex] = useState(0);

  const onActive = (nextIndex) => {
    setIndex(nextIndex);
  };
  return (
    <Box>
      <Tabs activeIndex={index} onActive={onActive} justify="center">
        <Tab title="Exam Codes">
          <Box align="stretch" justify="center">
            <ExamCodeSelector />
          </Box>
        </Tab>

        <Tab title="DBT Links">
          <Box align="stretch" justify="center">
            <DbtCodes />
          </Box>
        </Tab>
        <Tab title="FDMS Links">
          <Box align="stretch" justify="center">
            <FDMS />
          </Box>
        </Tab>
      </Tabs>
    </Box>
  );
}

export default ExamCodes;
