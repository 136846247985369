import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Proctor from "../Pages/Proctor";
import Mobile from "../Pages/Mobile";
import Webcam from "../Pages/Webcams";
import Laspau from "../Pages/Laspau";
import FAQ from "../Pages/FAQ";
import WebcamTesterPage from "../Pages/WebcamTesterPage";
import SammyCamUsers from "../Pages/SammyCamUsers";
import Demo from "../Pages/Demo";
import DemoRoom from "../Pages/DemoRoom";
import Practice from "../Pages/Practice";
import SCForm from "../Pages/SCForm";
import ExamCodes from "../Pages/ExamCodes";
import GoogleRooms from "../Pages/GoogleRooms";
import Invigil from "../Pages/Invigil";
import DBT from "../Pages/DBT";
import DBTSchedule from "../Pages/DBTSchedule";
import DBTRegister from "../Pages/DBTRegister";
import PaymentValidation from "../Pages/PaymentValidation";
import Chat from "../Pages/Chat";
import AddClient from "../Pages/AddClient";
import CmsScheduling from "../Pages/CmsScheduling";
import CmsEntryPoint from "../Pages/CmsEntryPoint";
import CmsSession from "../Pages/CmsSession";
import Dashboard from "../Pages/Dashboard";
import Sessions from "../Pages/Sessions";
import Components from "../Components/Components";
import TwilioSC from "../Pages/TwilioSC";
import TwilioSCViewer from "../Pages/TwilioSCViewer";
import TwilioSCLinks from "../Pages/TwilioSCLinks";
import TwilioUsers from "../Pages/TwilioUsers";
import TwilioMobile from "../Pages/TwilioMobile";
import NewTesterHome from "../Pages/NewTesterHome";
import ProlydianChat from "../Pages/ProlydianChat";
import AOAScheduling from "../Pages/AOAScheduling";
import ProlydianIntegration from "../Pages/ProlydianIntegration";
import Crane from "../Pages/Crane";
import AAAFoodHandlers from "../Pages/AAAFoodHandlers";
import GoogleRoomIntegration from "../Pages/GoogleRoomIntegration";

// create a protected route component that checks if a user is signed in and reirects to /proctor if not
const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      setIsAuthenticated(true);
    }
    setLoaded(true);
  }, []);

  if (loaded) {
    return isAuthenticated ? children : <Navigate to="/proctor" />;
  }
};

function Nav() {
  return (
    <Routes>
      <Route index element={<NewTesterHome />} />
      <Route path="/proctor" element={<Proctor />} />
      <Route path="/demo" element={<Demo />} />
      <Route path="/laspau" element={<Laspau />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/sc" element={<WebcamTesterPage />} />
      <Route path="/demo-room" element={<DemoRoom />} />
      <Route path="/practice" element={<Practice />} />
      <Route path="/dbt" element={<DBT />} />
      <Route path="/dbt-schedule" element={<DBTSchedule />} />
      <Route path="/dbt-register" element={<DBTRegister />} />
      <Route path="/cms-scheduling" element={<CmsScheduling />} />
      <Route path="/cms-entry-point" element={<CmsEntryPoint />} />
      <Route path="/cms" element={<CmsSession />} />
      <Route path="/chat" element={<Chat />} />
      <Route path="/AddClient" element={<AddClient />} />
      <Route path="/components" element={<Components />} />
      <Route path="/tsc" element={<TwilioSC />} />
      <Route path="/chat-prolydian" element={<ProlydianChat />} />
      <Route path="/aoa-scheduling" element={<AOAScheduling />} />
      <Route
        path="/twiliosc"
        element={
          <ProtectedRoute>
            <TwilioSCViewer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/twiliolinks"
        element={
          <ProtectedRoute>
            <TwilioSCLinks />
          </ProtectedRoute>
        }
      />
      <Route
        path="/twiliousers"
        element={
          <ProtectedRoute>
            <TwilioUsers />
          </ProtectedRoute>
        }
      />

      <Route
        path="/mobile"
        element={
          <ProtectedRoute>
            <Mobile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/webcams"
        element={
          <ProtectedRoute>
            <Webcam />
          </ProtectedRoute>
        }
      />
      <Route
        path="/webcam-form"
        element={
          <ProtectedRoute>
            <SCForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="/webcam-users"
        element={
          <ProtectedRoute>
            <SammyCamUsers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/invigil"
        element={
          <ProtectedRoute>
            <Invigil />
          </ProtectedRoute>
        }
      />
      <Route
        path="/google-rooms"
        element={
          <ProtectedRoute>
            <GoogleRooms />
          </ProtectedRoute>
        }
      />
      <Route
        path="/codes"
        element={
          <ProtectedRoute>
            <ExamCodes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/payment-validation"
        element={
          <ProtectedRoute>
            <PaymentValidation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sessions"
        element={
          <ProtectedRoute>
            <Sessions />
          </ProtectedRoute>
        }
      />
      <Route
        path="/twmobile"
        element={
          <ProtectedRoute>
            <TwilioMobile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/prolydian-integration"
        element={
          <ProtectedRoute>
            <ProlydianIntegration />
          </ProtectedRoute>
        }
      />
      <Route
        path="/crane"
        element={
          <ProtectedRoute>
            <Crane />
          </ProtectedRoute>
        }
      />
      <Route
        path="/aaa-food-handlers"
        element={
          <ProtectedRoute>
            <AAAFoodHandlers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/google-room-integration"
        element={
          <ProtectedRoute>
            <GoogleRoomIntegration />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default Nav;
