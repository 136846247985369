// This component is the main dashboard for the proctor.
// It will manage the data and logic for the queue, sessions, and necessary functions for proctoring
// any functions that are passed down to child components will be defined here
import { API, Auth } from "aws-amplify";
import {
  Box,
  Button,
  Text,
  Tabs,
  Tab,
  Page,
  Layer,
  Notification,
} from "grommet";
import { Table, User } from "grommet-icons";
import React, { useEffect, useState } from "react";

import {
  GetMySessionsAndQueue,
  sessionByType,
  sessionFinisherFunction,
} from "../graphql/queries";
import { onCreateSession, onUpdateSession } from "../graphql/subscriptions";
import { DeleteSession, UpdateSession } from "../graphql/mutations";
import SessionsLayout from "../Components/Dashboard/SessionsLayout";
import PopUpNotesCard from "../Components/Dashboard/PopUpNotesCard";
import SessionsTable from "../Components/Dashboard/SessionsTable";

function Dashboard() {
  // array sets: Queue, Sessions
  // session filter by types: all, byProctor, byStatus, byFlag, byClient
  const [queue, setQueue] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [me, setMe] = useState("");
  // index for tabs 0 = "MY SESSIONS" 1 = "ALL SESSIONS"
  const [index, setIndex] = useState(0);
  const [sessionFilter, setSessionFilter] = useState("all");
  //const [clientList, setClientList] = useState([]);
  const [isQueue, setIsQueue] = useState(false);
  const [isSessions, setIsSessions] = useState(false);
  // layer for note taking
  const [showLayer, setShowLayer] = useState(false);
  // session for note taking when layer is visible
  const [currSession, setCurrSession] = useState({});
  // value for notes layer
  const [value, setValue] = useState({});
  // DNS warning
  const [showDNSWarning, setShowDNSWarning] = useState(false);
  // monitor change count for useEffect
  const [changeCount, setChangeCount] = useState(0);
  // loading state
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  // saved notes notification
  const [showSavedNotes, setShowSavedNotes] = useState(false);
  //notification details for saving data
  const [notificationDetails, setNotificationDetails] = useState({});
  // show completion warning
  const [showCompletionWarning, setShowCompletionWarning] = useState(false);

  // effect for sessions and queue
  useEffect(() => {
    if (sessions.length > 0) {
      setSessions([]);
      setIsSessions(false);
    }
    if (index === 0) {
      getInitialSessionsAndQueue();
      if (isFirstLoad) {
        subscribe();
        secondSubscribe();
        setIsFirstLoad(false);
      }
    } else {
      switch (sessionFilter) {
        case "all":
          getAllSessions();
          break;
        case "byProctor":
          // get sessions by proctor
          break;
        case "byStatus":
          // get sessions by status
          break;
        case "byFlag":
          // get sessions by flag
          break;
        case "byClient":
          // get sessions by client
          break;
        default:
          // get all sessions
          break;
      }
    }

    // get my sessions and initial queue sessions
    async function getInitialSessionsAndQueue() {
      const user = await Auth.currentUserInfo();
      setMe(user.attributes.email);

      try {
        const data = await API.graphql({
          query: GetMySessionsAndQueue,
          variables: {
            proctor: user.attributes.email,
            status: "queue",
            filter: {
              or: [{ status: { eq: "claimed" } }, { status: { eq: "active" } }],
            },
          },
        });

        if (data.data) {
          if (data.data.sessionByStatus.items.length > 0) {
            setQueue(data.data.sessionByStatus.items);

            setIsQueue(true);
          }
          if (data.data.sessionByProctor.items.length > 0) {
            setSessions(data.data.sessionByProctor.items);
            setIsSessions(true);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
    // subscribe to onCreateSession
    async function subscribe() {
      await API.graphql({
        query: onCreateSession,
      }).subscribe({
        next: (msg) => {
          if (msg.value.data.onCreateSession.status === "queue") {
            setQueue((queue) => [...queue, msg.value.data.onCreateSession]);
          }
          if (!isQueue) {
            setIsQueue(true);
          }
        },
        error: (err) => console.log(err),
      });
    }

    // subscribe to onUpdateSession
    async function secondSubscribe() {
      await API.graphql({
        query: onUpdateSession,
        variables: {
          filter: {
            or: [{ status: { eq: "claimed" } }, { status: { eq: "queue" } }],
          },
        },
      }).subscribe({
        next: (msg) => {
          if (msg.value.data.onUpdateSession.status === "claimed") {
            setQueue((queue) => [
              ...queue.filter(
                (item) => item.id !== msg.value.data.onUpdateSession.id
              ),
            ]);
          } else if (msg.value.data.onUpdateSession.status === "queue") {
            setQueue((queue) => [...queue, msg.value.data.onUpdateSession]);
          }
        },
        error: (err) => console.log(err),
      });
    }

    // get all sessions
    async function getAllSessions() {
      try {
        const data = await API.graphql({
          query: sessionByType,
          variables: {
            type: "Session",
            sortDirection: "ASC",
          },
        });

        if (data.data.sessionByType.items.length > 0) {
          setSessions(data.data.sessionByType.items);
          setIsSessions(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [index, changeCount]);

  // FUNCTIONS pass down to respective components
  // claim a session, remove from queue, set proctor, append to proctor history
  async function claim(id, client, first, last, hist) {
    let tmp;
    if (hist == null) {
      tmp = [me];
    } else {
      tmp = hist;
      tmp.push(me);
    }

    try {
      const data = await API.graphql({
        query: UpdateSession,
        variables: {
          input: {
            id: id,
            status: "claimed",
            client_name: client,
            first_name: first,
            last_name: last,
            proctor: me,
            proctor_history: tmp,
          },
        },
      });

      // append to claimed
      setIsSessions(false);
      setSessions([]);
      setChangeCount((changeCount) => changeCount + 1);
    } catch (err) {
      console.log(err);
    }
  }
  // open layer for note taking
  const openLayer = (session) => {
    setShowLayer(true);
    setCurrSession(session);
  };
  // send to queue, remove from claimed, set proctor to queue
  async function sendToQueue(id, hist) {
    try {
      var tmp = hist;
      if (tmp == null) {
        tmp = ["queue"];
      }
      tmp.push("transfer");
      const tempValue = value;
      Object.keys(tempValue).forEach((key) => {
        if (tempValue[key] === "") {
          delete tempValue[key];
        }
      });
      const newTempValue = {
        ...tempValue,
        status: "queue",
        proctor: "transfer",
        proctor_history: tmp,
      };
      await API.graphql({
        query: UpdateSession,
        variables: {
          input: {
            id: id,
            ...newTempValue,
          },
        },
      });
      setIsSessions(false);
      setSessions([]);
      setShowLayer(false);
      setCurrSession({});
      setValue({});
      setChangeCount((changeCount) => changeCount + 1);
    } catch (err) {
      console.log(err);
    }
  }
  // save notes, update session - on close of <Layer>
  async function saveNotesOnClose() {
    try {
      const tempValue = value;
      Object.keys(tempValue).forEach((key) => {
        if (tempValue[key] === "") {
          delete tempValue[key];
        }
      });

      if (tempValue.pass && tempValue.pass === "pass") {
        tempValue.pass = true;
      } else if (tempValue.pass && tempValue.pass === "fail") {
        tempValue.pass = false;
      }

      setNotificationDetails({
        title: "Session Notes Saved",
        message:
          "Session notes for " +
          value.first_name +
          " " +
          value.last_name +
          " " +
          value.client_name +
          " have been saved.",
        status: "normal",
      });
      await API.graphql({
        query: UpdateSession,
        variables: {
          input: {
            id: tempValue.id,
            ...tempValue,
            status: "active",
          },
        },
      });
      setShowSavedNotes(true);
      setIsSessions(false);
      setSessions([]);
      setCurrSession({});
      setShowLayer(false);
      setValue({});
      setChangeCount((changeCount) => changeCount + 1);
    } catch (err) {
      console.log(err);
    }
  }
  // set status to "done" - save notes, update session, remove from claimed
  async function finishSession() {
    try {
      const tempValue = value;
      Object.keys(tempValue).forEach((key) => {
        if (tempValue[key] === "") {
          delete tempValue[key];
        }
      });
      if (tempValue.pass && tempValue.pass === "pass") {
        tempValue.pass = true;
      } else if (tempValue.pass && tempValue.pass === "fail") {
        tempValue.pass = false;
      }
      setNotificationDetails({
        status: "normal",
        title: "Session Completed",
        message:
          "Session for " +
          value.first_name +
          " " +
          value.last_name +
          " " +
          value.client_name +
          " has been completed. If you need to edit the session, please go to the all sessions tab and select the row of the session.",
      });
      const newTempValue = {
        ...tempValue,
        status: "done",
      };
      await API.graphql({
        query: UpdateSession,
        variables: {
          input: {
            id: tempValue.id,
            ...newTempValue,
          },
        },
      });
      // remove from sessions
      setShowSavedNotes(true);
      setShowLayer(false);
      setCurrSession({});
      setValue({});
      setIsSessions(false);
      setSessions([]);
      setChangeCount((changeCount) => changeCount + 1);
    } catch (err) {
      console.log(err);
    }
  }
  // send back to proctor, set status to active, set proctor to end of proctor history
  async function sendBackToProctor() {
    try {
      await API.graphql({
        query: UpdateSession,
        variables: {
          input: {
            id: value.id,
            status: "active",
          },
        },
      });
      setIsSessions(false);
      setSessions([]);
      setShowLayer(false);
      setCurrSession({});
      setValue({});
      setChangeCount((changeCount) => changeCount + 1);
    } catch (err) {
      console.log(err);
    }
  }
  // set status to DNS, show warning as this deletes the session
  async function setDNS() {
    try {
      await API.graphql({
        query: DeleteSession,
        variables: {
          input: {
            id: value.id,
          },
        },
      });

      // remove from sessions
      setShowLayer(false);
      setCurrSession({});
      setValue({});
      setShowDNSWarning(false);
      setIsSessions(false);
      setSessions([]);
      setChangeCount((changeCount) => changeCount + 1);
    } catch (err) {
      console.log(err);
    }
  }
  // create manual session, add to queue
  // finisher funtion
  async function completeSession() {
    try {
      const tempValue = value;
      Object.keys(tempValue).forEach((key) => {
        if (tempValue[key] === "") {
          delete tempValue[key];
        }
      });
      if (tempValue.pass && tempValue.pass === "pass") {
        tempValue.pass = true;
      } else if (tempValue.pass && tempValue.pass === "fail") {
        tempValue.pass = false;
      }
      setNotificationDetails({
        status: "normal",
        title: "Session Completed",
        message:
          "Session for " +
          value.first_name +
          " " +
          value.last_name +
          " " +
          value.client_name +
          " has been completed.",
      });
      const newTempValue = {
        ...tempValue,
        status: "finished",
        type: "completedSession",
      };
      console.log(newTempValue);
      const dat = await API.graphql({
        query: sessionFinisherFunction,
        variables: {
          session: JSON.stringify(newTempValue),
        },
      });
      await API.graphql({
        query: UpdateSession,
        variables: {
          input: {
            id: tempValue.id,
            ...newTempValue,
          },
        },
      });

      console.log(dat);
      // remove from sessions
      setShowSavedNotes(true);
      setShowLayer(false);
      setCurrSession({});
      setValue({});
      setIsSessions(false);
      setSessions([]);
      setChangeCount((changeCount) => changeCount + 1);
      setShowCompletionWarning(false);
    } catch (err) {
      console.log(err);
    }
  }
  // close notification
  const closeNotification = () => {
    setShowSavedNotes(false);
    setNotificationDetails({});
  };
  // index setter
  const onActive = (nextIndex) => setIndex(nextIndex);

  return (
    <>
      {showLayer && (
        <Layer
          onEsc={() => saveNotesOnClose()}
          onClickOutside={() => saveNotesOnClose()}
        >
          <Box
            align="start"
            justify="start"
            overflow="auto"
            margin={{ right: "small" }}
            width="xlarge"
            direction="column"
            gap="small"
            responsive
            wrap={true}
            pad="xsmall"
          >
            <PopUpNotesCard
              session={currSession}
              sendToQueue={sendToQueue}
              value={value}
              setValue={setValue}
              finishSession={finishSession}
              setShowDNSWarning={setShowDNSWarning}
              sendBackToProctor={sendBackToProctor}
              setShowCompletionWarning={setShowCompletionWarning}
            />
          </Box>
        </Layer>
      )}
      {showDNSWarning && (
        <Layer
          onEsc={() => setShowDNSWarning(false)}
          onClickOutside={() => setShowDNSWarning(false)}
        >
          <Box align="center" justify="center" gap="small" pad="small">
            <Text weight="bold">
              Are you sure you want to set this session to DNS?
            </Text>
            <Text weight="bold">
              This will delete the session from the database.
            </Text>
            <Button label="Yes" onClick={() => setDNS()} primary />
            <Button label="No" onClick={() => setShowDNSWarning(false)} />
          </Box>
        </Layer>
      )}
      {showSavedNotes && (
        <Notification
          toast
          status={notificationDetails.status}
          title={notificationDetails.title}
          message={notificationDetails.message}
          onClose={() => closeNotification()}
        />
      )}
      {showCompletionWarning && (
        <Layer
          onEsc={() => setShowDNSWarning(false)}
          onClickOutside={() => setShowDNSWarning(false)}
        >
          <Box align="center" justify="center" gap="small" pad="small">
            <Text weight="bold">
              Are you sure you want to set this session to complete?
            </Text>
            <Text weight="bold">
              This wont delete the session but will remove it from the view for
              reporting purposes.
            </Text>
            <Button label="Yes" onClick={() => completeSession()} primary />
            <Button
              label="No"
              onClick={() => setShowCompletionWarning(false)}
            />
          </Box>
        </Layer>
      )}
      <Box align="start" justify="start" fill={true} pad="small">
        <Tabs activeIndex={index} onActive={onActive} justify="start">
          <Tab title="My Sessions" icon={<User />}>
            <Page kind="full">
              <SessionsLayout
                queue={queue}
                sessions={sessions}
                isQueue={isQueue}
                claim={claim}
                isSessions={isSessions}
                openLayer={openLayer}
              />
            </Page>
          </Tab>
          <Tab title="All Sessions" icon={<Table />}>
            <Page kind="full">
              <SessionsTable sessions={sessions} openLayer={openLayer} />
            </Page>
          </Tab>
        </Tabs>
      </Box>
    </>
  );
}

export default Dashboard;
