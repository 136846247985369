import { Box, Notification } from "grommet";
import React, { useEffect, useState } from "react";
import PreChatCard from "../Components/PreChatCard";
import { API } from "aws-amplify";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { getClientPub } from "../graphql/queries";

function Chat() {
  const [isChat, setIsChat] = useState(false);
  const [clientInfo, setClientInfo] = useState(null);
  const [error, setError] = useState(false);
  const { search } = useLocation();
  const { client } = queryString.parse(search);

  useEffect(() => {
    if (client != null) {
      fetchClient(client);

      async function fetchClient(client) {
        try {
          const data = await API.graphql({
            query: getClientPub,
            variables: { id: client },
            authMode: "API_KEY",
          });
          console.log(data.data.getClient);
          if (data.data.getClient.id != null) {
            setClientInfo(data.data.getClient);
            setIsChat(true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  }, []);

  return (
    <Box align="center" justify="center">
      {error && (
        <Notification
          toast
          status="critical"
          message="Invalid Link"
          title="Error"
          onClose={() => setError(false)}
        />
      )}
      {isChat && <PreChatCard client={clientInfo} />}
    </Box>
  );
}

export default Chat;
