import { useEffect, useState } from "react";

export default function useParticipant({
  room,
  onNewParticipantConnected,
  onParticipantDisconnected,
}) {
  const [participants, setParticipants] = useState([]);
  const localParticipant = room?.localParticipant;

  useEffect(() => {
    if (room) {
      const handleCurrentParticipants = (participant) => {
        setParticipants([...participants, participant]);
      };

      const handleNewParticipant = (participant) => {
        setParticipants([...participants, participant]);
        onNewParticipantConnected && onNewParticipantConnected(participant);
      };

      const handleParticipantDisconnected = (participant) => {
        setParticipants(participants.filter((p) => p !== participant));
        onParticipantDisconnected && onParticipantDisconnected(participant);
      };

      room.participants.forEach(handleCurrentParticipants);

      room.on("participantConnected", handleNewParticipant);
      room.on("participantDisconnected", handleParticipantDisconnected);

      return () => {
        room.off("participantConnected", handleNewParticipant);
        room.off("participantDisconnected", handleParticipantDisconnected);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, onNewParticipantConnected, onParticipantDisconnected]);

  return { localParticipant, remoteParticipants: participants };
}
