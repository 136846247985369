import { Box, FormField, TextInput } from "grommet";
import React from "react";

// Name Field
export function NameField() {
  return (
    <Box align="center" justify="center" direction="row">
      <FormField label="First Name" name="first_name" required={true}>
        <TextInput name="first_name" placeholer="First Name" />
      </FormField>
      <FormField label="Last Name" name="last_name" required={true}>
        <TextInput name="last_name" placeholer="Last Name" />
      </FormField>
    </Box>
  );
}

// Email Field
export function EmailField() {
  return (
    <FormField label="Email" name="email" required={true}>
      <TextInput name="email" placeholer="Email" />
    </FormField>
  );
}

// Phone Field
export function PhoneField() {
  return (
    <FormField label="Phone" name="phone" required={true}>
      <TextInput name="phone" placeholer="Phone" />
    </FormField>
  );
}

// candidate_id Field
export function CandidateIdField() {
  return (
    <FormField label="Candidate ID" name="candidate_id" required={true}>
      <TextInput name="candidate_id" placeholer="Candidate ID" />
    </FormField>
  );
}

// exam field

export function ExamField() {
  return (
    <FormField label="Exam" name="exam" required={true}>
      <TextInput name="exam" placeholer="Exam" />
    </FormField>
  );
}

// instructor field
export function InstructorField() {
  return (
    <FormField label="Instructor" name="instructor" required={true}>
      <TextInput name="instructor" placeholer="Instructor" />
    </FormField>
  );
}

// school field

export function SchoolField() {
  return (
    <FormField label="School" name="school" required={true}>
      <TextInput name="school" placeholer="School" />
    </FormField>
  );
}

//lesson_id field
export function LessonIdField() {
  return (
    <FormField label="Lesson ID" name="lesson_id" required={true}>
      <TextInput name="lesson_id" placeholer="Lesson ID" />
    </FormField>
  );
}

// appointment field
export function AppointmentField() {
  return (
    <FormField
      label="Appointment/Start Time"
      name="appointment"
      required={true}
    >
      <TextInput name="appointment" placeholer="Appointment" />
    </FormField>
  );
}

//receipt field
export function ReceiptField() {
  return (
    <FormField label="Receipt" name="receipt_number" required={true}>
      <TextInput name="receipt_number" placeholer="Receipt" />
    </FormField>
  );
}
