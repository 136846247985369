import React, { useEffect, useRef } from "react";

export default function VideoTrack({ track }) {
  const ref = useRef();

  useEffect(() => {
    if (track) {
      console.log(track);
      const el = ref.current;
      track.track ? track.track.attach(el) : track.attach(el);

      return () => {
        track.track ? track.track.detach(el) : track.detach(el);
      };
    }
  }, [track]);

  return <video ref={ref} />;
}
