//Handle Room controls and logic

import { CardBody, CardFooter, Button, Box, Text, Stack } from "grommet";
import React, { useEffect } from "react";
import MicIcon from "@mui/icons-material/Mic";
import VideocamIcon from "@mui/icons-material/Videocam";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import useRoom from "../hooks/useRoom";
import Participant from "./Participant";

export default function ProctorVideoCard({ token, roomId, onDisconnected }) {
  const {
    room,
    error,
    connectRoom,
    disconnectRoom,
    localParticipant,
    remoteParticipants,
    isMicrophoneEnabled,
    toggleMicrophone,
    isCameraEnabled,
    toggleCamera,
  } = useRoom();

  useEffect(() => {
    if (!room && token && roomId) {
      connectRoom({
        token,
        options: { name: roomId, audio: false, video: false },
      });
    }
    return () => disconnectRoom();
  }, [room, token, roomId, connectRoom, disconnectRoom]);

  if (error) {
    return (
      <Box align="center" justify="center" fill>
        <Text size="large">{error.message}</Text>
      </Box>
    );
  }

  if (room) {
    return (
      <>
        <CardBody>
          <Stack fill anchor="top-right">
            <Box
              fill
              background={{ color: "brand" }}
              justify="center"
              align="center"
              direction="row"
              overflow="scroll"
            >
              {remoteParticipants.map((participant, index) => (
                <Participant
                  key={index}
                  participant={participant}
                  type="remote"
                />
              ))}
            </Box>
            <Box
              flex="shrink"
              width="small"
              height="xsmall"
              justify="start"
              align="end"
            >
              <Participant participant={localParticipant} type="local" />
            </Box>
          </Stack>
        </CardBody>
        <CardFooter align="center" justify="center">
          <Button
            icon={isMicrophoneEnabled ? <MicIcon /> : <MicOffIcon />}
            onClick={() => toggleMicrophone()}
            hoverIndicator
          />
          <Button
            icon={isCameraEnabled ? <VideocamIcon /> : <VideocamOffIcon />}
            onClick={() => toggleCamera()}
            hoverIndicator
          />
          <Button label="Leave Room" onClick={() => disconnectRoom()} />
        </CardFooter>
      </>
    );
  }

  return <></>;
}
