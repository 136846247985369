import React from "react";
import Navigator from "./Components/Navigator";
import NavUnAuth from "./Components/NavUnAuth";

import { useUser } from "./AuthContext";
import Nav from "./Global/NewRouter";

function App() {
  const myUser = useUser();

  return (
    <>
      {myUser.isUser ? <Navigator /> : <NavUnAuth />}
      <Nav />
    </>
  );
}

export default App;
