import { API, Storage } from "aws-amplify";
import { Box, Card, Select, TextInput, Text } from "grommet";
import { Search } from "grommet-icons";
import React, { useState, useEffect } from "react";
import { listExamCodeTables } from "../graphql/queries";

function ExamCodeSelector() {
  const [exams, setExams] = useState([]);
  const [value, setValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [keys, setKeys] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [codes, setCodes] = useState([]);

  useEffect(() => {
    async function fetchExams() {
      try {
        const response = await API.graphql({
          query: listExamCodeTables,
        });

        let temp = response.data.listExamCodeTables.items.map((item) => {
          return {
            label: item.client_name + " " + item.exam_name,
            value: item.id,
            ident: item.code_bucket_key,
          };
        });

        setExams(temp);
      } catch (error) {
        console.error(error);
      }
    }
    fetchExams();
  }, []);

  useEffect(() => {
    // filter exams by value
    // get the code bucket key from the exam
    // get the file from the code bucket
    // parse the file and set the codes

    async function getCodes() {
      let identity;
      let tem = exams.filter((exam) => {
        return exam.value === value;
      });

      identity = tem[0].ident;
      console.log(identity);
      const fileURL = await Storage.get(value + ".json", {
        identityId: identity,
      });

      await fetch(fileURL)
        .then((r) => r.json())
        .then((data) => {
          console.log(data);
          setCodes(data.data);
          //setSearchResults(data.data);
          setKeys(Object.keys(data.data[0]));
        });
    }
    if (value.length > 0) {
      getCodes();
    }
  }, [value]);

  useEffect(() => {
    const debouncedSearchTerm = debounce(search, 1000);
    debouncedSearchTerm(searchTerm);
  }, [searchTerm]);

  function debounce(func, delay) {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }

  function search(query) {
    if (query.length > 0) {
      const filteredResults = codes.filter((code) => {
        return keys.some((key) => {
          return code[key]
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase());
        });
      });
      setSearchResults(filteredResults);
    } else {
      setSearchResults([]);
    }
  }

  return (
    <Box align="center" justify="center" margin={{ top: "small" }}>
      <Card fill="horizontal" background="light-1" pad="medium">
        <Box align="center" justify="center" direction="row" gap="small">
          {exams.length > 0 && (
            <Box background="white" round="small">
              <Select
                labelKey="label"
                valueKey={{ key: "value", reduce: true }}
                value={value}
                options={exams}
                placeholder="Select Exam"
                onChange={({ value: nextOption }) => {
                  setValue(nextOption);
                }}
              />
            </Box>
          )}
          {value.length > 0 && (
            <Box background="white" round="small" direction="row">
              <TextInput
                placeholder="Search"
                icon={<Search />}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>
          )}
        </Box>
      </Card>
      {searchResults.length > 0 && (
        <Box
          align="center"
          justify="center"
          margin={{ top: "small" }}
          direction="row"
          wrap
        >
          {searchResults.map((result, index) => (
            <ExamCard exam={result} keys={keys} key={index} />
          ))}
        </Box>
      )}
    </Box>
  );
}

export default ExamCodeSelector;

function ExamCard({ exam, keys }) {
  return (
    <Card
      background="light-1"
      pad="small"
      margin="small"
      round="small"
      width="medium"
    >
      <Box align="start" justify="center">
        {keys.map((key, idx) => (
          <Text key={idx}>
            <strong>
              {key} {": "}
            </strong>
            {exam[key]}
          </Text>
        ))}
      </Box>
    </Card>
  );
}
