import { API } from "aws-amplify";
import {
  Box,
  Card,
  Text,
  TextInput,
  Button,
  NameValueList,
  NameValuePair,
  Table,
  TableRow,
  TableCell,
  Collapsible,
  TableHeader,
  TableBody,
} from "grommet";
import React, { useEffect, useState } from "react";
import {
  ListProlydianRegistrations,
  getProlydianExam,
} from "../graphql/queries";
import { FormNextLink, Search } from "grommet-icons";

function ProlydianIntegration() {
  const [registrations, setRegistrations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const keys = ["email", "firstName", "lastName"];

  useEffect(() => {
    // call get registrations, if there is a nextToken, call it again with the nextToken
    let temp = [];
    async function fetchData(token) {
      const data = await getRegistrations(token);
      temp = [...temp, ...data.items];
      if (data.nextToken) {
        fetchData(data.nextToken);
      } else {
        setRegistrations(temp);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    setSearchResults([]);
    const debouncedSearchTerm = debounce(search, 1000);
    debouncedSearchTerm(searchTerm);
  }, [searchTerm]);
  // get all registrations
  async function getRegistrations(token) {
    const data = await API.graphql({
      query: ListProlydianRegistrations,
      variables: token ? { nextToken: token, limit: 300 } : { limit: 300 },
    });
    return data.data.listProlydianRegistrations;
  }

  // debounce function
  function debounce(func, delay) {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }

  // search function
  function search(query) {
    if (query.length > 0) {
      const results = registrations.filter((registration) => {
        return keys.some((key) => {
          return registration[key]
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase());
        });
      });
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }
  return (
    <Box
      align="center"
      justify="center"
      fill="horizontal"
      overflow="auto"
      gap="medium"
      pad="small"
    >
      <Box align="center" justify="center" direction="row" gap="small">
        <Text size="xlarge" weight="bolder">
          Prolydian Exam Links and Codes
        </Text>
      </Box>
      <Card
        fill="horizontal"
        background="light-1"
        pad="medium"
        direction="row"
        align="center"
        justify="center"
        gap="small"
      >
        <Box background="white" round="small" direction="row" width="medium">
          <TextInput
            placeholder="Search By Name or Email"
            value={searchTerm}
            icon={<Search />}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>
        {searchTerm.length > 0 && (
          <Text size="large">Select a tester to view details</Text>
        )}
      </Card>
      {searchResults.length > 0 && (
        <Box
          align="center"
          justify="center"
          margin={{ top: "small" }}
          direction="column"
          fill="horizontal"
          wrap
          gap="small"
        >
          {searchResults.map((registration, idx) => (
            <ExamBox registration={registration} key={idx} />
          ))}
        </Box>
      )}
    </Box>
  );
}

export default ProlydianIntegration;

function ExamBox({ registration }) {
  const [open, setOpen] = useState(false);
  const [exam, setExam] = useState(null);

  useEffect(() => {
    if (open) {
      getExam();
    }
  }, [open]);

  async function getExam() {
    const data = await API.graphql({
      query: getProlydianExam,
      variables: { id: registration.examId },
    });

    setExam(data.data.getProlydianExam);
  }

  return (
    <Box
      key={registration.id}
      background="light-1"
      pad="small"
      round="small"
      fill
      elevation="small"
      justify="center"
      align="center"
      onClick={() => {
        if (!open) {
          setOpen(true);
        }
      }}
      hoverIndicator={{ elevation: "large" }}
      focusIndicator={false}
    >
      <Table alignSelf="center">
        <TableHeader>
          <TableRow>
            <TableCell scope="col" align="center">
              <Text size="large" weight="bold">
                Name
              </Text>
            </TableCell>
            <TableCell scope="col" align="center">
              <Text size="large" weight="bold">
                Email
              </Text>
            </TableCell>
            <TableCell scope="col" align="center">
              <Text size="large" weight="bold">
                Appointment
              </Text>
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell scope="col" align="center">
              <Text size="large">
                {registration.firstName} {registration.lastName}
              </Text>
            </TableCell>
            <TableCell scope="col" align="center">
              <Text size="large">{registration.email}</Text>
            </TableCell>
            <TableCell scope="col" align="center">
              <Text size="large">
                {new Date(registration.appointment).toLocaleString()}
              </Text>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Box pad="small" fill>
        <Collapsible open={open}>
          <Box direction="row" justify="evenly" align="start" gap="medium">
            <Box direction="column" align="start" justify="start">
              <Text weight="bold">Candidate ID:</Text>
              <Text>
                {registration.candidateId ? registration.candidateId : "N/A"}
              </Text>
              <Text weight="bold">Exam URL:</Text>
              <Text>{registration.examUrl}</Text>
              <Text weight="bold">Exam Code:</Text>
              <Text>{registration.unlockCode}</Text>
            </Box>
            <Box direction="column" align="start" justify="start">
              {exam && (
                <>
                  <Text weight="bold">Exam Name:</Text>
                  <Text>{exam.examName}</Text>
                  <Text weight="bold">Proctor Instructions:</Text>
                  <Text>{exam.proctorInstructions?.map((itm) => itm)}</Text>
                  <Text weight="bold">Candidate Instructions:</Text>
                  <Text>{exam.candidateInstructions?.map((itm) => itm)}</Text>
                </>
              )}
            </Box>
          </Box>
        </Collapsible>
      </Box>
    </Box>
  );
}
