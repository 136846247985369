import React, { useEffect } from "react";
import Participant from "./Participant";
import useRoom from "../../useRoom";
import { CardBody, Stack, Box, CardFooter, Button, Text } from "grommet";
import MicIcon from "@mui/icons-material/Mic";
import VideocamIcon from "@mui/icons-material/Videocam";
import MicOffIcon from "@mui/icons-material/MicOff";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";

function Room({ token, identity, roomName, onDisconnected, type }) {
  const [isFirstRender, setIsFirstRender] = React.useState(true);
  const {
    room,
    error,
    connectRoom,
    disconnectRoom,
    localParticipant,
    remoteParticipants,
    dominantSpeaker,
    isCameraOn,
    toggleCamera,
    isMicrophoneOn,
    toggleMicrophone,
  } = useRoom();

  useEffect(() => {
    const proctorOptions = {
      name: roomName,
      audio: false,
      video: false,
      width: 400,
      height: 400,
    };

    const testerOptions = {
      name: roomName,
      width: 400,
      height: 400,
    };

    if (isFirstRender) {
      if (!room && token && roomName) {
        connectRoom({
          token,
          options: testerOptions,
        });
      }
      setIsFirstRender(false);
    }
    return () => disconnectRoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectRoom, disconnectRoom, room, roomName, token]);

  const remoteConnectedIndicator = () => {
    if (type === "proctor") {
      return (
        <Box align="center" justify="center" direction="row">
          <Text size="xsmall" color="green">
            Tester Connected
          </Text>
        </Box>
      );
    } else {
      return (
        <Box align="center" justify="center" direction="row">
          <Text size="xsmall" color="green">
            Proctor Connected
          </Text>
        </Box>
      );
    }
  };

  if (error) return `Error: ${error.message}`;

  // connected

  if (room)
    return (
      <>
        <CardBody>
          {type === "proctor" ? (
            <Stack fill anchor="top-right">
              <Box
                fill
                background={{ color: "black" }}
                justify="center"
                align="center"
                direction="row"
                overflow="scroll"
              >
                {remoteParticipants.map((participant, index) => (
                  <Participant key={index} participant={participant} />
                ))}
              </Box>
              <Box
                flex="shrink"
                width="small"
                height="xsmall"
                justify="start"
                align="end"
              >
                <Participant participant={localParticipant} />
              </Box>
            </Stack>
          ) : (
            <Stack fill anchor="top-right">
              <Box
                fill
                background={{ color: "black" }}
                justify="center"
                align="center"
                direction="row"
                overflow="scroll"
              >
                <Participant participant={localParticipant} />
              </Box>
              <Box
                flex="shrink"
                width="small"
                height="xsmall"
                justify="start"
                align="end"
              >
                {remoteParticipants.map((participant, index) => (
                  <Participant key={index} participant={participant} />
                ))}
              </Box>
            </Stack>
          )}
        </CardBody>
        <CardFooter align="center" justify="center" direction="column">
          <Box align="center" justify="center" direction="row">
            <Button
              icon={isMicrophoneOn ? <MicIcon /> : <MicOffIcon />}
              onClick={() => toggleMicrophone()}
              hoverIndicator
            />
            <Button
              icon={isCameraOn ? <VideocamIcon /> : <VideocamOffIcon />}
              onClick={() => toggleCamera()}
              hoverIndicator
            />
            <Button
              label="Leave Room"
              onClick={() => {
                disconnectRoom();
                onDisconnected && onDisconnected();
              }}
              hoverIndicator
            />
          </Box>
          {remoteParticipants.length > 0 && remoteConnectedIndicator()}
        </CardFooter>
      </>
    );

  /*
  if (room)
    return (
      <div>
        <div>
          <button
            onClick={() => {
              disconnectRoom();
              onDisconnected && onDisconnected();
            }}
          >
            disconnect
          </button>
          <button onClick={() => toggleCamera()}>
            {isCameraOn ? "turn off camera" : "turn on camera"}
          </button>
          <button onClick={() => toggleMicrophone()}>
            {isMicrophoneOn ? "turn off mic" : "turn on mic"}
          </button>
        </div>
        <div>
          Local participant: {JSON.stringify(localParticipant?.identity)}
        </div>
        <Participant participant={localParticipant} />
        <div>
          Remote participants:{" "}
          {JSON.stringify(remoteParticipants.map((v) => v.identity))}
        </div>
        <div>Dominant speaker: {JSON.stringify(dominantSpeaker?.identity)}</div>
        <div>
          {remoteParticipants.map((p) => (
            <Participant participant={p} />
          ))}
        </div>
      </div>
    );
    */

  return "connecting...";
}

export default Room;
