import {
  Box,
  Button,
  Card,
  Form,
  FormField,
  Heading,
  Tab,
  Tabs,
  TextInput,
  Notification,
  Text,
  Select,
} from "grommet";
import React, { useState } from "react";
import { getLsoTester } from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import GoogleRoomCard from "../Components/GoogleRoomCard";
import { addDailyGRoom } from "../graphql/mutations";
import { useUser } from "../AuthContext";

function GoogleRooms() {
  const [index, setIndex] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [org, setOrg] = useState("");
  const [lsoCand, setLsoCand] = useState("");
  const [isCands, setIsCands] = useState(false);
  const [error, setError] = useState(false);
  const [gRoomError, setGRoomError] = useState(false);
  const [gRoomSuccess, setGRoomSuccess] = useState(false);
  const [rooms, setRooms] = useState(0);
  const [room, setRoom] = useState([]);
  const onActive = (index) => {
    setIndex(index);
    setName("");
    setEmail("");
    setOrg("");
    setLsoCand("");
    setIsCands(false);
  };
  const roomCount = [
    "Room 1",
    "Room 2",
    "Room 3",
    "Room 4",
    "Room 5",
    "Room 6",
    "Room 7",
    "Room 8",
    "Room 9",
    "Room 10",
    "Room 11",
    "Room 12",
    "Room 13",
    "Room 14",
    "Room 15",
    "Room 16",
  ];
  const myUser = useUser();

  async function handleSubmit(organization) {
    if (organization === "colibri") {
      setOrg("colibri");
      setIsCands(true);
    } else if (organization === "LAUSD") {
      setOrg("LAUSD");
      setIsCands(true);
    } else {
      setIsCands(true);
    }
  }

  async function handleCount() {
    const date = new Date();
    let val = rooms;
    setRoom([]);
    for (let i = 0; i < val; i++) {
      room.push(roomCount[i]);
    }
    if (room.length === val) {
      await API.graphql(
        graphqlOperation(addDailyGRoom, {
          body: {
            roomCount: room,
            procEmail: myUser.user.attributes.email,
            date: date,
          },
        })
      )
        .then((res) => {
          if (!res.data.addDailyGRoom.includes("success")) {
            setGRoomError(true);
          } else {
            setGRoomSuccess(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setGRoomError(true);
        });
    }
  }

  return (
    <Box pad="medium" justify="center" alignContent="center" alignSelf="center">
      <Tabs
        activeIndex={index}
        onActive={onActive}
        justify="center"
        align="center"
      >
        <Tab title="Daily Rooms">
          <Box align="center">
            {gRoomError && (
              <Notification
                toast
                title="Error"
                status="critical"
                message="Something went wrong, please try again"
                onClose={() => setError(false)}
              />
            )}
            {gRoomSuccess && (
              <Notification
                toast
                title="Success"
                status="normal"
                onClose={() => setGRoomSuccess(false)}
                message="Daily Rooms Zap triggered successfully, please check your email for the rooms"
              />
            )}
            <Heading level="4" align="center" textAlign="center">
              Click below to create up to 16 Google rooms for use during your
              shift.
            </Heading>
            <Box align="center" pad="medium">
              <Card pad="medium" align="center" width="medium">
                <Text>Select a number of rooms to create</Text>
                <Box margin="medium" width="small">
                  <Select
                    options={[
                      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                    ]}
                    onChange={({ option }) => setRooms(option)}
                    value={rooms}
                    textAlign="center"
                  />
                </Box>
                <Box align="center" justify="center">
                  <Button
                    label="Create Google Rooms"
                    primary
                    color="#BFBFBF"
                    onClick={handleCount}
                  />
                </Box>
              </Card>
            </Box>
          </Box>
        </Tab>
        <Tab title="One-Time Rooms">
          <Box align="center" width="medium" justify="center">
            <Heading level="3" textAlign="center" align="center">
              One Time Rooms
            </Heading>
            <Box align="center">
              <Card width="medium" pad="medium">
                <Form onSubmit={() => handleSubmit(null)}>
                  <FormField>
                    <TextInput
                      placeholder="Enter Tester Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormField>
                  <FormField>
                    <TextInput
                      type="email"
                      placeholder="Enter Tester Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormField>
                  <FormField>
                    <TextInput
                      placeholder="Enter Organization"
                      value={org}
                      onChange={(e) => setOrg(e.target.value)}
                    />
                  </FormField>
                  <Box align="center" justify="center">
                    <Button
                      label="Submit"
                      type="submit"
                      primary
                      color="#BFBFBF"
                    />
                  </Box>
                </Form>
              </Card>
            </Box>
            <Box align="center">
              {isCands ? (
                <GoogleRoomCard name={name} email={email} org={org} />
              ) : (
                <Box></Box>
              )}
            </Box>
          </Box>
        </Tab>
        <Tab title="Colibri Group Rooms">
          <Box align="center" width="medium" justify="center">
            <Heading level="3" textAlign="center" align="center">
              Colibri Group Rooms
            </Heading>
            <Box align="center">
              <Card margin="medium" pad="medium" width="medium">
                <Form onSubmit={() => handleSubmit("colibri")}>
                  <FormField>
                    <TextInput
                      placeholder="Enter Tester Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormField>
                  <FormField>
                    <TextInput
                      type="email"
                      placeholder="Enter Tester Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormField>
                  <FormField>
                    <TextInput
                      placeholder="Enter Organization"
                      value="Colibri Group"
                      disabled
                    />
                  </FormField>
                  <Box align="center" justify="center">
                    <Button
                      label="Submit"
                      type="submit"
                      primary
                      color="#BFBFBF"
                    />
                  </Box>
                </Form>
              </Card>
            </Box>
            <Box align="center">
              {isCands ? (
                <GoogleRoomCard name={name} email={email} org={org} />
              ) : (
                <Box></Box>
              )}
            </Box>
          </Box>
        </Tab>
        <Tab title="LAUSD Rooms">
          <Box align="center" width="medium" justify="center">
            <Heading level="3" textAlign="center" align="center">
              LAUSD Rooms
            </Heading>
            <Box align="center">
              <Card margin="medium" pad="medium" width="medium">
                <Form onSubmit={() => handleSubmit("LAUSD")}>
                  <FormField>
                    <TextInput
                      placeholder="Enter Tester Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </FormField>
                  <FormField>
                    <TextInput
                      type="email"
                      placeholder="Enter Tester Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormField>
                  <FormField>
                    <TextInput
                      placeholder="Enter Organization"
                      value="LAUSD"
                      disabled
                    />
                  </FormField>
                  <Box align="center" justify="center">
                    <Button
                      label="Submit"
                      type="submit"
                      primary
                      color="#BFBFBF"
                    />
                  </Box>
                </Form>
              </Card>
            </Box>
            <Box align="center">
              {isCands ? (
                <GoogleRoomCard name={name} email={email} org={org} />
              ) : (
                <Box></Box>
              )}
            </Box>
          </Box>
        </Tab>
      </Tabs>
    </Box>
  );
}

export default GoogleRooms;
