import { Box, FormField, TextInput, TextArea, Select } from "grommet";
import React, { useState } from "react";
// first and last name field
export function NameField() {
  return (
    <Box align="center" justify="center" direction="row" gap="xsmall">
      <FormField label="First Name" name="first_name" required={true}>
        <TextInput
          name="first_name"
          placeholer="First Name"
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
      <FormField label="Last Name" name="last_name" required={true}>
        <TextInput
          name="last_name"
          placeholer="Last Name"
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}
// email field
export function EmailField() {
  return (
    <Box>
      <FormField label="Email" name="email" required={true}>
        <TextInput
          name="email"
          placeholer="Email"
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}
// phone field
export function PhoneField() {
  return (
    <Box>
      <FormField label="Phone" name="phone" required={true}>
        <TextInput
          name="phone"
          placeholer="Phone"
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}
// Exam field
export function ExamField() {
  return (
    <Box>
      <FormField label="Exam" name="exam" required={true}>
        <TextInput
          name="exam"
          placeholer="Exam"
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}
// candidate_id field
export function CandidateIdField() {
  return (
    <Box>
      <FormField label="Candidate ID" name="candidate_id" required={true}>
        <TextInput
          name="candidate_id"
          placeholer="Candidate ID"
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}

// school field
export function SchoolField() {
  return (
    <Box>
      <FormField label="School" name="school" required={true}>
        <TextInput
          name="school"
          placeholer="School"
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}

// instructor field
export function InstructorField() {
  return (
    <Box>
      <FormField label="Instructor" name="instructor" required={true}>
        <TextInput
          name="instructor"
          placeholer="Instructor"
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}

// lesson_id field
export function LessonIdField() {
  return (
    <Box>
      <FormField label="Lesson ID" name="lesson_id" required={true}>
        <TextInput
          name="lesson_id"
          placeholer="Lesson ID"
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}

// appointment field
export function AppointmentField() {
  return (
    <Box>
      <FormField
        label="Appointment/ Start Time"
        name="appointment"
        required={true}
      >
        <TextInput
          name="appointment"
          placeholer="Appointment"
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}

// receipt_number field
export function ReceiptNumberField() {
  return (
    <Box>
      <FormField label="Receipt Number" name="receipt_number" required={true}>
        <TextInput
          name="receipt_number"
          placeholer="Receipt Number"
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}

// notes field TextArea
export function NotesField() {
  return (
    <Box>
      <FormField
        label="Notes - Adjust my size by clicking in the lower right corner"
        name="notes"
        required={true}
      >
        <TextArea
          name="notes"
          placeholer="Notes"
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}

// start_time and end_time field
export function StartTimeField() {
  return (
    <Box>
      <FormField label="Start Time" name="start_time" required={true}>
        <TextInput
          name="start_time"
          placeholer="Start Time"
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}
export function EndTimeField() {
  return (
    <Box>
      <FormField label="End Time" name="end_time" required={true}>
        <TextInput
          name="end_time"
          placeholer="End Time"
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}

// google meet link field
export function GoogleMeetLinkField() {
  return (
    <Box>
      <FormField
        label="Google Meet Link"
        name="google_meet_link"
        required={true}
      >
        <TextInput
          name="google_meet_link"
          placeholer="Google Meet Link"
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}
// score field
export function ScoreField() {
  return (
    <Box>
      <FormField label="Score" name="score" required={true}>
        <TextInput
          name="score"
          placeholer="Score"
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}
// pass field select pass or fail
export function PassField() {
  return (
    <Box>
      <FormField label="Pass" name="pass" required={true}>
        <Select
          name="pass"
          options={["pass", "fail"]}
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}
// exam_password field
export function ExamPasswordField() {
  return (
    <Box>
      <FormField label="Exam Password" name="exam_password" required={true}>
        <TextInput
          name="exam_password"
          placeholer="Exam Password"
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}

// status field disabled
export function StatusField() {
  return (
    <Box>
      <FormField label="Status" name="status" required={true}>
        <TextInput
          name="status"
          placeholer="Active"
          disabled
          style={{ backgroundColor: "white" }}
          required={true}
        />
      </FormField>
    </Box>
  );
}

// flag field select green, yellow, red
export function FlagField() {
  const [color, setColor] = useState("white");
  return (
    <Box>
      <FormField label="Flag" name="flag" required={true}>
        <Select
          name="flag"
          options={["green", "yellow", "red"]}
          style={{ backgroundColor: color }}
          onChange={(event) => {
            setColor(event.target.value);
          }}
          required={true}
        />
      </FormField>
    </Box>
  );
}
