import {
  Box,
  Cards,
  Data,
  DataFilter,
  DataFilters,
  DataSearch,
  DataSummary,
  DateInput,
  Select,
  Spinner,
  Text,
  TextInput,
  Toolbar,
} from "grommet";
import React from "react";
import RoomList from "./RoomList";
import { API } from "aws-amplify";
import { getGoogleRoomsByCreatedAtDate } from "../../graphql/queries";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

export default function MyRooms({
  roomList,
  tabIndex,
  setRoomList,
  isRoomList,
  setIsRoomList,
}) {
  const [value, setValue] = React.useState(
    dayjs().tz("America/Chicago").format("YYYY-MM-DD")
  );
  async function handleDateChange(list, nextToken, event) {
    setValue(event.value);
    const date = event.value.split("T")[0];

    let temp = list;
    const data = await API.graphql({
      query: getGoogleRoomsByCreatedAtDate,
      variables: nextToken
        ? {
            createdAtDate: date,
            nextToken: nextToken,
          }
        : { createdAtDate: date },
    });

    temp = [...temp, ...data.data.googleRoomByDate.items];

    return data.data.googleRoomByDate.nextToken
      ? await handleDateChange(
          temp,
          data.data.googleRoomByDate.nextToken,
          event
        )
      : temp;
  }

  function doItToEm(event) {
    setIsRoomList(true);
    setRoomList([]);
    handleDateChange([], null, event).then((data) => {
      setRoomList(data);
      if (data.length > 0) {
        setIsRoomList(true);
      } else {
        setIsRoomList(false);
      }
    });
  }
  return (
    <Box pad="small" gap="small">
      {tabIndex === 1 && (
        <Box
          width="small"
          direction="row"
          align="center"
          border
          pad={{ horizontal: "small" }}
          round="small"
          gap="medium"
        >
          <Text size="small" weight="bold">
            {value}
          </Text>
          <DateInput
            onChange={(event) => doItToEm(event)}
            value={value}
            size="small"
          />
        </Box>
      )}
      {roomList.length === 0 ? (
        <Box align="center" justify="center" pad="medium">
          {isRoomList ? (
            <Spinner size="large" />
          ) : (
            <Text size="small" weight="bold">
              No rooms found
            </Text>
          )}
        </Box>
      ) : (
        <Data
          data={roomList}
          defaultView={{
            sort: {
              property: "createdAt",
              direction: "desc",
            },
          }}
        >
          <Toolbar align="center">
            <DataSearch placeholder="Search" />
            <DataFilters layer>
              <DataFilter property="clientName" label="Client" />
              {tabIndex === 1 ? (
                <>
                  <DataFilter property="organizer" label="Proctor" />
                </>
              ) : (
                <>
                  <DataFilter property="createdAtDate" label="Date" />
                </>
              )}
            </DataFilters>
            <DataSummary />
          </Toolbar>
          <Cards size="medium">
            {(item) => <RoomList room={item} key={item.id} />}
          </Cards>
        </Data>
      )}
    </Box>
  );
}
