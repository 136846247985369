const Proctors = {
  room1: [
    "a1",
    "b2",
    "c3",
    "d4",
    "e5",
    "f6",
    "g7",
    "h8",
    "k9",
    "m10",
    "n11",
    "p12",
    "q13",
    "r14",
    "s15",
  ],

  room2: [
    "a16",
    "b17",
    "c18",
    "d19",
    "e20",
    "f21",
    "g22",
    "h23",
    "k24",
    "m25",
    "n26",
    "p27",
    "q28",
    "r29",
    "s30",
  ],

  room3: [
    "a31",
    "b32",
    "c33",
    "d34",
    "e35",
    "f36",
    "g37",
    "h38",
    "k39",
    "m40",
    "n41",
    "p42",
    "q43",
    "r44",
    "s45",
  ],
};

export default Proctors;
