import { API, graphqlOperation } from "aws-amplify";
import {
  Box,
  Button,
  Form,
  FormField,
  Heading,
  TextInput,
  Card,
} from "grommet";
import React, { useState, useEffect } from "react";
import { webcamLinkByDate } from "../graphql/queries";
import { updateWebcamLink } from "../graphql/mutations";

function SCForm() {
  const [proc, setProc] = useState("");
  const [tester, setTester] = useState("");
  const [linkArray, setLinkArray] = useState(null);
  const [linksInUse, setLinksInUse] = useState([]);
  const [testerArray, setTesterArray] = useState([]);

  useEffect(() => {
    if (linkArray !== null) {
      mutateLink(linkArray).then(
        () => {
          setLinkArray(null);
        },
        (err) => {
          console.log(err);
        }
      );
    }
    async function mutateLink(arg) {
      try {
        const updatedLink = await API.graphql({
          query: updateWebcamLink,
          variables: {
            input: { id: arg.id, proctor: proc, tester: tester, status: true },
          },
        });
        const updatedData = updatedLink.data.updateWebcamLink;
        setLinksInUse((linksInUse) => [
          ...linksInUse,
          `www.monitoredu.live/sc?embed=${updatedData.embedid}&roomid=${updatedData.roomid}`,
        ]);
        setTesterArray((testerArray) => [...testerArray, tester]);
      } catch (error) {
        console.log(error);
      }
    }
  }, [linkArray, proc, tester]);

  function handleSubmit(e) {
    e.preventDefault();
    getLinks();
  }

  async function getLinks() {
    try {
      const links = await API.graphql(
        graphqlOperation(webcamLinkByDate, {
          sortDirection: "ASC",
          type: "WebcamLink",
          filter: { status: { eq: false } },
          limit: 600,
        })
      );

      setLinkArray(links.data.webcamLinkByDate.items[0]);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Box align="center" justify="center" fill="vertical" pad="medium">
        <Box align="center" pad="medium">
          <Heading>Webcam Link Form</Heading>
          <Box align="center" pad="medium">
            <Card align="center" width="medium" pad="medium">
              <Form onSubmit={handleSubmit}>
                <FormField>
                  <TextInput
                    placeholder="Proctor"
                    onChange={(e) => setProc(e.target.value)}
                  />
                </FormField>
                <FormField>
                  <TextInput
                    placeholder="Tester"
                    onChange={(e) => setTester(e.target.value)}
                  />
                </FormField>
                <Box align="center" pad="medium">
                  <Button
                    type="submit"
                    label="Submit"
                    primary
                    color="#BFBFBF"
                  />
                </Box>
              </Form>
            </Card>
          </Box>
        </Box>
        <Box align="center" margin="medium" fill="horizontal">
          {linksInUse.map((link, i) => (
            <Box key={i} width="xxlarge" align="center" fill={true}>
              <Heading level="4" fill={true}>
                {testerArray[i]} | {link}
              </Heading>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}

export default SCForm;
