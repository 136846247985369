import { Box, Button } from "grommet";
import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useUser } from "../AuthContext";

function Proctor() {
  const myUser = useUser();
  /*
  useEffect(() => {
    const scrip = document.createElement("script");
    scrip.async = true;
    scrip.src = "https://accounts.google.com/gsi/client";
    document.body.appendChild(scrip);

    window.customCallback = (data) => {
      console.log("customCallback", data);
    };
  }, []);
  */
  return (
    <>
      <Box align="center" justify="center" direction="column" pad="small">
        {myUser.isUser ? (
          <Box></Box>
        ) : (
          <>
            <Button
              primary
              label="Sign In"
              onClick={() => {
                Auth.federatedSignIn({ provider: "Google" });
              }}
            ></Button>
          </>
        )}
      </Box>
    </>
  );
}

export default Proctor;

/*

<div
          id="g_id_onload"
          data-client_id="697717554650-b9eobjal9867ibfqi3c7dgc0h25vnkoo.apps.googleusercontent.com"
          data-context="signin"
          data-ux_mode="popup"
          data-callback="customCallback"
          data-auto_prompt="false"
        ></div>

        <div
          className="g_id_signin"
          data-type="standard"
          data-shape="pill"
          data-theme="filled_black"
          data-text="signin_with"
          data-size="large"
          data-logo_alignment="left"
        ></div>


        */
