import React from "react";

function Laspau() {
  return (
    <div style={{ "text-align": "center" }}>
      <h1>LASPAU</h1>
      <h2>Click below to connect to a proctor</h2>
      <a href="https://tawk.to/chat/5e9e92ee69e9320caac5baf9/default">
        Click Here
      </a>
    </div>
  );
}

export default Laspau;
