import React from "react";
import Proctors from "../Global/proctors";
import OtMobile from "../Components/OtMobile";
import RoomGrid from "../Global/RoomGrid";

function Demo() {
  const proc = Proctors.room1;
  const embed = "d93a188a-484c-4e63-b397-2eaefacbe707";
  const id = "demo";
  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Room Codes:</h2>
      <div className="demo-grid">
        <RoomGrid id={null} rooms={proc} />
      </div>
      <h1 style={{ textAlign: "center" }}> DEMO ROOM </h1>
      <br />
      <OtMobile rooms={proc} embedCode={embed} proc={id} />
    </div>
  );
}

export default Demo;
