import React from "react";
import { Card, CardBody, CardHeader, Text, Button } from "grommet";
import { API } from "aws-amplify";
import { updateWebcamLink } from "../graphql/mutations";
import { UpdateTwilioSCRoom } from "../graphql/mutations";
import { v4 as uuidv4 } from "uuid";
//SammyCamUserCard
//Used to identify who is where in Sammy Cams for those watching
//Receives Proctor, Tester, RoomID, EmbedId
//Has "Finish Button"
function TwilioUserCard(users) {
  // function to finish tester
  async function finishTester(id) {
    try {
      await API.graphql({
        query: UpdateTwilioSCRoom,
        variables: {
          input: {
            id: id,
            proctor: null,
            tester: null,
            status: "inactive",
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      {users.users.map((user, index) => {
        return (
          <Card key={index}>
            <CardHeader
              align="center"
              direction="row-responsive"
              flex={false}
              justify="center"
              gap="medium"
              pad="small"
            ></CardHeader>
            <CardBody pad="medium" align="center">
              <Text textAlign="start">Proctor: {user.proctor}</Text>
              <Text textAlign="start">Tester: {user.tester} </Text>
              <Text textAlign="start">Room: {user.id} </Text>
              <Button
                label="Open Room"
                onClick={() =>
                  window.open(
                    `/tsc?embed=${uuidv4()}&roomid=${user.id}`,
                    "_blank"
                  )
                }
              />
              <Button
                label="Finish Tester"
                onClick={() => finishTester(user.id)}
              />
            </CardBody>
          </Card>
        );
      })}
    </>
  );
}

export default TwilioUserCard;
