import { Box, Heading } from "grommet";
import React, { useState, useEffect } from "react";

import DBTLogo from "../Components/dbtlogo";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

function DBTSchedule() {
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const { search } = useLocation();
  const { meduId } = queryString.parse(search);

  useEffect(() => {
    if (meduId) {
      setLoaded(true);
    } else {
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box align="center" justify="center" pad="large">
      <DBTLogo />
      {error && (
        <Box align="center" justify="center" pad="large">
          <Heading textAlign="center">
            Invalid Link, please use the link provided in your registration
            email
          </Heading>
        </Box>
      )}

      {loaded && (
        <iframe
          id="JotFormIFrame-221305211951039"
          title="dbt | MonitorEDU Exam Registration "
          onLoad={window.parent.scrollTo(0, 0)}
          allowFullScreen={true}
          allow="geolocation; microphone; camera"
          src={"https://form.jotform.com/221305377433148?meduid=" + meduId}
          frameBorder="0"
          style={{
            minWidth: "100%",
            height: "1500px",
            border: "none",
          }}
          scrolling="yes"
        ></iframe>
      )}
    </Box>
  );
}

export default DBTSchedule;
