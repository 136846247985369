import React from "react";

function TesterCard(props) {
  return (
    <div>
      <iframe
        src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${props.embed}&room=${props.room}&iframe=true`}
        style={{ width: "400px", height: "400px" }}
        scrolling="auto"
        allow="microphone; camera"
        title="Tester-room1"
      ></iframe>
    </div>
  );
}

export default TesterCard;
