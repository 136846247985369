import React, { useEffect, useState } from "react";
import { users, clients, rooms } from "../fakerData/index";
import { Box, Button, Tab, Tabs } from "grommet";
import MyRooms from "../Components/GoogleRooms/MyRooms";
import { API, Auth } from "aws-amplify";
import {
  getGoogleRoomsByCreatedAtDate,
  getGoogleRoomsByOrganizer,
  getInitialGoogleRoomData,
} from "../graphql/queries";
import dayjs from "dayjs";
import { Add } from "grommet-icons";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);
// 5 search contexts by mine, by all, by user, by client, by date

function GoogleRoomIntegration() {
  const [roomList, setRoomList] = useState([]);
  const [clientList, setClientList] = useState(clients);
  const [userList, setUserList] = useState(users);
  const [tabIndex, setTabIndex] = useState(0);
  const [currentUser, setCurrentUser] = useState(users[0]);
  const [isRoomList, setIsRoomList] = useState(true);

  // effect to fetch initial data sets
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log(user.attributes.email);
        setCurrentUser(user.attributes.email);
        fetchInitialData("Timmy_Glover1@gmail.com");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // effect when tabIndex changes to all rooms
  useEffect(() => {
    setRoomList([]);
    setIsRoomList(true);
    if (tabIndex === 0) {
      fetchInitialData(currentUser);
    }
    if (tabIndex === 1) {
      const newRoomList = fetchTodayRooms([], null).then((res) => {
        console.log(res);
        setRoomList(res);
        if (res.length > 0) {
          setIsRoomList(true);
        } else {
          setIsRoomList(false);
        }
      });
    }
  }, [tabIndex]);
  async function fetchInitialData(user) {
    const data = await API.graphql({
      query: getInitialGoogleRoomData,
    });

    const roo = await fetchRoomsByOrganizer(user, [], null).then((res) => {
      setRoomList(res);
      if (res.length > 0) {
        setIsRoomList(true);
      } else {
        setIsRoomList(false);
      }
    });
    setUserList(data.data.listUsers.items);
    setClientList(data.data.listClients.items);
  }

  async function fetchRoomsByOrganizer(organizer, list, nextToken) {
    let temp = list;
    const data = await API.graphql({
      query: getGoogleRoomsByOrganizer,
      variables: nextToken
        ? { organizer: organizer, sortDirection: "DESC", nextToken: nextToken }
        : { organizer: organizer, sortDirection: "DESC" },
    });

    temp = [...temp, ...data.data.googleRoomByOrganizer.items];

    return data.data.googleRoomByOrganizer.nextToken
      ? await fetchRoomsByOrganizer(
          organizer,
          temp,
          data.data.googleRoomByOrganizer.nextToken
        )
      : temp;
  }

  async function fetchTodayRooms(list, nextToken) {
    // get today's date
    const today = dayjs().tz("America/Chicago").format("YYYY-MM-DD");

    let temp = list;
    const data = await API.graphql({
      query: getGoogleRoomsByCreatedAtDate,
      variables: nextToken
        ? {
            createdAtDate: today,
            nextToken: nextToken,
          }
        : { createdAtDate: today },
    });

    temp = [...temp, ...data.data.googleRoomByDate.items];

    return data.data.googleRoomByDate.nextToken
      ? await fetchTodayRooms(temp, data.data.googleRoomByDate.nextToken)
      : temp;
  }

  return (
    <Box
      align="start"
      justify="start"
      fill="horizontal"
      width="full"
      gap="small"
      pad={{ top: "medium" }}
    >
      <Button label="Add New Room" primary size="large" icon={<Add />} />
      <Tabs
        activeIndex={tabIndex}
        onActive={(nextIndex) => setTabIndex(nextIndex)}
        alignControls="start"
        fill="horizontal"
      >
        <Tab title="My Rooms">
          <Box pad="medium" width="100%">
            <MyRooms
              roomList={roomList}
              tabIndex={0}
              isRoomList={isRoomList}
              setIsRoomList={setIsRoomList}
            />
          </Box>
        </Tab>
        <Tab title="All Rooms">
          <Box pad="medium" width="100%">
            <MyRooms
              roomList={roomList}
              tabIndex={1}
              setRoomList={setRoomList}
              isRoomList={isRoomList}
              setIsRoomList={setIsRoomList}
            />
          </Box>
        </Tab>
        <Tab title="By Room Code"></Tab>
      </Tabs>
    </Box>
  );
}

export default GoogleRoomIntegration;
