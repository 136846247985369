import { API } from "aws-amplify";
import {
  Heading,
  Box,
  Card,
  CardHeader,
  CardBody,
  FormField,
  TextInput,
  Select,
  Button,
  Form,
  Notification,
} from "grommet";
import React, { useState } from "react";
import { DbtByEmail, listDBTTesters } from "../graphql/queries";
import DbtCodeCard from "./DbtCodeCard";

const apptMonth = [
  { label: "January", value: "Jan" },
  { label: "February", value: "Feb" },
  { label: "March", value: "Mar" },
  { label: "April", value: "Apr" },
  { label: "May", value: "May" },
  { label: "June", value: "Jun" },
  { label: "July", value: "Jul" },
  { label: "August", value: "Aug" },
  { label: "September", value: "Sep" },
  { label: "October", value: "Oct" },
  { label: "November", value: "Nov" },
  { label: "December", value: "Dec" },
];

const apptDay = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

function DbtCodes() {
  const [email, setEmail] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [items, setItems] = useState([]);
  const [isItems, setIsItems] = useState(false);
  const [error, setError] = useState(false);

  async function searchByEmail() {
    setIsItems(false);
    try {
      const data = await API.graphql({
        query: DbtByEmail,
        variables: {
          email: email,
        },
      });

      if (data.data.dbtByEmail.items.length > 0) {
        console.log(data.data.dbtByEmail.items);
        setItems(data.data.dbtByEmail.items);
        setIsItems(true);
      } else {
        setError(true);
        setIsItems(false);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setEmail("");
      setMonth("");
      setDay("");
    }
  }

  async function searchByAppointment() {
    setIsItems(false);
    console.log(month);
    try {
      const data = await API.graphql({
        query: listDBTTesters,
        variables: {
          filter: { appointment: { contains: month + " " + day } },
          limit: 600,
        },
      });
      if (data.data.listDBTTesters.items.length > 0) {
        console.log(data.data.listDBTTesters.items);
        setItems(data.data.listDBTTesters.items);
        setIsItems(true);
      } else {
        setError(true);
        setIsItems(false);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setEmail("");
      setMonth("");
      setDay("");
    }
  }

  return (
    <Box
      align="center"
      justify="center"
      direction="column"
      flex="grow"
      margin="xsmall"
    >
      {error && (
        <Notification
          status="critical"
          toast
          onClose={() => setError(false)}
          title="Error"
          message="Candidate(s) not found"
        />
      )}
      <Heading>DBT Exam Links</Heading>
      <Box
        align="center"
        justify="center"
        direction="row-responsive"
        gap="small"
      >
        <Box align="center" justify="center">
          <Card pad="medium" width="medium" height="medium">
            <CardHeader align="center" justify="center">
              <Heading level={3} textAlign="center">
                Search By Email
              </Heading>
            </CardHeader>
            <CardBody align="center" justify="center">
              <Form onSubmit={searchByEmail}>
                <FormField>
                  <TextInput
                    placeholder="Enter Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </FormField>
                <Box align="center" justify="center">
                  <Button
                    type="submit"
                    label="Submit"
                    primary
                    color="#BFBFBF"
                  />
                </Box>
              </Form>
            </CardBody>
          </Card>
        </Box>
        <Box align="center" justify="center">
          <Card pad="medium" width="medium" height="medium">
            <CardHeader align="center" justify="center">
              <Heading level={3} textAlign="center">
                Search By Appointment
              </Heading>
            </CardHeader>
            <CardBody align="center" justify="center">
              <Form onSubmit={searchByAppointment}>
                <FormField>
                  <Select
                    placeholder="Select Month"
                    labelKey={"label"}
                    valueKey={{ key: "value", reduce: true }}
                    value={month}
                    options={apptMonth}
                    onChange={({ value: nextValue }) => setMonth(nextValue)}
                  />
                </FormField>
                <FormField>
                  <Select
                    placeholder="Select Day"
                    options={apptDay}
                    value={day}
                    onChange={({ option }) => setDay(option)}
                  />
                </FormField>
                <Box align="center" justify="center">
                  <Button
                    type="submit"
                    label="Submit"
                    primary
                    color="#BFBFBF"
                  />
                </Box>
              </Form>
            </CardBody>
          </Card>
        </Box>
        {/*
        <Box align="center" justify="center">
          <Card pad="medium" width="medium" height="medium">
            <CardHeader align="center" justify="center">
              <Heading level={3} textAlign="center">
                List All Appointments
              </Heading>
            </CardHeader>
            <CardBody align="center" justify="center">
              <Button
                onClick={listAllAppointments}
                label="List All"
                primary
                color="#BFBFBF"
              />
            </CardBody>
          </Card>
        </Box>
        */}
      </Box>
      <Box
        align="center"
        justify="center"
        direction="row-responsive"
        wrap={true}
        gap="small"
        pad="medium"
      >
        {isItems &&
          items.map((itm, idx) => (
            <DbtCodeCard
              key={idx}
              name={itm.name}
              email={itm.email}
              appointment={itm.appointment}
              ident={itm.id}
              deliveryId={itm.deliveryId}
              exam={itm.exam ? itm.exam : "1"}
            />
          ))}
      </Box>
    </Box>
  );
}

export default DbtCodes;
