import React, { useEffect, useState } from "react";

import TesterCard from "../Components/TesterCard";
function Practice() {
  const [isRoom, setIsRoom] = useState(false);
  const [roomCode, setRoomCode] = useState(null);

  useEffect(() => {
    const tod = Date.now();
    const toStr = tod.toString();
    const splitter = toStr.split("");
    const len = splitter.length;
    setRoomCode(
      "prac" + splitter[len - 1] + splitter[len - 2] + splitter[len - 3]
    );
  }, []);

  const embed = "d93a188a-484c-4e63-b397-2eaefacbe707";

  function handleSubmit(e) {
    e.preventDefault();
    setIsRoom(true);
  }

  return (
    <div style={{ textAlign: "center" }}>
      <form onSubmit={handleSubmit}>
        <label>Enter Room Code:</label>
        <input type="text" value={roomCode}></input>
        <input type="submit" value="submit"></input>
      </form>
      {isRoom ? (
        <TesterCard embed={embed} room={roomCode} />
      ) : (
        <h3>Press Submit</h3>
      )}
    </div>
  );
}

export default Practice;
