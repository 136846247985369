import {
  Card,
  CardBody,
  CardHeader,
  Text,
  Button,
  CardFooter,
  Notification,
} from "grommet";
import React, { useState } from "react";
import { useUser } from "../AuthContext";
import { addGRoom, addLausdGRoom, addColibriGRoom } from "../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
// Card for google room generator
//receives props: name, email, organization

function GoogleRoomCard({ name, email, org }) {
  const [error, setError] = useState(false); //false if no error, true if error
  const [success, setSuccess] = useState(false); //false if no success, true if success
  const myUser = useUser(); //user object from AuthContext

  //Determine organization to find which mutation to use
  //LAUSD goes to Patrick's calendar
  //others go to Don's calendar
  async function handleClick() {
    const date = new Date();
    if (org === "LAUSD") {
      await API.graphql(
        graphqlOperation(addLausdGRoom, {
          body: {
            name: name,
            email: email,
            procEmail: myUser.user.attributes.email,
            date: date,
          },
        })
      )
        .then((res) => {
          if (res.data.addLausdGRoom.includes("success")) {
            setSuccess(true);
          } else {
            setError(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setError(true);
        });
    } else if (org === "colibri") {
      await API.graphql(
        graphqlOperation(addColibriGRoom, {
          body: {
            name: name,
            email: email,
            procEmail: myUser.user.attributes.email,
            date: date,
          },
        })
      )
        .then((res) => {
          if (res.data.addColibriGRoom.includes("success")) {
            setSuccess(true);
          } else {
            setError(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setError(true);
        });
    } else {
      await API.graphql(
        graphqlOperation(addGRoom, {
          body: {
            name: name,
            email: email,
            procEmail: myUser.user.attributes.email,
            date: date,
            org: org,
          },
        })
      )
        .then((res) => {
          console.log(res);
          if (res.data.addGRoom.includes("success")) {
            setSuccess(true);
          } else {
            setError(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setError(true);
        });
    }
  }

  return (
    <>
      {error && (
        <Notification
          toast
          title="Error"
          status="critical"
          onClose={() => setError(false)}
          message="Something went wrong, please make sure all inputs are correct"
        />
      )}
      {success && (
        <Notification
          toast
          title="Success"
          status="normal"
          onClose={() => setSuccess(false)}
          message="Zap triggered successfully, if you don't recieve the invitation shortly, try again"
        />
      )}
      <Card width="medium" margin="medium" pad="medium">
        <CardHeader
          align="center"
          direction="row-responsive"
          justify="center"
          flex={false}
          gap="medium"
          pad="small"
        >
          <Text size="large" textAlign="center">
            Google Room Generator
          </Text>
        </CardHeader>
        <CardBody pad="small">
          <Text textAlign="start">Name: {name}</Text>
          <Text textAlign="start">Email: {email}</Text>
          <Text textAlign="start">Organization: {org}</Text>
        </CardBody>
        <CardFooter align="center" justify="center">
          <Button label="Create Google Room" onClick={handleClick} primary />
        </CardFooter>
      </Card>
    </>
  );
}

export default GoogleRoomCard;
