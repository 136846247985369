import React, { useEffect, useState } from "react";
import { Box, Card, CardBody, Button, Text, CardHeader, Form } from "grommet";
import {
  NameField,
  EmailField,
  PhoneField,
  ExamField,
  CandidateIdField,
  SchoolField,
  InstructorField,
  LessonIdField,
  AppointmentField,
  ReceiptNumberField,
  NotesField,
  StartTimeField,
  EndTimeField,
  GoogleMeetLinkField,
  ScoreField,
  PassField,
  ExamPasswordField,
  StatusField,
  FlagField,
} from "./PopUpNotesCardFields";
import { API } from "aws-amplify";
import { getClient } from "../../graphql/queries";

const keysToComponentMap = {
  name: NameField,
  email: EmailField,
  phone: PhoneField,
  exam: ExamField,
  candidate_id: CandidateIdField,
  school: SchoolField,
  instructor: InstructorField,
  lesson_id: LessonIdField,
  appointment: AppointmentField,
  receipt_number: ReceiptNumberField,
  notes: NotesField,
  start_time: StartTimeField,
  end_time: EndTimeField,
  google_meet_link: GoogleMeetLinkField,
  score: ScoreField,
  pass: PassField,
  exam_password: ExamPasswordField,
  status: StatusField,
  flag: FlagField,
};
/*
const mockConfig = [
  {
    component: "name",
  },
  {
    component: "email",
  },
  {
    component: "phone",
  },
  {
    component: "exam",
  },
  {
    component: "candidate_id",
  },
  {
    component: "appointment",
  },
  {
    component: "receipt_number",
  },
  {
    component: "notes",
  },
  {
    component: "start_time",
  },
  {
    component: "end_time",
  },
  {
    component: "google_meet_link",
  },

  {
    component: "exam_password",
  },
  {
    component: "status",
  },
  {
    component: "flag",
  },
];
*/

function PopUpNotesCard({
  session,
  sendToQueue,
  value,
  setValue,
  finishSession,
  setShowDNSWarning,
  sendBackToProctor,
  setShowCompletionWarning,
}) {
  const [noteFormat, setNoteFormat] = useState([]);
  const [loaded, setLoaded] = useState(false);

  //const [error, setError] = useState(false);

  useEffect(() => {
    async function fetchClient() {
      try {
        const data = await API.graphql({
          query: getClient,
          variables: { id: session.client_id },
        });

        if (data.data.getClient) {
          setNoteFormat(JSON.parse(data.data.getClient.note_format));
          setLoaded(true);
        }
      } catch (err) {
        console.log("error fetching client", err);
      }
    }
    fetchClient();
    // loop through session and set null values to empty strings
    const sessionCopy = { ...session };
    Object.keys(sessionCopy).forEach((key) => {
      if (sessionCopy[key] === null) {
        sessionCopy[key] = "";
      }
    });
    setValue(sessionCopy);
  }, []);

  return (
    <>
      <Box fill={true} align="center" justify="center">
        <Card
          align="center"
          justify="center"
          pad="small"
          fill={true}
          background={{ color: "active" }}
        >
          <CardHeader pad="small" align="center" justify="center">
            <Text size="large" weight="bold">
              {session.client_name}
            </Text>
          </CardHeader>
          <CardBody
            pad="xsmall"
            direction="row"
            gap="xsmall"
            align="start"
            justify="center"
            fill={true}
            overflow="scroll"
          >
            <Form
              onChange={(event) => {
                setValue(event);
              }}
              onSubmit={() => {
                finishSession();
              }}
              value={value}
              style={{ width: "100%" }}
            >
              {loaded &&
                noteFormat.map((config) => {
                  const Component = keysToComponentMap[config.component];
                  return <Component key={config.component} />;
                })}

              <Box direction="row" justify="center" align="center" gap="small">
                {/*
                <Button
                  type="button"
                  label="Set DNS"
                  onClick={() => setShowDNSWarning(true)}
                />
                */}
                <Button
                  type="button"
                  label="Send to Queue"
                  onClick={() => sendToQueue(value.id, session.proctor_history)}
                />
                {value.status === "done" ? (
                  <Box direction="row" gap="small">
                    <Button
                      type="button"
                      label="Send Back to Proctor"
                      onClick={() => sendBackToProctor()}
                    />
                    <Button
                      type="button"
                      label="Complete Session"
                      primary
                      onClick={() => setShowCompletionWarning(true)}
                    />
                  </Box>
                ) : (
                  <Button type="submit" label="Finish Tester" primary />
                )}
              </Box>
            </Form>
          </CardBody>
        </Card>
      </Box>
    </>
  );
}

export default PopUpNotesCard;
