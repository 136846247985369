import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { listSessions } from "../graphql/queries";
import { Box, Text, DataTable, Layer } from "grommet";
import PopUpNotesCard from "../Components/Dashboard/PopUpNotesCard";

// how do I do boolean to string conversion?
const convertBoolToString = (bool) => {
  if (bool === true) {
    return "Pass";
  } else if (bool === false) {
    return "Fail";
  } else {
    return "N/A";
  }
};
const columns = [
  {
    property: "createdAt",
    header: "Created At",
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.createdAt}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "first_name",
    header: <Text>First Name</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.first_name}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "last_name",
    header: <Text>Last Name</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.last_name}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "client_name",
    header: <Text>Client Name</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.client_name}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "email",
    header: <Text>Email</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.email}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "phone",
    header: <Text>Phone</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.phone}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "receipt_number",
    header: <Text>Receipt Number</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.receipt_number}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "proctor",
    header: <Text>Proctor</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.proctor}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "exam",
    header: <Text>Exam</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.exam}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "start_time",
    header: <Text>Start Time</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.start_time}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "end_time",
    header: <Text>End Time</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.end_time}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "notes",
    header: <Text>Notes</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.notes}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "large",
  },
  {
    property: "flag",
    header: <Text>Flag</Text>,
    render: (datum) => (
      <Box direction="row" background={datum.flag} fill={true}>
        <Text size="xsmall" truncate>
          {datum.flag}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "score",
    header: <Text>Score</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.score}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "status",
    header: <Text>Status</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.status}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "pass",
    header: <Text>Pass/Fail</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {convertBoolToString(datum.pass)}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "exam_password",
    header: <Text>Exam Password</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.exam_password}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "google_meet_link",
    header: <Text>Google Meet Link</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.google_meet_link}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "candidate_id",
    header: <Text>Candidate ID</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.candidate_id}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "school",
    header: <Text>School</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.school}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "instructor",
    header: <Text>Instructor</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.instructor}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "lesson_id",
    header: <Text>Lesson ID</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.lesson_id}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
  {
    property: "appointment",
    header: <Text>Appointment</Text>,
    render: (datum) => (
      <Box direction="row">
        <Text size="xsmall" truncate>
          {datum.appointment}
        </Text>
      </Box>
    ),
    align: "start",
    alignVertical: "middle",
    size: "small",
  },
];

function Sessions() {
  const [sessions, setSessions] = useState([]);
  const [show, setShow] = useState(false);
  const [clicked, setClicked] = useState("");

  useEffect(() => {
    async function fetchSessions() {
      try {
        const data = await API.graphql({
          query: listSessions,
        });
        console.log(data);
        setSessions(data.data.listSessions.items);
      } catch (err) {
        console.log("error fetching sessions");
      }
    }
    fetchSessions();
  }, []);

  return (
    <Box fill={true} overflow="scroll">
      <DataTable
        columns={columns}
        data={sessions}
        primaryKey="id"
        onClickRow={(event) => {
          console.log(event.datum);
          setClicked(event.datum);
          setShow(true);
        }}
        step={150}
        fill={true}
      />
      {show && (
        <Layer onEsc={() => setShow(false)} full>
          <Text textAlign="center">Scroll Down To View More</Text>
          <Box
            align="start"
            justify="start"
            overflow="auto"
            margin={{ right: "small" }}
            width="100%"
            direction="column"
            gap="small"
            responsive
            wrap={true}
            pad="xsmall"
          >
            <PopUpNotesCard session={clicked} />
          </Box>
        </Layer>
      )}
    </Box>
  );
}

export default Sessions;
