import React from "react";
import "./roomGrid.css";

function RoomGrid(props) {
  return (
    <div>
      <h3 style={{ textAlign: "center" }}>Room Codes:</h3>
      <hr />
      <div className="room-grid">
        {props.rooms.map((itm, idx) => (
          <h5 key={idx}>
            {props.id}
            {itm}
          </h5>
        ))}
      </div>
      <hr />
    </div>
  );
}

export default RoomGrid;
