import React from "react";
import ProctorVideoCard from "../Twillio/components/ProctorVideoCard";

export default function Components() {
  return (
    <div>
      <h1>Components</h1>
      <ProctorVideoCard />
    </div>
  );
}
