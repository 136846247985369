import { Box, Heading, DataTable } from "grommet";
import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { listInvigilExams, invigilexamgetter } from "../graphql/queries";
import { useUser } from "../AuthContext";

function Invigil() {
  const [invigilExams, setInvigilExams] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const myUser = useUser();

  useEffect(() => {
    getExams();
  }, []);

  async function getExams() {
    try {
      const examData = await API.graphql({
        query: listInvigilExams,
        variables: {
          limit: 600,
        },
      });
      const exams = examData.data.listInvigilExams.items;
      const examsTwo = exams;
      // sort to ascending order by examID
      const ex = examsTwo.sort((a, b) => {
        if (Number(a.examId) < Number(b.examId)) {
          return -1;
        }
        if (Number(a.examId) > Number(b.examId)) {
          return 1;
        }
        return 0;
      });

      // filter out duplicate examId's
      const exTwo = ex.filter((exam, index, self) => {
        return (
          index ===
          self.findIndex((t) => {
            return t.examId === exam.examId;
          })
        );
      });

      setInvigilExams(exTwo);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleLaunch(datum) {
    try {
      const linkData = await API.graphql({
        query: invigilexamgetter,
        variables: {
          examId: datum.examId,
          proctorId: myUser.user.attributes.email,
          custId: datum.custId,
        },
      });
      const body = linkData.data.invigilexamgetter;
      const urlObj = JSON.parse(body);
      window.open(urlObj.body.url, "_blank");
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <Box align="center" justify="center">
        <Box align="center">
          <Heading textAlign="center" level="3">
            Invigil Exams
          </Heading>
        </Box>
        {isLoaded ? (
          <Box align="center">
            <Heading level="4">Click on a row to launch Invigil</Heading>
            <DataTable
              pad="medium"
              border={true}
              columns={[
                {
                  header: "Exam Name",
                  property: "title",
                  primary: true,
                  align: "start",
                  size: "small",
                },
                {
                  header: "Exam Dates",
                  property: "date",
                  align: "start",
                  size: "small",
                },
                {
                  header: "Exam ID",
                  property: "examId",
                  align: "start",
                  size: "xxsmall",
                },
                {
                  header: "Special Instructions",
                  property: "instructions",
                  align: "start",
                  size: "xsmall",
                },
                {
                  header: "Allowed Materials",
                  property: "materials",
                  align: "start",
                  size: "small",
                },
              ]}
              data={invigilExams}
              background={{ header: { color: "active", opacity: "medium" } }}
              onClickRow={({ datum }) => handleLaunch(datum)}
            />
          </Box>
        ) : (
          <Box></Box>
        )}
      </Box>
    </>
  );
}

export default Invigil;
