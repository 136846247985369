import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  Box,
  Text,
  ResponsiveContext,
} from "grommet";
import Room from "./Room";

export default function VideoProviderCard({ token, roomId, participantType }) {
  const [isJoined, setIsJoined] = useState(false);

  const size = React.useContext(ResponsiveContext);

  const lobby = () => {
    return (
      <CardBody>
        <Box align="center" justify="center" fill>
          <Button label="Join Room" primary onClick={() => setIsJoined(true)} />
        </Box>
      </CardBody>
    );
  };

  return (
    <>
      <Card width="medium" height={size === "small" ? "75vh" : "medium"}>
        <CardHeader align="center" justify="center" pad="xsmall">
          <Text>Room Code: {roomId}</Text>
        </CardHeader>
        {!isJoined ? (
          lobby()
        ) : (
          <Room
            token={token}
            roomName={roomId}
            onDisconnected={() => setIsJoined(false)}
            type={participantType}
          />
        )}
      </Card>
    </>
  );
}
