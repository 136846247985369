import { useEffect, useState } from "react";

export default function useVideoTrack({ videoTrack }) {
  const [videoOn, setVideoOn] = useState(false);

  useEffect(() => {
    if (videoTrack) {
      // initial state video

      setVideoOn(videoTrack.isTrackEnabled);

      const handleVideoDisabled = () => {
        setVideoOn(false);
      };

      const handleVideoEnabled = () => {
        setVideoOn(true);
      };

      videoTrack.on("disabled", handleVideoDisabled);
      videoTrack.on("enabled", handleVideoEnabled);
      videoTrack.track?.on("stopped", handleVideoDisabled);

      return () => {
        videoTrack.off("disabled", handleVideoDisabled);
        videoTrack.off("enabled", handleVideoEnabled);
        videoTrack.track?.off("stopped", handleVideoDisabled);
      };
    }
  }, [videoTrack]);

  return { videoOn };
}
