import React, { useState } from "react";
import TesterCard from "../Components/TesterCard";

function DemoRoom() {
  const [rName, setRname] = useState("");
  const [isRName, setIsRname] = useState(false);
  const embed = "d93a188a-484c-4e63-b397-2eaefacbe707";
  const roomid = "demo";

  function handleSubmit(e) {
    e.preventDefault();
    setIsRname(true);
  }

  function handleInput(e) {
    setRname(e.target.value);
  }

  return (
    <div style={{ textAlign: "center" }}>
      <form onSubmit={handleSubmit}>
        <label>Enter Room Code:</label>
        <input type="text" onChange={handleInput}></input>
        <input type="submit" value="submit"></input>
      </form>
      {isRName ? (
        <TesterCard room={roomid + rName} embed={embed} />
      ) : (
        <h1> </h1>
      )}
    </div>
  );
}

export default DemoRoom;
