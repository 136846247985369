import React, { useState, useEffect } from "react";
import { Box, Grid, Heading, Text } from "grommet";
import { API } from "aws-amplify";
import { webcamLinkByDate } from "../graphql/queries";
import { onUpdateWebcamLink } from "../graphql/subscriptions";
import SammyCamCard from "../Components/SammyCamCard";

function SammyCamUsers() {
  const [users, setUsers] = useState([]);
  const [isUsers, setIsUsers] = useState(false);

  useEffect(() => {
    getInitialUsers();
    subscribe();
  }, []);

  // initial call to get current webcamusers
  async function getInitialUsers() {
    try {
      const initialUsers = await API.graphql({
        query: webcamLinkByDate,
        variables: {
          sortDirection: "ASC",
          limit: 600,
          filter: {
            status: { eq: true },
          },
          type: "WebcamLink",
        },
      });
      const initialUserData = initialUsers.data.webcamLinkByDate.items;
      setUsers(initialUserData);
      if (initialUserData.length > 0) {
        setIsUsers(true);
      }
    } catch (err) {
      console.log(err);
    }
  }
  // subscribe to changes in webcamusers
  async function subscribe() {
    await API.graphql({
      query: onUpdateWebcamLink,
    }).subscribe({
      next: (messageData) => {
        if (messageData.value.data.onUpdateWebcamLink.status === true) {
          setUsers((users) => [
            ...users,
            messageData.value.data.onUpdateWebcamLink,
          ]);
          if (isUsers === false) {
            setIsUsers(true);
          }
        }

        if (messageData.value.data.onUpdateWebcamLink.status === false) {
          setUsers((users) =>
            users.filter(
              (item) => item.id !== messageData.value.data.onUpdateWebcamLink.id
            )
          );
          if (users.length < 1) {
            console.log(users);
          }
        }
      },
      error: (error) => console.log(error),
    });
  }

  return (
    <Box align="center" justify="center">
      <Heading textAlign="center" size="small">
        SammyCam Users
      </Heading>
      <Box align="center" justify="start" fill="vertical" width="xxlarge">
        <Grid columns={{ count: "fit", size: "small" }} width="100%">
          {isUsers ? (
            <SammyCamCard users={users} />
          ) : (
            <Box>
              <Text>No Current Users</Text>
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default SammyCamUsers;
