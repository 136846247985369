import {
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  Heading,
  Text,
  Box,
  Button,
  Notification,
} from "grommet";
import React, { useState, useEffect } from "react";
import { ValidateOrder } from "../graphql/mutations";
import { API } from "aws-amplify";

function PaymentCard({ test, user }) {
  const [isVerified, setIsVerified] = useState(false);
  const [tester, setTester] = useState(test);
  const [validError, setValidError] = useState(false);

  useEffect(() => {
    if (tester.verified_by) {
      setIsVerified(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValidate = async () => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    if (
      tester.verified_by &&
      tester.item_quantity <= tester.verified_by.length
    ) {
      setValidError(true);
    } else {
      if (tester.verified_by) {
        try {
          const response = await API.graphql({
            query: ValidateOrder,
            variables: {
              input: {
                paymentId: tester.paymentId,
                verified_by: [...tester.verified_by, user],
                verified_at: date,
              },
            },
          });

          setTester(response.data.updatePaidTester);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const response = await API.graphql({
            query: ValidateOrder,
            variables: {
              input: {
                paymentId: tester.paymentId,
                verified_by: [user],
                verified_at: date,
              },
            },
          });

          setTester(response.data.updatePaidTester);
          setIsVerified(true);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const handleInvalidate = async (idx) => {
    const temp = tester.verified_by;
    temp.splice(idx, 1);
    try {
      const response = await API.graphql({
        query: ValidateOrder,
        variables: {
          input: {
            paymentId: tester.paymentId,
            verified_by: temp,
            verified_at: null,
          },
        },
      });

      setTester(response.data.updatePaidTester);
      setIsVerified(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {validError && (
        <Notification
          status="critical"
          toast
          onClose={() => setValidError(false)}
          title="You Can't Do That!"
          message="This Order is already verified"
        />
      )}
      <Card width="500px" background="light-1" margin="xsmall">
        <CardHeader align="center" direction="row-responsive" justify="center">
          <Heading align="center" textAlign="center" level="3" margin="small">
            {tester.cust_name}
          </Heading>
        </CardHeader>
        <CardBody pad="medium">
          <Box
            align="center"
            justify="between"
            direction="row"
            flex
            gap="medium"
          >
            <Text weight="bold">Order Number:</Text>
            <Text weight="bold">{tester.referenceId}</Text>
          </Box>
          <Box
            align="center"
            justify="between"
            direction="row"
            flex
            gap="medium"
          >
            <Text weight="bold">Order Date:</Text>
            <Text weight="bold">
              {new Date(tester.createdAt).toLocaleDateString()}
            </Text>
          </Box>
          <Box
            align="center"
            justify="between"
            direction="row"
            flex
            gap="medium"
          >
            <Text weight="bold">Item: </Text>
            <Text weight="bold">{tester.item_name}</Text>
          </Box>
          <Box
            align="center"
            justify="between"
            direction="row"
            flex
            gap="medium"
          >
            <Text weight="bold">Quantity: </Text>
            <Text weight="bold">{tester.item_quantity}</Text>
          </Box>
          <Box
            align="center"
            justify="between"
            direction="row"
            flex
            gap="medium"
          >
            <Text weight="bold">Email: </Text>
            <Text weight="bold">{tester.cust_email}</Text>
          </Box>
          <Box
            align="center"
            justify="between"
            direction="row"
            flex
            gap="medium"
          >
            <Text weight="bold">Last 4 of CC: </Text>
            <Text weight="bold">{tester.lastFour}</Text>
          </Box>
          <Box
            align="center"
            justify="between"
            direction="row"
            flex
            gap="medium"
          >
            {isVerified && <Text weight="bold">Verified By:</Text>}
          </Box>
          <Box direction="column" flex gap="medium" wrap={true}>
            {isVerified &&
              tester.verified_by.map((itm, idx) => (
                <Box
                  align="center"
                  justify="between"
                  direction="row"
                  flex
                  gap="medium"
                  key={idx}
                >
                  <Text weight="bold">{itm}</Text>
                  <Button
                    label="Invalidate"
                    primary
                    color="#BFBFBF"
                    onClick={() => handleInvalidate(idx)}
                  />
                </Box>
              ))}
            {tester.verified_at && (
              <Box
                align="center"
                justify="between"
                direction="row"
                flex
                gap="medium"
              >
                <Text weight="bold">Verified Date: </Text>
                <Text weight="bold">{tester.verified_at}</Text>
              </Box>
            )}
          </Box>
        </CardBody>
        <CardFooter background="light-2" textAlign="center">
          <Box
            align="center"
            justify="center"
            direction="row"
            flex
            gap="medium"
            height="xxsmall"
          >
            <Button
              textAlign="center"
              onClick={() => handleValidate()}
              label="Validate"
            ></Button>
          </Box>
        </CardFooter>
      </Card>
    </>
  );
}

export default PaymentCard;
