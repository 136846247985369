import { Box, Button, Card, CardBody, CardHeader, Text } from "grommet";

export default function RoomList({ roomList, room }) {
  /*
  return (
    <Box direction="row" wrap>
      {roomList.map((room) => (
        <Card
          key={room.id}
          background="white"
          margin="small"
          width="medium"
          height="medium"
          elevation="medium"
        >
          <CardHeader pad="small">
            <Box border="bottom" width="full">
              <Text size="large" weight="bold">
                {room.clientName}
              </Text>
              <Text size="large">{room.candidateName}</Text>
            </Box>
          </CardHeader>
          <CardBody pad="small">
            <Box>
              <Box direction="row" gap="small">
                <Text size="small" weight="bold">
                  Email:
                </Text>
                <Text size="small">{room.candidateEmail}</Text>
              </Box>
              <Box direction="row" gap="small">
                <Text size="small" weight="bold">
                  Organizer:
                </Text>
                <Text size="small">{room.organizer}</Text>
              </Box>
              <Box direction="row" gap="small">
                <Text size="small" weight="bold">
                  Room Code:
                </Text>
                <Text size="small">{room.roomCode}</Text>
              </Box>
              <Box direction="row" gap="small">
                <Text size="small" weight="bold">
                  Date:
                </Text>
                <Text size="small">{room.createdAtDate}</Text>
              </Box>
            </Box>
          </CardBody>
        </Card>
      ))}
    </Box>
  );
  */

  return (
    <Card background="white" margin="small" width="medium" elevation="medium">
      <CardHeader pad="small">
        <Box border="bottom" width="full">
          <Text size="large" weight="bold">
            {room.clientName}
          </Text>
          <Text size="large">{room.candidateName}</Text>
        </Box>
      </CardHeader>
      <CardBody pad="small">
        <Box>
          <Box direction="row" gap="small" justify="between">
            <Text size="small" weight="bold">
              Email:
            </Text>
            <Text size="small">{room.candidateEmail}</Text>
          </Box>
        </Box>
        <Box direction="row" gap="small" justify="between">
          <Text size="small" weight="bold">
            Organizer:
          </Text>

          <Text size="small">{room.organizer}</Text>
        </Box>
        <Box direction="row" gap="small" justify="between">
          <Text size="small" weight="bold">
            Room Code:
          </Text>
          <Text size="small">{room.roomCode}</Text>
        </Box>
        <Box direction="row" gap="small" justify="between">
          <Text size="small" weight="bold">
            Date:
          </Text>
          <Text size="small">{room.createdAtDate}</Text>
        </Box>
        <Box direction="row" gap="small">
          <Button
            label="Join"
            primary
            tip="Copies the room link to the clipboard"
          />
          <Button label="Delete" secondary />
          <Button label="Get Recording" />
        </Box>
      </CardBody>
    </Card>
  );
}
