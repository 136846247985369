import React, { useRef, useState } from "react";
import {
  Box,
  Card,
  Heading,
  CardBody,
  Form,
  CardHeader,
  Button,
  Text,
} from "grommet";
import {
  NameField,
  EmailField,
  PhoneField,
  CandidateIdField,
  ExamField,
  InstructorField,
  SchoolField,
  LessonIdField,
  AppointmentField,
  ReceiptField,
} from "./PreChatFormFields";
import { API } from "aws-amplify";
import { CreateSession } from "../graphql/mutations";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const keysToComponentMap = {
  name: NameField,
  email: EmailField,
  phone: PhoneField,
  candidate_id: CandidateIdField,
  exam: ExamField,
  instructor: InstructorField,
  school: SchoolField,
  lesson_id: LessonIdField,
  appointment: AppointmentField,
  receipt_number: ReceiptField,
};

function PreChatCard({ client }) {
  const [isPreChat, setIsPreChat] = useState(true);
  const [isChat, setIsChat] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const tawkMessengerRef = useRef();
  const form = JSON.parse(client.pre_chat_form);
  const splitLink = client.tawk_property_link.split("/");
  const widgetId = splitLink[splitLink.length - 1];
  const propertyId = splitLink[splitLink.length - 2];

  const onload = () => {
    console.log("Tawk Messenger Loaded");
    window.Tawk_API.maximize();
    window.Tawk_API.setAttributes({
      name: name,
      email: email,
    });
  };

  async function handleSubmit(event) {
    console.log(event.value);
    try {
      const data = await API.graphql({
        query: CreateSession,
        variables: {
          input: {
            status: "queue",
            client_name: client.name,
            client_id: client.id,
            type: "Session",
            ...event.value,
          },
        },
        authMode: "API_KEY",
      });

      if (data.data.createSession.id != null) {
        setName(
          data.data.createSession.first_name +
            " " +
            data.data.createSession.last_name
        );
        setEmail(data.data.createSession.email);
        setIsPreChat(false);
        setIsChat(true);
      }
    } catch (err) {
      console.log("error creating session:", err);
    }
  }
  return (
    <Box align="center" justify="center" pad="xsmall">
      {isPreChat && (
        <Card pad="xsmall" gap="small" align="center" justify="center">
          <CardHeader align="center" justify="center">
            <Heading level="3" textAlign="center">
              {client.name}
            </Heading>
          </CardHeader>
          <CardBody gap="small" align="center" justify="center">
            <Form
              onSubmit={(event) => {
                handleSubmit(event);
              }}
            >
              {form.map((config, idx) => {
                const Component = keysToComponentMap[config.component];
                return <Component key={idx} />;
              })}
              <Box align="center" justify="center">
                <Button type="submit" label="Submit" primary />
              </Box>
            </Form>
          </CardBody>
        </Card>
      )}
      {isChat && (
        <Box align="center" justify="center" direction="row" gap="small">
          <Text>
            Do not close this window to stay connected to your proctor via chat
          </Text>

          <TawkMessengerReact
            useRef={tawkMessengerRef}
            propertyId={propertyId}
            widgetId={widgetId}
            onLoad={onload}
          />
        </Box>
      )}
    </Box>
  );
}

export default PreChatCard;
