import useParticipant from "./useParticipant";
import useRoomConnector from "./useRoomConnector";
import useToggleMicrophone from "./useToggleMicrophone";
import useToggleCamera from "./useToggleCamera";

export default function useRoom() {
  const { room, error, connectRoom, disconnectRoom } = useRoomConnector();
  const { localParticipant, remoteParticipants } = useParticipant({ room });
  const { isMicrophoneEnabled, toggleMicrophone } = useToggleMicrophone(
    room,
    false
  );
  const { isCameraEnabled, toggleCamera } = useToggleCamera(room, false);

  return {
    room,
    error,
    connectRoom,
    disconnectRoom,
    localParticipant,
    remoteParticipants,
    isMicrophoneEnabled,
    toggleMicrophone,
    isCameraEnabled,
    toggleCamera,
  };
}
