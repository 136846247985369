import {
  Box,
  TextInput,
  Text,
  TextArea,
  Button,
  MaskedInput,
  DateInput,
  Select,
  Notification,
} from "grommet";
import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listCraneGuageSessions } from "../graphql/queries";
import { Search } from "grommet-icons";
import { UpdateCraneGuageSession, addCraneGRoom } from "../graphql/mutations";
import { useUser } from "../AuthContext";
const secondOptions = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
];

export default function Crane() {
  const [sessions, setSessions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [selected, setSelected] = useState(null);
  const keys = ["user_name", "user_email"];

  useEffect(() => {
    // call get sessions, if there is a nextToken, call it again with the nextToken
    let temp = [];
    async function fetchData(token) {
      const data = await getSessions(token);

      temp = [...temp, ...data.items];
      if (data.nextToken) {
        fetchData(data.nextToken);
        console.log("fetch again");
      } else {
        setSessions(temp);
      }

      console.log(temp);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setSearchResults([]);
    const debouncedSearchTerm = debounce(search, 1000);
    debouncedSearchTerm(searchTerm);
  }, [searchTerm]);

  function debounce(func, delay) {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }

  function search(query) {
    if (query.length > 0) {
      const results = sessions.filter((session) => {
        return keys.some((key) => {
          return session[key]
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase());
        });
      });
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }

  async function getSessions(token) {
    const data = await API.graphql({
      query: listCraneGuageSessions,
      variables: token ? { nextToken: token, limit: 300 } : { limit: 300 },
    });

    return data.data.listCraneGuageSessions;
  }

  return (
    <Box align="center" justify="center" pad="medium" gap="medium">
      <Box
        align="center"
        justify="center"
        pad="medium"
        gap="medium"
        width="medium"
      >
        {isSelected ? (
          <Button
            label="Go Back"
            onClick={() => {
              //setIsSelected(false);
              //setSelected(null);
              window.location.reload();
            }}
            primary
          />
        ) : (
          <TextInput
            placeholder="Search for Tester By Name or Email"
            value={searchTerm}
            icon={<Search />}
            onChange={(e) => setSearchTerm(e.target.value)}
            width="medium"
          />
        )}
      </Box>
      <Box
        align="center"
        justify="center"
        pad="small"
        gap="medium"
        direction="column"
        width="full"
      >
        {isSelected ? (
          <SelectedTileForm
            selected={selected}
            setIsSelected={setIsSelected}
            setSelected={setSelected}
          />
        ) : (
          <SelectionTiles
            setSelected={setSelected}
            setIsSelected={setIsSelected}
            searchResults={searchResults}
          />
        )}
      </Box>
    </Box>
  );
}

function SelectionTiles({ setSelected, setIsSelected, searchResults }) {
  return (
    <>
      <Box align="center" justify="center">
        {searchResults.length > 0 && (
          <Text size="large" textAlign="center">
            Select a tester to view details
          </Text>
        )}
      </Box>
      <Box
        align="center"
        justify="center"
        pad="medium"
        gap="medium"
        direction="row"
        wrap={true}
      >
        {searchResults.length > 0 &&
          searchResults.map((session, idx) => {
            return (
              <Box
                key={idx}
                align="center"
                justify="center"
                pad="medium"
                gap="small"
                width="medium"
                margin="small"
                background="light-1"
                round="small"
                direction="column"
                onClick={() => {
                  setSelected(session);
                  setIsSelected(true);
                }}
              >
                <Text>{session.user_name}</Text>
                <Text> {session.user_email}</Text>
                <Text>{session.test_name}</Text>
              </Box>
            );
          })}
      </Box>
    </>
  );
}

function SelectedTileForm({ selected, setSelected, setIsSelected }) {
  const [proctor, setProctor] = useState(
    selected.proctor === null ? "" : selected.proctor
  );
  const [startDate, setStartDate] = useState(new Date().toISOString());
  const [startTime, setStartTime] = useState(
    selected.start_time === null
      ? ""
      : handleTimeConversionFromISOString(selected.start_time)
  );
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [endTime, setEndTime] = useState(
    selected.end_time === null
      ? ""
      : handleTimeConversionFromISOString(selected.end_time)
  );
  const [flag, setFlag] = useState(selected.flag === null ? "" : selected.flag);
  const [notes, setNotes] = useState(
    selected.notes === null ? "" : selected.notes
  );
  const [googleMeetID, setGoogleMeetID] = useState(
    selected.google_meet_id === null ? "" : selected.google_meet_id
  );
  const [isNotification, setIsNotification] = useState(false);

  const user = useUser();

  async function handleSubmit() {
    /*
    console.log("submit");
    console.log("session_id", selected.session_id);
    console.log("proctor", proctor);
    console.log("startDate", startDate);
    console.log("startTime", startTime);
    console.log("endDate", endDate);
    console.log("endTime", endTime);
    console.log("flag", flag);
    console.log("notes", notes);
    console.log("googleMeetID", googleMeetID);
    */

    // make sure fields are not empty or null
    if (
      proctor === "" ||
      startDate === "" ||
      startTime === "" ||
      endDate === "" ||
      endTime === "" ||
      flag === "" ||
      notes === "" ||
      googleMeetID === ""
    ) {
      alert("Please fill out all fields");
      return;
    }

    const start = handleTimeConversionToISOString(startDate, startTime);
    //console.log("start", start);

    const end = handleTimeConversionToISOString(endDate, endTime);
    //console.log("end", end);

    try {
      const data = await API.graphql({
        query: UpdateCraneGuageSession,
        variables: {
          input: {
            session_id: selected.session_id,
            proctor: proctor,
            start_time: start,
            end_time: end,
            flag: flag,
            notes: notes,
            google_meet_id: googleMeetID,
          },
        },
      });
      console.log("submission", data);

      if (data.data.updateCraneGuageSession) {
        alert("Session Updated");
        setSelected(null);
        setIsSelected(false);
      } else {
        alert("Session Not Updated, try again.");
      }
    } catch (error) {
      console.log(error);
      alert("An error occurred, please try again");
    }
  }

  function handleTimeConversionToISOString(date, time) {
    // date is in format of ISO String
    // time is in format of hh:mm ap

    const timeArr = time.split(" ");
    const hour = timeArr[0].split(":")[0];
    const minute = timeArr[0].split(":")[1];
    const ap = timeArr[1];
    const newDate = new Date(date);
    //console.log("newdate", newDate);
    newDate.setHours(ap === "pm" ? parseInt(hour) + 12 : parseInt(hour));
    newDate.setMinutes(minute);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    //console.log("newdate", newDate);
    return newDate.toISOString();
  }

  function handleTimeConversionFromISOString(time) {
    //convert ISO String to time format of hh:mm ap
    const date = new Date(time);
    //console.log("time", time);
    // console.log("date", date);
    const hour = date.getHours();
    const minute = date.getMinutes();
    const ap = hour >= 12 ? "pm" : "am";
    const newHour = hour > 12 ? hour - 12 : hour;
    // make sure minutes are two digits
    if (minute < 10) {
      return `${newHour}:0${minute} ${ap}`;
    }
    return `${newHour}:${minute} ${ap}`;
  }

  async function handleGRoom() {
    try {
      const data = await API.graphql(
        graphqlOperation(addCraneGRoom, {
          body: {
            name: selected.user_name,
            email: selected.user_email,
            procEmail: user.user.attributes.email,
            date: new Date(),
          },
        })
      );

      setIsNotification(true);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Box align="start" justify="center" pad="small" gap="medium" width="large">
      {isNotification && (
        <Notification
          toast
          title="Success"
          status="normal"
          onClose={() => setIsNotification(false)}
          message={
            "Google Room Created, email sent to " +
            selected.user_email +
            " and " +
            user.user.attributes.email
          }
        />
      )}
      <Text size="large" textAlign="center" weight="bold">
        FILL OUT THE FORM BELOW AND CLICK SUBMIT
      </Text>
      <Box
        direction="row"
        gap="medium"
        align="start"
        justify="start"
        fill="horizontal"
      >
        <Text weight="bold">Name: </Text>
        <Text>{selected.user_name}</Text>
      </Box>
      <Box
        direction="row"
        gap="medium"
        align="start"
        justify="start"
        fill="horizontal"
      >
        <Text weight="bold">Email: </Text>
        <Text>{selected.user_email}</Text>
      </Box>
      <Box
        direction="row"
        gap="medium"
        align="start"
        justify="start"
        fill="horizontal"
      >
        <Text weight="bold">Test Name: </Text>
        <Text>{selected.test_name}</Text>
      </Box>
      {selected.password !== "null" && (
        <Box
          direction="row"
          gap="medium"
          align="start"
          justify="start"
          fill="horizontal"
        >
          <Text weight="bold">Test Password: </Text>
          <Text>{selected.password}</Text>
        </Box>
      )}
      {selected.exam_url !== "null" && (
        <Box
          direction="row"
          gap="medium"
          align="start"
          justify="start"
          fill="horizontal"
        >
          <Text weight="bold">Exam URL: </Text>
          <Text>{selected.exam_url}</Text>
        </Box>
      )}
      <Box
        direction="row"
        gap="medium"
        align="start"
        justify="start"
        fill="horizontal"
      >
        <Text weight="bold">Proctor Instructions: </Text>
        <Text>{selected.proctor_instructions}</Text>
      </Box>
      <Box
        direction="row"
        gap="medium"
        align="start"
        justify="start"
        fill="horizontal"
      >
        <TextInput
          placeholder="Proctor"
          value={proctor}
          onChange={(e) => setProctor(e.target.value)}
        />
      </Box>
      <Box
        direction="row"
        gap="medium"
        align="start"
        justify="start"
        fill="horizontal"
      >
        <Text weight="bold">Time is your local time, not Central </Text>
      </Box>
      <Box
        direction="row"
        gap="medium"
        align="start"
        justify="start"
        fill="horizontal"
      >
        <Text weight="bold">Start Date/Time: </Text>
        <DateInput
          format="mm/dd/yyyy"
          value={startDate}
          onChange={({ value }) => {
            setStartDate(value);
          }}
        />
        <MaskedInput
          mask={[
            {
              length: [1, 2],
              options: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
              ],
              regexp: /^1[1-2]$|^[0-9]$/,
              placeholder: "hh",
            },
            { fixed: ":" },
            {
              length: 2,
              options: secondOptions,
              regexp: /^[0-5][0-9]$|^[0-9]$/,
              placeholder: "mm",
            },
            { fixed: " " },
            {
              length: 2,
              options: ["am", "pm"],
              regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
              placeholder: "ap",
            },
          ]}
          value={startTime}
          onChange={(event) => {
            setStartTime(event.target.value);
          }}
          onBlur={(event) => {
            console.log(event.target.value);
          }}
        />
      </Box>
      <Box
        direction="row"
        gap="medium"
        align="start"
        justify="start"
        fill="horizontal"
      >
        <Text weight="bold">End Date/Time: </Text>
        <DateInput
          format="mm/dd/yyyy"
          value={endDate}
          onChange={({ value }) => {
            setEndDate(value);
          }}
        />
        <MaskedInput
          mask={[
            {
              length: [1, 2],
              options: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
              ],
              regexp: /^1[1-2]$|^[0-9]$/,
              placeholder: "hh",
            },
            { fixed: ":" },
            {
              length: 2,
              options: secondOptions,
              regexp: /^[0-5][0-9]$|^[0-9]$/,
              placeholder: "mm",
            },
            { fixed: " " },
            {
              length: 2,
              options: ["am", "pm"],
              regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
              placeholder: "ap",
            },
          ]}
          value={endTime}
          onChange={(event) => {
            setEndTime(event.target.value);
          }}
          onBlur={(event) => {
            console.log(event.target.value);
          }}
        />
      </Box>
      <Box
        direction="row"
        gap="medium"
        align="start"
        justify="start"
        fill="horizontal"
      >
        <Select
          options={["green", "yellow", "red"]}
          value={flag}
          onChange={({ option }) => setFlag(option)}
          placeholder="Flag"
          defaultValue={flag}
        />
      </Box>
      <Box
        direction="row"
        gap="medium"
        align="start"
        justify="start"
        fill="horizontal"
      >
        <TextArea
          placeholder="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </Box>
      <Box
        direction="row"
        gap="medium"
        align="start"
        justify="start"
        fill="horizontal"
      >
        <TextInput
          placeholder="Google Meet ID"
          value={googleMeetID}
          onChange={(e) => setGoogleMeetID(e.target.value)}
        />
        <Button
          label="Create Google Room"
          onClick={handleGRoom}
          color="light-2"
          primary
        />
      </Box>
      <Box
        direction="row"
        gap="medium"
        align="start"
        justify="start"
        fill="horizontal"
      >
        <Button primary label="Submit" onClick={handleSubmit} />
      </Box>
    </Box>
  );
}
