import React from "react";
import {
  Box,
  Grid,
  Card,
  Text,
  NameValueList,
  NameValuePair,
  Button,
  Tip,
} from "grommet";
import { Add } from "grommet-icons";

function SessionsLayout({
  queue,
  isQueue,
  sessions,
  claim,
  isSessions,
  openLayer,
  loading,
}) {
  return (
    <Box>
      <Grid
        columns={["small", "auto"]}
        rows={["auto", "auto"]}
        areas={[
          { name: "side", start: [0, 0], end: [0, 1] },
          { name: "main", start: [1, 0], end: [1, 1] },
        ]}
        gap="small"
        fill={true}
      >
        <Box
          align="start"
          justify="start"
          gridArea="side"
          overflow="scroll"
          width="small"
          pad="xsmall"
          background={{ color: "active" }}
          gap="xsmall"
          elevation="small"
          round="small"
          direction="column"
          basis="xlarge"
          margin="xsmall"
        >
          <Card background={{ color: "brand" }} width="small" fill="horizontal">
            <Box align="center" justify="center" direction="row" pad="xsmall">
              <Text weight="bolder">QUEUE</Text>
            </Box>
          </Card>

          {isQueue ? (
            queue.map((item, idx) => (
              <Card
                hoverIndicator={false}
                background={{ color: "brand" }}
                height="small"
                width="small"
                fill="horizontal"
                key={idx}
                overflow="auto"
                justify="center"
              >
                <Box
                  align="center"
                  justify="between"
                  direction="row"
                  pad="xsmall"
                >
                  <NameValueList layout="grid" align="start">
                    <NameValuePair name={item.client_name}>
                      <Text>
                        {item.first_name} {item.last_name}
                      </Text>
                    </NameValuePair>
                  </NameValueList>
                  <Button
                    color="active-text"
                    icon={<Add />}
                    size="small"
                    hoverIndicator={true}
                    tip="Claim Tester"
                    onClick={() =>
                      claim(
                        item.id,
                        item.client_name,
                        item.first_name,
                        item.last_name,
                        item.proctor_history
                      )
                    }
                  />
                </Box>
              </Card>
            ))
          ) : (
            <Box>
              <Text>No Current Queue</Text>
            </Box>
          )}
        </Box>
        {/*
        <Box
          align="center"
          justify="start"
          gridArea="main"
          overflow="auto"
          height="xlarge"
          margin={{ right: "small" }}
          width="100%"
          direction="column"
          gap="xsmall"
          pad="xsmall"
          wrap={true}
        >
          */}
        <Grid
          gridArea="main"
          columns={["auto", "auto", "auto"]}
          rows="xsmall"
          gap="medium"
          pad="xsmall"
        >
          {isSessions &&
            sessions.map((itm, idx) => (
              <Tip content="Click to View" plain={true} key={idx}>
                <Card
                  background={{ color: "active" }}
                  height="xsmall"
                  width="medium"
                  onClick={() => openLayer(itm)}
                >
                  <Box
                    align="center"
                    justify="between"
                    direction="row"
                    pad="xsmall"
                  >
                    <NameValueList layout="grid" align="start">
                      <NameValuePair name={itm.client_name}>
                        <Text>
                          {itm.first_name} {itm.last_name}
                        </Text>
                      </NameValuePair>
                    </NameValueList>
                  </Box>
                </Card>
              </Tip>
            ))}
        </Grid>
        {/*
        </Box>
            */}
      </Grid>
    </Box>
  );
}

export default SessionsLayout;
