import { Box } from "grommet";
import React from "react";
import DBTLogo from "../Components/dbtlogo";

function DBTRegister() {
  return (
    <Box align="center" justify="center" pad="large">
      <DBTLogo />

      <iframe
        id="JotFormIFrame-221305211951039"
        title="dbt | MonitorEDU Exam Registration "
        onLoad={window.parent.scrollTo(0, 0)}
        allowFullScreen={true}
        allow="geolocation; microphone; camera"
        src="https://form.jotform.com/221305211951039"
        frameBorder="0"
        style={{
          minWidth: "100%",
          height: "1200px",
          border: "none",
        }}
        scrolling="yes"
      ></iframe>
    </Box>
  );
}

export default DBTRegister;
