import { API } from "aws-amplify";
import { Box, Notification } from "grommet";
import React, { useEffect, useState } from "react";
import { getCmsScheduleDetails } from "../graphql/queries";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import CmsScheduleCard from "../Components/CmsScheduleCard";
import CmsCancelCard from "../Components/CmsCancelCard";
import CmsScheduleCardTwo from "../Components/CmsScheduleCardTwo";

function CmsScheduling() {
  // url query params include user_id exam_id registration_id and action
  // call the api to get the user by id and exam by id
  // check schedule by date in registration
  // set bounds of calendar and time from the exam

  // if action is schedule then show the calendar and time picker
  // if action is reschedule show the calendar and time picker and the current scheduled time
  // if action is cancel show the current scheduled time and a cancel button
  const [isSchedule, setIsSchedule] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [candidate, setCandidate] = useState({});
  const [exam, setExam] = useState({});
  const [registration, setRegistration] = useState({});
  const [examError, setExamError] = useState(false);
  const [candidateError, setCandidateError] = useState(false);
  const [registrationError, setRegistrationError] = useState(false);
  const [activeError, setActiveError] = useState(false);
  const [scheduleByError, setScheduleByError] = useState(false);
  const [actionError, setActionError] = useState(false);
  const [updatedAction, setUpdatedAction] = useState("");
  // const [windowError, setWindowError] = useState(false);
  const { search } = useLocation();
  const { exam_id, user_id, registration_id, action, window } =
    queryString.parse(search);

  const today = new Date();
  const todayISO = today.toISOString();

  useEffect(() => {
    //query functions

    //actions

    if (action === "schedule" || action === "reschedule") {
      // getCmsScheduleDetails
      cmsScheduleAction(exam_id, user_id, registration_id).then((res) => {
        if (res) {
          setIsSchedule(true);
        }
      });

      // check if appointment already scheduled
      // if so show reschedule
      // if not show schedule
    } else if (action === "cancel") {
      // getCmsCancelDetails
      cmsScheduleAction(exam_id, user_id, registration_id).then((res) => {
        if (res) {
          setIsCancel(true);
        }
      });
    } else {
      setActionError(true);
    }
  }, []);

  async function cmsScheduleAction(exam_id, user_id, registration_id) {
    try {
      const data = await API.graphql({
        query: getCmsScheduleDetails,
        variables: {
          exam_id: exam_id,
          user_id: user_id,
          registration_id: registration_id,
        },
        authMode: "API_KEY",
      });

      if (data.data.getCmsRegistration.appointment !== null) {
        setUpdatedAction("reschedule");
        console.log("reschedule");
      } else {
        setUpdatedAction("schedule");
        console.log("schedule");
      }
      // registration checks
      let response = true;
      if (data.data.getCmsExam !== null) {
        setExam(data.data.getCmsExam);
      } else {
        setExamError(true);
        response = false;
      }
      if (data.data.getCmsCandidate !== null) {
        setCandidate(data.data.getCmsCandidate);
      } else {
        setCandidateError(true);
        response = false;
      }
      if (data.data.getCmsRegistration !== null) {
        setRegistration(data.data.getCmsRegistration);
      } else {
        setRegistrationError(true);
        response = false;
      }
      if (data.data.getCmsRegistration.active === true) {
      } else {
        setActiveError(true);
        response = false;
      }
      if (data.data.getCmsRegistration.schedule_by_date >= todayISO) {
      } else if (data.data.getCmsRegistration.schedule_by_date == null) {
      } else {
        setScheduleByError(true);
        response = false;
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  }
  //actions
  return (
    <Box align="center" justify="center">
      {examError && (
        <Notification
          toast
          title="Error"
          status="critical"
          message="Exam not found"
          onClose={() => setExamError(false)}
        />
      )}
      {candidateError && (
        <Notification
          toast
          title="Error"
          status="critical"
          message="Candidate not found"
          onClose={() => setCandidateError(false)}
        />
      )}
      {registrationError && (
        <Notification
          toast
          title="Error"
          status="critical"
          message="Registration not found"
          onClose={() => setRegistrationError(false)}
        />
      )}
      {activeError && (
        <Notification
          toast
          title="Error"
          status="critical"
          message="Registration is not active"
          onClose={() => setActiveError(false)}
        />
      )}
      {scheduleByError && (
        <Notification
          toast
          title="Error"
          status="critical"
          message="Registration is not active"
          onClose={() => setScheduleByError(false)}
        />
      )}
      {actionError && (
        <Notification
          toast
          title="Error"
          status="critical"
          message="Action not found"
          onClose={() => setActionError(false)}
        />
      )}
      {isSchedule && (
        <CmsScheduleCardTwo
          first={candidate.first_name}
          last={candidate.last_name}
          email={candidate.email_address}
          registration={registration_id}
          start={exam.utc_start_date}
          end={exam.utc_end_date}
          examName={exam.name}
          action={updatedAction}
          window={window}
          appt={
            registration.appointment !== null
              ? registration.appointment.local_date
              : null
          }
        />
      )}
      {isCancel && (
        <CmsCancelCard
          first={candidate.first_name}
          last={candidate.last_name}
          email={candidate.email_address}
          registration={registration_id}
          start={exam.utc_start_date}
          end={exam.utc_end_date}
          examName={exam.name}
          local={registration.appointment.local_date}
        />
      )}
    </Box>
  );
}

export default CmsScheduling;
