import React, { useEffect, useState } from "react";
import OtMobile from "../Components/OtMobile";
import Proctors from "../Global/proctors";
import { getTwilioAccessToken, roomByDate } from "../graphql/queries";
import { API } from "aws-amplify";
import RoomGrid from "../Global/RoomGrid";
import { Box, Button, Heading, Main, Select } from "grommet";
import { useUser } from "../AuthContext";
import VideoProvider from "../testing-twill/src/components/VideoProvider";

function TwilioMobile() {
  const [rooms, setRooms] = useState(null); // Room Values (a1 b2 c3...)
  const [proc, setProc] = useState(null); // Proctor Values (Proctor 001 Proctor 002 ...)
  const [isRooms, setIsRooms] = useState(false);
  const [isProc, setIsProc] = useState(false);
  const [items, setItems] = useState([]);
  const [vids] = useState([
    { label: "Room 1", value: "room1" },
    { label: "Room 2", value: "room2" },
    { label: "Room 3", value: "room3" },
  ]);

  const [twilioRooms, setTwilioRooms] = useState([]);

  const user = useUser();

  useEffect(() => {
    getProctors();
  }, []);

  async function getProctors() {
    try {
      const proctorData = await API.graphql({
        query: roomByDate,
        variables: {
          sortDirection: "ASC",
          type: "Room",
        },
        authMode: "API_KEY",
      });
      const proctors = proctorData.data.RoomByDate.items;
      setItems(proctors);
    } catch (err) {
      console.log(err);
    }
  }

  function handleProcChange(val) {
    const index = items.map((obj) => obj.id).indexOf(val);
    setProc(items[index]);
    setIsProc(true);
    setTwilioRooms([]);
  }

  function handleRoomChange(value) {
    let pp = null;
    switch (value) {
      case "room1":
        pp = Proctors.room1;
        break;
      case "room2":
        pp = Proctors.room2;
        break;
      case "room3":
        pp = Proctors.room3;
        break;
      default:
        break;
    }

    setRooms(pp);
    setTwilioRooms([]);
    setIsRooms(true);
  }

  async function handleAddRoom() {
    let identity = user.user.attributes.email.split("@")[0];
    let r;

    if (twilioRooms.length < 1) {
      r = proc.proctorCode + rooms[0];
    } else if (twilioRooms.length < 15) {
      r = proc.proctorCode + rooms[twilioRooms.length];
    }

    try {
      const token = await API.graphql({
        query: getTwilioAccessToken,
        variables: {
          body: JSON.stringify({
            identity: identity + Math.random().toString(),
            room: r,
          }),
        },
      });

      if (twilioRooms.length < 15) {
        setTwilioRooms([
          ...twilioRooms,
          { room: r, token: token.data.getTwilioAccessToken },
        ]);
      }
      if (twilioRooms.length >= 15) {
        alert("You have reached the maximum number of rooms for this proctor.");
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <Box align="center" justify="center">
        <Heading textAlign="center" size="small">
          Mobile Rooms
        </Heading>

        <Box align="center" justify="start" direction="row">
          <Select
            name="Select Proctor Room"
            placeholder="Select Proctor Room"
            labelKey={"name"}
            valueKey={{ key: "id", reduce: true }}
            options={items}
            onChange={({ value: nextValue }) => handleProcChange(nextValue)}
          />
          {isProc ? (
            <Select
              name="choose room"
              placeholder="Choose Room"
              labelKey={"label"}
              valueKey={{ key: "value", reduce: true }}
              options={vids}
              onChange={({ value: nextValue }) => handleRoomChange(nextValue)}
            />
          ) : (
            <Box></Box>
          )}
          <Box align="center" justify="center">
            {isRooms && (
              <Button label="Add Room" onClick={() => handleAddRoom()} />
            )}
          </Box>
        </Box>
        <Box
          align="center"
          justify="center"
          direction="row"
          gap="small"
          wrap={true}
          fill="horizontal"
        >
          {twilioRooms.length > 0 &&
            twilioRooms.map((itm, idx) => (
              <VideoProvider
                roomId={itm.room}
                token={itm.token}
                key={idx}
                participantType="proctor"
              />
            ))}
        </Box>
      </Box>
    </>
  );
}

export default TwilioMobile;
