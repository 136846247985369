import { Box, Button, Heading, Text } from "grommet";
import React from "react";
import DBTLogo from "../Components/dbtlogo";
import { useNavigate } from "react-router-dom";

function DBT() {
  const navigate = useNavigate();
  return (
    <Box align="center" justify="center">
      <DBTLogo />
      <Heading level="1">How It Works</Heading>
      <Box align="center" justify="center" width="100%" gap="small">
        <Box align="center" justify="center" pad="xxlarge">
          <Heading level="2" textAlign="center">
            Register | Schedule | Pay for an exam with MonitorEDU
          </Heading>
          <Button
            label="Click Here"
            size="large"
            primary
            onClick={() => navigate("/dbt-register")}
          ></Button>
        </Box>
        <Box align="center" justify="center" pad="large" gap="medium">
          <Heading level="2">Scheduling Policies:</Heading>
          <Box justify="start" textAlign="start" gap="medium">
            <Text weight="bold" size="large">
              1. You may schedule up to 48 hours in advance of your desired
              start date / time.
            </Text>
            <Text weight="bold" size="large">
              2. You may cancel/re-schedule anytime before your selected start
              date / time. (All re-scheduling and cancellations must be handled
              by contacting schedule@monitoredu.com, you will receive a response
              within one business day)
            </Text>
            <Text weight="bold" size="large">
              3. Candidates who have passed the exam may not retake the exam.
            </Text>
          </Box>
        </Box>
        <Box align="center" justify="center" pad="large" gap="medium">
          <Heading level="2">When it is time to take your exam:</Heading>
          <Text pad="medium" weight="bold" size="large">
            Connect with a proctor using the link in the scheduling confirmation
            email you received. OR click the button below.
          </Text>
          <Button
            label="Click Here"
            size="large"
            primary
            onClick={() =>
              window.open(
                "https://tawk.to/chat/6245ceb50bfe3f4a8770db70/1fvgauthj",
                "_blank"
              )
            }
          ></Button>
        </Box>
      </Box>
    </Box>
  );
}

export default DBT;
