import React from "react";
import { Box, Button, Heading, Main, Text } from "grommet";

function FAQ() {
  return (
    <>
      <Box align="center" justify="center" pad="medium">
        <Main>
          <Box>
            <Heading level="3">How do I take my test?</Heading>
            <Heading level="4">
              The MonitorEDU Process is very simple - you just need to follow
              the instructions:
            </Heading>
            <Text> - Click on start my test</Text>
            <Text> - Fill out your information</Text>
            <Text> - Get connected to your MEDU agent</Text>
            <Text> - They will send your mobile link</Text>
            <Text>
              {" "}
              - They will send you the second camera link - usually a webcam -
              if needed
            </Text>
            <Text> - and finally they will help launch your test</Text>
            <Button
              primary
              margin="medium"
              label="Click to test connections"
              onClick={() =>
                window.open(
                  "https://tawk.to/chat/5d597714eb1a6b0be608197a/default"
                )
              }
            ></Button>
          </Box>
          <Box>
            <Heading level="3">What else do I need to know?</Heading>
            <Heading level="4">
              It is a pretty simple process - but if you want to get into your
              exam really quick:
            </Heading>
            <Text>
              {" "}
              - Make sure your phone is fully charged and plugged into its
              charger
            </Text>
            <Text> - Make sure your wifi is connected</Text>
            <Text>
              {" "}
              - Make sure your testing area is clean and you only have the
              resources allowed
            </Text>
            <Text>
              {" "}
              - The proctor will have you remove any items that are not allowed
            </Text>
            <Text> - Make sure test area is well lit</Text>
            <Text> - The room should be quiet</Text>
            <Button
              primary
              margin="medium"
              label="How to place your camera"
              onClick={() =>
                window.open("https://www.youtube.com/watch?v=2-CyXVS6Zws")
              }
            />
          </Box>
        </Main>
      </Box>
    </>
  );
}

export default FAQ;
