import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Heading,
  CardBody,
  Text,
  CardFooter,
  Button,
  Box,
  Spinner,
} from "grommet";
import { API } from "aws-amplify";
import {
  sendCmsCancelNotification,
  updateCmsRegistration,
} from "../graphql/mutations";

function CmsCancelCard(props) {
  const [inProgress, setInProgress] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  async function handleSubmit() {
    setLoading(true);
    setInProgress(false);
    try {
      const input = {
        registration_id: props.registration,
        appointment: {
          appointment_id: null,
          exam_date: null,
          local_date: null,
        },
        status: "cancelled",
      };

      const data = await API.graphql({
        query: updateCmsRegistration,
        variables: { input },
        authMode: "API_KEY",
      });

      await API.graphql({
        query: sendCmsCancelNotification,
        variables: {
          body: {
            name: `${props.first} ${props.last}`,
            exam: props.examName,
          },
        },
        authMode: "API_KEY",
      });

      if (data.data.updateCmsRegistration.appointment.appointment_id === null) {
        setSuccess(true);
        setLoading(false);
      } else {
        setInProgress(true);
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <Box align="center" justify="center" pad="medium">
      <Card width="medium" align="center" justify="center" pad="medium">
        {inProgress && (
          <>
            <CardHeader>
              <Heading level="3" margin="none">
                Cancel Appointment
              </Heading>
            </CardHeader>
            <CardBody>
              <Text textAlign="center">
                Are you sure you want to cancel the appointment for{" "}
                {props.first} {props.last}?
              </Text>
              <Text textAlign="center" weight="bold">
                Appointment:
              </Text>
              <Text textAlign="center">{props.local}</Text>
              <Text textAlign="center" weight="bold">
                Exam:
              </Text>
              <Text textAlign="center">{props.examName}</Text>
            </CardBody>
            <CardFooter>
              <Button
                label="Cancel"
                size="large"
                primary
                onClick={handleSubmit}
              />
            </CardFooter>
          </>
        )}
        {loading && (
          <>
            <Box align="center" justify="center">
              <Spinner />
            </Box>
          </>
        )}
        {success && (
          <>
            <Box align="center" justify="center">
              <Text textAlign="center">Appointment Cancelled</Text>
            </Box>
          </>
        )}
      </Card>
    </Box>
  );
}

export default CmsCancelCard;
