import {
  Box,
  TextInput,
  Form,
  FormField,
  Button,
  Heading,
  Calendar,
  NameValueList,
  NameValuePair,
  Text,
  ResponsiveContext,
} from "grommet";
import React, { useContext, useEffect, useState } from "react";
import { API } from "aws-amplify";
import { getAOAName } from "../graphql/queries";

// set time range from 12:00am to 11:30pm in 30 minute increments
const times = [
  "12:00am",
  "12:30am",
  "1:00am",
  "1:30am",
  "2:00am",
  "2:30am",
  "3:00am",
  "3:30am",
  "4:00am",
  "4:30am",
  "5:00am",
  "5:30am",
  "6:00am",
  "6:30am",
  "7:00am",
  "7:30am",
  "8:00am",
  "8:30am",
  "9:00am",
  "9:30am",
  "10:00am",
  "10:30am",
  "11:00am",
  "11:30am",
  "12:00pm",
  "12:30pm",
  "1:00pm",
  "1:30pm",
  "2:00pm",
  "2:30pm",
  "3:00pm",
  "3:30pm",
  "4:00pm",
  "4:30pm",
  "5:00pm",
  "5:30pm",
  "6:00pm",
  "6:30pm",
  "7:00pm",
  "7:30pm",
  "8:00pm",
  "8:30pm",
  "9:00pm",
  "9:30pm",
  "10:00pm",
  "10:30pm",
  "11:00pm",
  "11:30pm",
];

function AOAScheduling() {
  const [step, setStep] = useState("step1");
  return (
    <Box
      align="center"
      justify="start"
      gap="medium"
      fill
      background="light-2"
      height={{ min: "100vh" }}
    >
      <Box
        align="center"
        justify="start"
        gap="medium"
        background="white"
        round="small"
        width="xlarge"
        height={{ min: "100vh" }}
      >
        <Heading level="1" margin="small" textAlign="center">
          AOA Exam Scheduling
        </Heading>

        <AOASchedulingSteps step={step} setStep={setStep} />
      </Box>
    </Box>
  );
}

export default AOAScheduling;

function AOASchedulingSteps({ step, setStep }) {
  const [examItems, setExamItems] = useState([]);
  const [examAttempt, setExamAttempt] = useState("");

  const handleStep1Submit = async (value) => {
    try {
      const data = await API.graphql({
        query: getAOAName,
        variables: {
          action: "getNameOfFirstExam",
          payload: JSON.stringify({
            AOAID: value.aoaID,
          }),
        },
        authMode: "API_KEY",
      });

      const res = JSON.parse(data.data.getAOAExamSetup);
      const tempExams = res.data.aoaByAOAID.items.filter(
        (item) => item.Status !== "cancelled" || item.Status !== "canceled"
      );
      if (tempExams.length === 0) {
        alert("No exams found for this AOA ID, or AOA ID is invalid.");
      } else if (tempExams.length === 1) {
        setExamAttempt(res.data.aoaByAOAID.items[0]);
        if (
          res.data.aoaByAOAID.items[0].Status === "scheduled" ||
          res.data.aoaByAOAID.items[0].Status === "rescheduled"
        ) {
          setStep("step4");
        } else {
          setStep("step3");
        }
      } else {
        // filter out items by status = "cancelled" or "canceled"
        setExamItems(tempExams);
        setStep("step2");
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (step === "step1") {
    return (
      <Box align="center" justify="center" pad="small" gap="medium">
        <Form
          onSubmit={({ value }) => {
            handleStep1Submit(value);
          }}
        >
          <FormField
            name="aoaID"
            label="Please enter your AOA ID"
            htmlFor="aoa-id-input"
          >
            <TextInput
              name="aoaID"
              a11yTitle="Please enter your AOA ID"
              placeholder="AOA ID"
              id="aoa-id-input"
              style={{ border: "1px solid black" }}
              size="large"
            />
          </FormField>
          <Button label="Verify" type="submit" primary size="large" />
        </Form>
      </Box>
    );
  }

  if (step === "step2") {
    function handleExamSelection(exam) {
      setExamAttempt(exam);
      if (exam.Status === "scheduled" || exam.Status === "rescheduled") {
        setStep("step4");
      } else {
        setStep("step3");
      }
    }
    return (
      <Box align="center" justify="center" pad="small" gap="medium">
        <Heading level="3" textAlign="center">
          Select the exam you would like to schedule
        </Heading>

        {examItems.map((item, idx) => (
          <Button
            key={idx}
            label={item.ExamType}
            onClick={() => {
              handleExamSelection(item);
            }}
            size="large"
            primary
          />
        ))}
      </Box>
    );
  }

  if (step === "step3") {
    return (
      <Box
        align="center"
        justify="center"
        pad="small"
        textAlign="center"
        gap="small"
      >
        <NameValueList pairProps={{ direction: "column" }}>
          <NameValuePair
            name="Name"
            value={examAttempt.FirstName + " " + examAttempt.LastName}
          >
            <Text>
              {examAttempt.FirstName} {examAttempt.LastName}
            </Text>
          </NameValuePair>
          <NameValuePair name="Email" value={examAttempt.Email}>
            <Text>{examAttempt.Email}</Text>
          </NameValuePair>
          <NameValuePair name="AOAID" value={examAttempt.AOAID}>
            <Text>{examAttempt.AOAID}</Text>
          </NameValuePair>
          <NameValuePair name="Exam" value={examAttempt.ExamType}>
            <Text>{examAttempt.ExamType}</Text>
          </NameValuePair>
        </NameValueList>
        <DateTimeSelector
          attempt={examAttempt}
          setExamAttempt={setExamAttempt}
          setStep={setStep}
        />
      </Box>
    );
  }

  if (step === "step4") {
    return (
      <Box align="center" justify="center" pad="small" gap="medium">
        <Heading level="4" textAlign="center">
          Exam: {examAttempt.ExamType} has been scheduled for{" "}
          {new Date(examAttempt.ScheduledDate).toLocaleString()}
        </Heading>
        <Heading level="4" textAlign="center">
          Save this information for your records.
        </Heading>
        <Heading level="5" textAlign="center">
          If you would like to reschedule your exam click below.
        </Heading>
        <Button
          label="Reschedule"
          onClick={() => setStep("step3")}
          primary
          size="large"
        />
      </Box>
    );
  }
}

function DateTimeSelector({ attempt, setExamAttempt, setStep }) {
  const [tz, setTz] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [dateRangeLower, setDateRangeLower] = useState([]);
  const [dateRangeUpper, setDateRangeUpper] = useState([]);
  const [timeRange, setTimeRange] = useState(times);
  const [selectedDate, setSelectedDate] = useState(
    new Date(attempt.StartDate).getTime() < new Date().getTime()
      ? new Date().toISOString().split("T")[0]
      : new Date(attempt.StartDate).toISOString().split("T")[0]
  );
  const [selectedTime, setSelectedTime] = useState("");
  const size = useContext(ResponsiveContext);

  useEffect(() => {
    console.log(size);
    // get the day before attempt.StartDate and the day after attempt.EndDate
    const start = new Date(attempt.StartDate);
    const end = new Date(attempt.EndDate);
    let dayBefore = new Date(start.setDate(start.getDate() - 1));

    // check if dayBefore is before today
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    dayBefore = dayBefore < today ? today : dayBefore;

    const dayBeforeISO = dayBefore.toISOString().split("T")[0];
    const dayAfter = new Date(end.setDate(end.getDate() + 1));

    const dayAfterISO = dayAfter.toISOString().split("T")[0];
    // set dateRangeLower to [one year before dayBefore, dayBefore] converted to ISO strings
    setDateRangeLower([
      new Date(dayBefore.setFullYear(dayBefore.getFullYear() - 1))
        .toISOString()
        .split("T")[0],
      dayBeforeISO,
    ]);
    // set dateRangeUpper to [dayAfter, one year after dayAfter] converted to ISO strings
    setDateRangeUpper([
      dayAfterISO,
      new Date(dayAfter.setFullYear(dayAfter.getFullYear() + 1))
        .toISOString()
        .split("T")[0],
    ]);

    // set selectedDate to today if it is set before today

    console.log(dateRangeLower);
    console.log(dateRangeUpper);
  }, [attempt]);

  useEffect(() => {
    // get the time zone of the user
    setTz(Intl.DateTimeFormat().resolvedOptions().timeZone);

    setIsLoaded(true);
  }, []);

  useEffect(() => {
    // trim down the timeRange when selectedDate is the same as attempt.StartDate or attempt.EndDate
    const startDateString = new Date(attempt.StartDate)
      .toISOString()
      .split("T")[0];
    const endDateString = new Date(attempt.EndDate).toISOString().split("T")[0];
    // first find the offset of the current time zone from UTC
    const offset = new Date().getTimezoneOffset();

    // if selectedDate is equal to startDateString, trim down the beginnnig of the timeRange by offset minutes
    if (selectedDate === startDateString) {
      const newTimeRange = times.slice(offset / 30);
      setTimeRange(newTimeRange);
    }
    // if selectedDate is equal to endDateString, trim down the end of the timeRange by offset minutes
    else if (selectedDate === endDateString) {
      const newTimeRange = times.slice(0, timeRange.length - offset / 30);
      setTimeRange(newTimeRange);
    }
    // otherwise, set timeRange back to the full times array
    else {
      setTimeRange(times);
    }
  }, [selectedDate]);

  function handleDateChange(val) {
    // check if val is not before today
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    const selected = new Date(val);
    selected.setHours(0);
    selected.setMinutes(0);
    selected.setSeconds(0);
    selected.setMilliseconds(0);
    if (selected < today) {
      alert("Please select a date that is not before today.");
      return;
    }

    setSelectedDate(val);
  }

  async function handleSchedulingSubmit() {
    // convert selected date and time to UTC
    console.log(selectedTime);
    if (selectedTime === "") {
      alert("Please select a time");
      return;
    }
    const date = new Date();
    date.setFullYear(selectedDate.split("-")[0]);
    date.setMonth(selectedDate.split("-")[1] - 1);
    date.setDate(selectedDate.split("-")[2]);
    const time = selectedTime.split(":");
    const hour = time[0];
    const minute = time[1].slice(0, 2);
    const ampm = time[1].slice(2);
    if (hour === "12" && ampm === "am") {
      date.setHours(0);
    } else if (hour === "12" && ampm === "pm") {
      date.setHours(12);
    } else {
      date.setHours(ampm === "pm" ? parseInt(hour) + 12 : hour);
    }

    date.setMinutes(minute);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const utcDate = date.toISOString();

    try {
      const data = await API.graphql({
        query: getAOAName,
        variables: {
          action: "updateExam",
          payload: JSON.stringify({
            input: {
              ExamAttempt: attempt.ExamAttempt,
              ScheduledDate: utcDate,
              Status:
                attempt.Status === "eligible" ? "scheduled" : "rescheduled",
            },
            day: selectedDate,
            time: selectedTime,
          }),
        },
        authMode: "API_KEY",
      });

      const res = JSON.parse(data.data.getAOAExamSetup);

      setExamAttempt(res.data.updateAOAExam);
      setStep("step4");
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Box align="center" justify="center" gap="medium">
      <Text weight="bold">Select a date and time for your exam.</Text>
      <Text weight="bold">
        All times are in {tz}. {size}
      </Text>
      <Box
        border={
          size !== "small"
            ? { width: "1px", color: "black" }
            : { width: "0px", color: "white" }
        }
      >
        <Calendar
          disabled={[dateRangeLower, dateRangeUpper]}
          date={selectedDate}
          onSelect={(date) => handleDateChange(date)}
        />
      </Box>
      <select
        value={selectedTime}
        onChange={(e) => setSelectedTime(e.target.value)}
        style={{ width: "100%", height: "50px", fontSize: "1.5rem" }}
        label="Select a time"
      >
        <option value="" disabled>
          Select a time
        </option>
        {timeRange.map((time, idx) => (
          <option key={idx} value={time}>
            {time}
          </option>
        ))}
      </select>
      <Button
        label="Submit"
        primary
        size="large"
        onClick={() => handleSchedulingSubmit()}
      />
      <br />
    </Box>
  );
}
