import React, { useState } from "react";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormField,
  TextInput,
  CheckBox,
  Heading,
  Button,
  Notification,
} from "grommet";
import { API } from "aws-amplify";
import { createClient } from "../graphql/mutations";

function AddClient() {
  const [preChatData, setPreChatData] = useState({
    email: false,
    phone: false,
    candidate_id: false,
    exam: false,
    instructor: false,
    school: false,
    lesson_id: false,
    appointment: false,
    receipt_number: false,
  });
  const [noteData, setNoteData] = useState({
    name: true,
    email: false,
    phone: false,
    candidate_id: false,
    school: false,
    instructor: false,
    lesson_id: false,
    appointment: false,
    receipt_number: false,
    notes: true,
    start_time: true,
    end_time: true,
    google_meet_link: false,
    score: false,
    pass: false,
    exam_password: false,
    status: true,
    flag: true,
  });
  const [clientName, setClientName] = useState("");
  const [tawkLink, setTawkLink] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const createPreChatInput = () => {
    let output = [
      {
        component: "name",
      },
    ];
    for (let key in preChatData) {
      if (preChatData[key]) {
        output.push({
          component: key,
        });
      }
    }

    return output;
  };

  const createNoteInput = () => {
    let output = [];
    for (let key in noteData) {
      if (noteData[key]) {
        output.push({
          component: key,
        });
      }
    }

    return output;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const notes = createNoteInput();
    const preChat = createPreChatInput();

    try {
      const data = await API.graphql({
        query: createClient,
        variables: {
          input: {
            name: clientName,
            tawk_property_link: tawkLink,
            note_format: JSON.stringify(notes),
            pre_chat_form: JSON.stringify(preChat),
          },
        },
      });

      if (data.data.createClient.id) {
        setSuccess(true);
        setClientName("");
        setTawkLink("");
        setPreChatData({
          email: false,
          phone: false,
          candidate_id: false,
          exam: false,
          instructor: false,
          school: false,
          lesson_id: false,
          appointment: false,
          receipt_number: false,
        });
        setNoteData({
          first_name: true,
          last_name: true,
          email: false,
          phone: false,
          exam: false,
          candidate_id: false,
          school: false,
          instructor: false,
          lesson_id: false,
          appointment: false,
          receipt_number: false,
          notes: true,
          start_time: true,
          end_time: true,
          google_meet_link: false,
          score: false,
          pass: false,
          exam_password: false,
          status: true,
          flag: true,
        });
      } else {
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  return (
    <Box align="center" justify="center">
      {success && (
        <Notification
          toast
          title="Success"
          message="Client Created"
          status="normal"
          onClose={() => setSuccess(false)}
        />
      )}
      {error && (
        <Notification
          toast
          title="Error"
          message="Client Not Created"
          status="critical"
          onClose={() => setError(false)}
        />
      )}
      <Card background={{ color: "background-front" }} overflow="auto">
        <CardHeader
          align="center"
          direction="row"
          flex={false}
          justify="between"
          gap="medium"
          pad="small"
        />
        <CardBody pad="small" overflow="auto" direction="column">
          <Box
            align="stretch"
            justify="center"
            gap="small"
            overflow="scroll"
            direction="column"
          >
            <Form
              onSubmit={(event) => {
                handleSubmit(event);
              }}
            >
              <Box align="center" justify="center" direction="column">
                <Box
                  align="center"
                  justify="start"
                  direction="row"
                  margin="xsmall"
                >
                  <FormField label="Client Name">
                    <TextInput
                      name="name"
                      placeholder="Client Name"
                      onChange={(e) => setClientName(e.target.value)}
                    />
                  </FormField>
                  <Box
                    align="center"
                    justify="start"
                    direction="row"
                    margin="xsmall"
                  >
                    <FormField label="Tawk Property Link">
                      <TextInput
                        name="tawk_property_link"
                        placeholder="Tawk Link"
                        onChange={(e) => setTawkLink(e.target.value)}
                      />
                    </FormField>
                  </Box>
                </Box>
                <Box
                  align="start"
                  justify="between"
                  direction="row"
                  gap="xsmall"
                  pad="xsmall"
                >
                  <Box align="center" justify="start">
                    <Heading level="4" margin="xsmall" textAlign="center">
                      Pre-Chat-Form Select All That Apply
                    </Heading>
                    <Box
                      align="start"
                      justify="start"
                      gap="xsmall"
                      pad="xsmall"
                    >
                      <FormField>
                        <CheckBox label="Name" checked disabled />
                      </FormField>
                      <FormField>
                        <CheckBox
                          label="Email"
                          checked={preChatData.email}
                          name="email"
                          onChange={(event) => {
                            setPreChatData({
                              ...preChatData,
                              email: event.target.checked,
                            });
                            setNoteData({
                              ...noteData,
                              email: event.target.checked,
                            });
                          }}
                        />
                      </FormField>
                      <FormField>
                        <CheckBox
                          label="Phone"
                          onChange={(event) => {
                            setPreChatData({
                              ...preChatData,
                              phone: event.target.checked,
                            });
                            setNoteData({
                              ...noteData,
                              phone: event.target.checked,
                            });
                          }}
                          checked={preChatData.phone}
                          name="phone"
                        />
                      </FormField>
                      <FormField>
                        <CheckBox
                          label="Candidate ID"
                          onChange={(event) => {
                            setPreChatData({
                              ...preChatData,
                              candidate_id: event.target.checked,
                            });
                            setNoteData({
                              ...noteData,
                              candidate_id: event.target.checked,
                            });
                          }}
                          checked={preChatData.candidate_id}
                          name="candidate_id"
                        />
                      </FormField>
                      <FormField>
                        <CheckBox
                          label="Exam"
                          checked={preChatData.exam}
                          onChange={(event) => {
                            setPreChatData({
                              ...preChatData,
                              exam: event.target.checked,
                            });
                            setNoteData({
                              ...noteData,
                              exam: event.target.checked,
                            });
                          }}
                          name="exam"
                        />
                      </FormField>
                      <FormField>
                        <CheckBox
                          label="Instructor"
                          onChange={(event) => {
                            setPreChatData({
                              ...preChatData,
                              instructor: event.target.checked,
                            });
                            setNoteData({
                              ...noteData,
                              instructor: event.target.checked,
                            });
                          }}
                          checked={preChatData.instructor}
                          name="instructor"
                        />
                      </FormField>
                      <FormField>
                        <CheckBox
                          label="School"
                          name="school"
                          onChange={(event) => {
                            setPreChatData({
                              ...preChatData,
                              school: event.target.checked,
                            });
                            setNoteData({
                              ...noteData,
                              school: event.target.checked,
                            });
                          }}
                          checked={preChatData.school}
                        />
                      </FormField>
                      <FormField>
                        <CheckBox
                          label="Lesson Id"
                          checked={preChatData.lesson_id}
                          name="lesson_id"
                          onChange={(event) => {
                            setPreChatData({
                              ...preChatData,
                              lesson_id: event.target.checked,
                            });
                            setNoteData({
                              ...noteData,
                              lesson_id: event.target.checked,
                            });
                          }}
                        />
                      </FormField>
                      <FormField>
                        <CheckBox
                          label="Appointment"
                          name="appointment"
                          onChange={(event) => {
                            setPreChatData({
                              ...preChatData,
                              appointment: event.target.checked,
                            });
                            setNoteData({
                              ...noteData,
                              appointment: event.target.checked,
                            });
                          }}
                          checked={preChatData.appointment}
                        />
                      </FormField>
                      <FormField>
                        <CheckBox
                          label="Receipt #"
                          onChange={(event) => {
                            setPreChatData({
                              ...preChatData,
                              receipt_number: event.target.checked,
                            });
                            setNoteData({
                              ...noteData,
                              receipt_number: event.target.checked,
                            });
                          }}
                          checked={preChatData.receipt_number}
                          name="receipt_number"
                        />
                      </FormField>
                    </Box>
                  </Box>
                  <Box align="center" justify="start">
                    <Heading level="4" margin="xsmall" textAlign="center">
                      Note Format Select All That Apply (prechat fields, notes,
                      times, flags, etc are included)
                    </Heading>
                    <Box
                      align="start"
                      justify="start"
                      gap="xsmall"
                      pad="xsmall"
                    >
                      <FormField>
                        <CheckBox
                          label="Google Meet Link"
                          checked={noteData.google_meet_link}
                          name="google_meet_link"
                          onChange={(event) => {
                            setNoteData({
                              ...noteData,
                              google_meet_link: event.target.checked,
                            });
                          }}
                        />
                      </FormField>
                      <FormField>
                        <CheckBox
                          label="Score"
                          checked={noteData.score}
                          name="score"
                          onChange={(event) => {
                            setNoteData({
                              ...noteData,
                              score: event.target.checked,
                            });
                          }}
                        />
                      </FormField>
                      <FormField>
                        <CheckBox
                          label="Pass / Fail"
                          checked={noteData.pass}
                          name="pass"
                          onChange={(event) => {
                            setNoteData({
                              ...noteData,
                              pass: event.target.checked,
                            });
                          }}
                        />
                      </FormField>
                      <FormField>
                        <CheckBox
                          label="Exam Password"
                          checked={noteData.exam_password}
                          name="exam_password"
                          onChange={(event) => {
                            setNoteData({
                              ...noteData,
                              exam_password: event.target.checked,
                            });
                          }}
                        />
                      </FormField>
                    </Box>
                  </Box>
                </Box>
                <Button label="Submit" margin="xsmall" type="submit" />
              </Box>
            </Form>
          </Box>
        </CardBody>
      </Card>
    </Box>
  );
}

export default AddClient;
