/*

import React, { useEffect, useState } from "react";
import { roomByDate, getTwilioAccessToken } from "../graphql/queries";
import { API } from "aws-amplify";
import { Box, Button, Select } from "grommet";
import ProctorVideoCard from "../Twillio/components/ProctorVideoCard";
import { useUser } from "../AuthContext";
//import VideoProvider from "../Twillio/components/VideoProvider";
import VideoProvider from "../testing-twill/src/components/VideoProvider";
import Room from "../testing-twill/src/components/Room";

const codes = [
  "a1",
  "b2",
  "c3",
  "d4",
  "e5",
  "f6",
  "g7",
  "h8",
  "i9",
  "j10",
  "k11",
  "l12",
  "m13",
  "n14",
  "o15",
  "p16",
  "q17",
  "r18",
  "s19",
  "t20",
];

function Mobile() {
  const [rooms, setRooms] = useState([]);
  const [items, setItems] = useState([]);
  const [proctor, setProctor] = useState(null);

  const user = useUser();

  useEffect(() => {
    async function getProctors() {
      try {
        const proctorData = await API.graphql({
          query: roomByDate,
          variables: {
            sortDirection: "ASC",
            type: "Room",
          },
          authMode: "API_KEY",
        });
        const proctors = proctorData.data.RoomByDate.items;
        setItems(proctors);
      } catch (err) {
        console.log(err);
      }
    }
    getProctors();
  }, []);

  const handleAddRoom = async () => {
    let identity = user.user.attributes.email.split("@")[0];
    let room;
    if (rooms.length < 1) {
      room = proctor + codes[0];
    } else {
      room = proctor + codes[rooms.length];
    }

    try {
      const token = await API.graphql({
        query: getTwilioAccessToken,
        variables: {
          body: JSON.stringify({
            identity: identity + Math.random().toString(),
            room: room,
          }),
        },
      });

      setRooms([
        ...rooms,
        { room: room, token: token.data.getTwilioAccessToken },
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      align="center"
      justify="center"
      direction="column"
      pad="medium"
      gap="small"
      fill
    >
      <Box
        align="center"
        justify="center"
        direction="row"
        pad="small"
        gap="small"
      >
        <Select
          name="Select Proctor Room"
          placeholder="Select Proctor Room"
          labelKey={"name"}
          valueKey={{ key: "proctorCode", reduce: true }}
          options={items}
          onChange={({ value: nextValue }) => setProctor(nextValue)}
        />
        {proctor === null ? (
          <Box></Box>
        ) : (
          <Button label="Add Room" onClick={() => handleAddRoom()} />
        )}
      </Box>
      <Box
        align="center"
        justify="center"
        direction="row"
        gap="small"
        wrap={true}
        fill="horizontal"
      >
        {rooms.length > 0 &&
          rooms.map((itm, idx) => (
            <VideoProvider roomId={itm.room} token={itm.token} key={idx} />
          ))}
      </Box>
    </Box>
  );
}

export default Mobile;
*/

import React, { useEffect, useState } from "react";
import OtMobile from "../Components/OtMobile";
import Proctors from "../Global/proctors";
import { roomByDate } from "../graphql/queries";
import { API } from "aws-amplify";
import RoomGrid from "../Global/RoomGrid";
import { Box, Heading, Main, Select } from "grommet";

function Mobile() {
  const [rooms, setRooms] = useState(null); // Room Values (a1 b2 c3...)
  const [proc, setProc] = useState(null); // Proctor Values (Proctor 001 Proctor 002 ...)
  const [isRooms, setIsRooms] = useState(false);
  const [isProc, setIsProc] = useState(false);
  const [items, setItems] = useState([]);
  const [vids] = useState([
    { label: "Room 1", value: "room1" },
    { label: "Room 2", value: "room2" },
    { label: "Room 3", value: "room3" },
  ]);

  useEffect(() => {
    getProctors();
  }, []);

  async function getProctors() {
    try {
      const proctorData = await API.graphql({
        query: roomByDate,
        variables: {
          sortDirection: "ASC",
          type: "Room",
        },
        authMode: "API_KEY",
      });
      const proctors = proctorData.data.RoomByDate.items;
      setItems(proctors);
    } catch (err) {
      console.log(err);
    }
  }

  function handleProcChange(val) {
    const index = items.map((obj) => obj.id).indexOf(val);
    setProc(items[index]);
    setIsProc(true);
  }

  function handleRoomChange(value) {
    let pp = null;
    switch (value) {
      case "room1":
        pp = Proctors.room1;
        break;
      case "room2":
        pp = Proctors.room2;
        break;
      case "room3":
        pp = Proctors.room3;
        break;
      default:
        break;
    }

    setRooms(pp);
    setIsRooms(true);
  }

  return (
    <>
      <Box align="center" justify="center">
        <Heading textAlign="center" size="small">
          Mobile Rooms
        </Heading>
        <Box align="center" justify="center">
          {isRooms ? (
            <RoomGrid id={proc.proctorCode} rooms={rooms} />
          ) : (
            <Box></Box>
          )}
        </Box>
        <Box align="center" justify="start" direction="row">
          <Select
            name="Select Proctor Room"
            placeholder="Select Proctor Room"
            labelKey={"name"}
            valueKey={{ key: "id", reduce: true }}
            options={items}
            onChange={({ value: nextValue }) => handleProcChange(nextValue)}
          />
          {isProc ? (
            <Select
              name="choose room"
              placeholder="Choose Room"
              labelKey={"label"}
              valueKey={{ key: "value", reduce: true }}
              options={vids}
              onChange={({ value: nextValue }) => handleRoomChange(nextValue)}
            />
          ) : (
            <Box></Box>
          )}
        </Box>
        <Main
          fill="vertical"
          flex="grow"
          overflow="auto"
          align="center"
          responsive={true}
        >
          {isRooms ? (
            <OtMobile
              rooms={rooms}
              embedCode={proc.embedCode}
              proc={proc.proctorCode}
            />
          ) : (
            <Box></Box>
          )}
        </Main>
      </Box>
    </>
  );
}

export default Mobile;
