import { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { Box, Heading, Text } from "grommet";

import { v4 as uuidv4 } from "uuid";
import { API } from "aws-amplify";
import { getTwilioAccessToken } from "../graphql/queries";
import VideoProvider from "../testing-twill/src/components/VideoProvider";

export default function TwilioSC() {
  const [isRoom, setIsRoom] = useState(false);
  const [token, setToken] = useState(null);
  const { search } = useLocation();
  const { embed, roomid } = queryString.parse(search);

  useEffect(() => {
    if (roomid != null && embed != null) {
      grabRoom(roomid);
      setIsRoom(true);
    }
  }, [roomid, embed]);

  async function grabRoom(rid) {
    try {
      const token = await API.graphql({
        query: getTwilioAccessToken,
        variables: {
          body: JSON.stringify({
            identity: uuidv4(),
            room: rid,
          }),
        },
        authMode: "API_KEY",
      });
      setToken(token.data.getTwilioAccessToken);
    } catch (err) {
      console.log(err);
      alert("Invalid Room ID" + err);
    }
  }

  return (
    <>
      <Box align="center" pad="small" gap="small" fill>
        <Heading level="3" margin="none">
          Webcam Page
        </Heading>
        <Box fill="vertical">
          {isRoom ? (
            <VideoProvider token={token} roomId={roomid} />
          ) : (
            <Text>Room Code Invalid</Text>
          )}
        </Box>
      </Box>
    </>
  );
}
