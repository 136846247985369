import React, { useEffect, useState } from "react";
import { listAAAFoodHandlerSessions } from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { Box, Button, Text, TextInput, Notification } from "grommet";
import {
  UpdateAAAFoodHandlerSession,
  grantAAAFoodHandlerAccess,
  revokeAAAFoodHandlerAccess,
} from "../graphql/mutations";

function AAAFoodHandlers() {
  const [sessions, setSessions] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [queue, setQueue] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const keys = ["Name", "EmailAddress"];
  useEffect(() => {
    // call get sessions, if there is a nextToken, call it again with the nextToken
    let temp = [];
    async function fetchData(token) {
      const data = await getSessions(token);

      temp = [...temp, ...data.items];
      if (data.nextToken) {
        fetchData(data.nextToken);
      } else {
        setSessions(temp);
        let filtered = temp.filter((session) => {
          return session.Status === "QUEUE" || session.Status === null;
        });
        setQueue(filtered);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    setSearchResults([]);
    const debouncedSearchTerm = debounce(search, 1000);
    debouncedSearchTerm(searchTerm);
  }, [searchTerm]);

  function debounce(func, delay) {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  }

  function search(query) {
    if (query.length > 0) {
      const results = sessions.filter((session) => {
        return keys.some((key) => {
          return (
            session[key] !== null &&
            typeof session[key] === "string" &&
            session[key].toString().toLowerCase().includes(query.toLowerCase())
          );
        });
      });
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }

  async function getSessions(token) {
    const data = await API.graphql({
      query: listAAAFoodHandlerSessions,
      variables: token ? { nextToken: token, limit: 300 } : { limit: 300 },
    });

    return data.data.listAAAFoodHandlerSessions;
  }

  return (
    <Box
      align="center"
      justify="center"
      fill="horizontal"
      pad="small"
      gap="medium"
    >
      <Box elevation="medium" round fill align="center" justify="center">
        <Box
          align="center"
          justify="center"
          width="medium"
          pad="medium"
          gap="medium"
        >
          <Text size="large" weight="bold">
            AAA Food Handlers
          </Text>
          <Text size="large">
            CLICK FINISH EXAM WHEN COMPLETED TO LOCK AND REMOVE FROM QUEUE
          </Text>
          <Text size="small">
            Search for a session by name or email address, includes completed
            sessions
          </Text>
          <TextInput
            label="Search By Email"
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search"
          />
        </Box>
      </Box>
      {searchResults.length > 0 ? (
        <Box fill gap="medium">
          {searchResults.map((session) => (
            <Box
              elevation="medium"
              round
              fill
              align="center"
              justify="center"
              key={session.SessionId}
            >
              <SessionCard session={session} />
            </Box>
          ))}
        </Box>
      ) : (
        <Box fill gap="medium">
          <Text size="large" weight="bold" textAlign="center">
            QUEUE
          </Text>
          {queue.map((session) => (
            <Box
              elevation="medium"
              round
              fill
              align="center"
              justify="center"
              key={session.SessionId}
            >
              <SessionCard session={session} />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default AAAFoodHandlers;

function SessionCard({ session }) {
  const [unlockSuccess, setUnlockSuccess] = useState(false);
  const [lockSuccess, setLockSuccess] = useState(false);
  const [finishSuccess, setFinishSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (session.ExamStatus === "LOCKED" || session.ExamStatus === null) {
      setLockSuccess(true);
    }
    if (session.ExamStatus === "UNLOCKED") {
      setUnlockSuccess(true);
    }
    if (session.Status === "COMPLETED") {
      setFinishSuccess(true);
    }
  }, []);
  async function lockExam() {
    setUnlockSuccess(false);
    try {
      const data = await API.graphql(
        graphqlOperation(revokeAAAFoodHandlerAccess, {
          body: {
            user_id: session.StudentId,
            course_id: session.ExamId,
            token: "578f877f-205f-47c6-85e0-afe16172237c",
          },
        })
      );

      await API.graphql({
        query: UpdateAAAFoodHandlerSession,
        variables: {
          input: {
            SessionId: session.SessionId,
            ExamStatus: "LOCKED",
          },
        },
      });

      if (
        data.data.revokeFoodHandlerAccess ===
        "{message=Student access has been revoked}"
      ) {
        setLockSuccess(true);
      } else {
        setMessage(data.data.revokeFoodHandlerAccess);
        setError(true);
        setLockSuccess(false);
        setUnlockSuccess(false);
      }
    } catch (error) {
      console.log(error);
      setError(true);
      setLockSuccess(false);
      setUnlockSuccess(false);
    }
  }
  async function unlockExam() {
    setLockSuccess(false);
    try {
      const data = await API.graphql(
        graphqlOperation(grantAAAFoodHandlerAccess, {
          body: {
            user_id: session.StudentId,
            course_id: session.ExamId,
            token: "578f877f-205f-47c6-85e0-afe16172237c",
          },
        })
      );

      await API.graphql({
        query: UpdateAAAFoodHandlerSession,
        variables: {
          input: {
            SessionId: session.SessionId,
            ExamStatus: "UNLOCKED",
          },
        },
      });
      console.log(data);
      if (
        data.data.grantFoodHandlerAccess ===
        "{message=Student access has been granted successfully}"
      ) {
        setUnlockSuccess(true);
      } else {
        setMessage(data.data.grantFoodHandlerAccess);
        setError(true);
        setLockSuccess(false);
        setUnlockSuccess(false);
      }
    } catch (error) {
      console.log(error);
      setError(true);
      setLockSuccess(false);
      setUnlockSuccess(false);
    }
  }

  async function finishExam() {
    setUnlockSuccess(false);
    try {
      const data = await API.graphql(
        graphqlOperation(revokeAAAFoodHandlerAccess, {
          body: {
            user_id: session.StudentId,
            course_id: session.ExamId,
            token: "578f877f-205f-47c6-85e0-afe16172237c",
          },
        })
      );

      await API.graphql({
        query: UpdateAAAFoodHandlerSession,
        variables: {
          input: {
            SessionId: session.SessionId,
            ExamStatus: "LOCKED",
            Status: "COMPLETED",
          },
        },
      });

      if (
        data.data.revokeFoodHandlerAccess ===
        "{message=Student access has been revoked}"
      ) {
        setLockSuccess(true);
      } else {
        setMessage(data.data.revokeFoodHandlerAccess);
        setError(true);
        setLockSuccess(false);
        setUnlockSuccess(false);
      }
    } catch (error) {
      console.log(error);
      setError(true);
      setLockSuccess(false);
      setUnlockSuccess(false);
    }
  }

  return (
    <Box align="center" justify="center">
      <Box pad="medium" gap="medium" direction="row">
        {unlockSuccess && (
          <Notification title="Exam Unlocked" status="normal" />
        )}
        {lockSuccess && <Notification title="Exam Locked" status="critical" />}
        {error && (
          <Notification
            title="Something may have gone wrong. Please try again."
            message={message}
            status="critical"
            onClose={() => setError(false)}
          />
        )}
        {finishSuccess && (
          <Notification title="Exam Completed" status="normal" />
        )}

        <Box direction="column" gap="small">
          <Box direction="row" gap="small">
            <Text weight="bold">Tester Name:</Text>
            <Text>{session.Name}</Text>
          </Box>
          <Box direction="row" gap="small">
            <Text weight="bold">Email:</Text>
            <Text>{session.EmailAddress}</Text>
          </Box>
        </Box>
        <Box direction="column" gap="small">
          <Box direction="row" gap="small">
            <Text weight="bold">Course Language:</Text>
            <Text>{session.CourseLanguage}</Text>
          </Box>
          <Box direction="row" gap="small">
            <Text weight="bold">Session Date:</Text>
            <Text>{session.createdAt.split("T")[0]}</Text>
          </Box>
        </Box>
        <Box direction="column" gap="small">
          <Button
            label="Unlock Exam"
            primary
            color="black"
            onClick={unlockExam}
          />
          <Button label="Lock Exam" onClick={lockExam} />
        </Box>
      </Box>
      <Box
        direction="column"
        gap="small"
        width="small"
        margin={{ bottom: "small" }}
      >
        <Button label="Finish Exam" primary onClick={finishExam} />
      </Box>
    </Box>
  );
}
