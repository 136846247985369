import React from "react";
import { Box, Grid } from "grommet";

function OtWebcam(props) {
  const webStyle = {
    width: "auto",
    height: "250px",
  };

  return (
    <>
      <Box align="center" justify="start" fill="vertical" responsive={true}>
        <Grid
          columns={["1fr", "1fr", "1fr", "1fr", "1fr"]}
          rows={["250px", "250px", "250px"]}
          gap="small"
          fill={true}
          margin="xxsmall"
          responsive={true}
        >
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${
              props.embed
            }&room=${props.roomID + props.rooms[0]}&iframe=true`}
            style={webStyle}
            className="webcam1"
            scrolling="auto"
            allow="microphone; camera"
            title="room101"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${
              props.embed
            }&room=${props.roomID + props.rooms[1]}&iframe=true`}
            style={webStyle}
            className="webcam2"
            scrolling="auto"
            allow="microphone; camera"
            title="room102"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${
              props.embed
            }&room=${props.roomID + props.rooms[2]}&iframe=true`}
            style={webStyle}
            className="webcam3"
            scrolling="auto"
            allow="microphone; camera"
            title="room103"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${
              props.embed
            }&room=${props.roomID + props.rooms[3]}&iframe=true`}
            style={webStyle}
            className="webcam4"
            scrolling="auto"
            allow="microphone; camera"
            title="room104"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${
              props.embed
            }&room=${props.roomID + props.rooms[4]}&iframe=true`}
            style={webStyle}
            className="webcam5"
            scrolling="auto"
            allow="microphone; camera"
            title="room105"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${
              props.embed
            }&room=${props.roomID + props.rooms[5]}&iframe=true`}
            style={webStyle}
            className="webcam6"
            scrolling="auto"
            allow="microphone; camera"
            title="room106"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${
              props.embed
            }&room=${props.roomID + props.rooms[6]}&iframe=true`}
            style={webStyle}
            className="webcam7"
            scrolling="auto"
            allow="microphone; camera"
            title="room107"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${
              props.embed
            }&room=${props.roomID + props.rooms[7]}&iframe=true`}
            style={webStyle}
            className="webcam8"
            scrolling="auto"
            allow="microphone; camera"
            title="room108"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${
              props.embed
            }&room=${props.roomID + props.rooms[8]}&iframe=true`}
            style={webStyle}
            className="webcam9"
            scrolling="auto"
            allow="microphone; camera"
            title="room109"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${
              props.embed
            }&room=${props.roomID + props.rooms[9]}&iframe=true`}
            style={webStyle}
            className="webcam10"
            scrolling="auto"
            allow="microphone; camera"
            title="room1010"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${
              props.embed
            }&room=${props.roomID + props.rooms[10]}&iframe=true`}
            style={webStyle}
            className="webcam11"
            scrolling="auto"
            allow="microphone; camera"
            title="room1011"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${
              props.embed
            }&room=${props.roomID + props.rooms[11]}&iframe=true`}
            style={webStyle}
            className="webcam12"
            scrolling="auto"
            allow="microphone; camera"
            title="room1012"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${
              props.embed
            }&room=${props.roomID + props.rooms[12]}&iframe=true`}
            style={webStyle}
            className="webcam13"
            scrolling="auto"
            allow="microphone; camera"
            title="room1013"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${
              props.embed
            }&room=${props.roomID + props.rooms[13]}&iframe=true`}
            style={webStyle}
            className="webcam14"
            scrolling="auto"
            allow="microphone; camera"
            title="room1014"
          ></iframe>
          <iframe
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${
              props.embed
            }&room=${props.roomID + props.rooms[14]}&iframe=true`}
            style={webStyle}
            className="webcam15"
            scrolling="auto"
            allow="microphone; camera"
            title="room1015"
          ></iframe>
        </Grid>
      </Box>
    </>
  );
}

export default OtWebcam;
