import React, { useState } from "react";
import TesterCard from "../Components/TesterCard";
import { API } from "aws-amplify";
import { getTwilioAccessToken, listRooms } from "../graphql/queries";
import { Box, Text, Button, Form, FormField, Main, TextInput } from "grommet";
import { v4 as uuidv4 } from "uuid";
import VideoProvider from "../testing-twill/src/components/VideoProvider";

function NewTesterHome() {
  const [rName, setRName] = useState("");
  const [isRName, setIsRName] = useState(false);
  const [embedCode, setEmbedCode] = useState(null);
  const [token, setToken] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    grabRoom(rName);
  };

  const handleInput = (e) => {
    setRName(e.target.value.toLowerCase());
    if (isRName === true) {
      setIsRName(false);
    }
  };

  async function grabRoom(rid) {
    try {
      let str = rid.split("");
      let qString = str[0] + str[1] + str[2] + str[3];
      const roomData = await API.graphql({
        query: listRooms,
        variables: { filter: { proctorCode: { eq: qString } } },
        authMode: "API_KEY",
      });
      const embedData = roomData.data.listRooms.items;
      setEmbedCode(embedData[0].embedCode);
      grabToken();
      setIsRName(true);
    } catch (err) {
      console.log(err);
      setIsRName(false);
      alert("Invalid Room ID");
    }
  }

  async function grabToken() {
    try {
      const tokenData = await API.graphql({
        query: getTwilioAccessToken,
        variables: {
          body: JSON.stringify({
            identity: uuidv4(),
            room: rName,
          }),
        },
        authMode: "API_KEY",
      });

      setToken(tokenData.data.getTwilioAccessToken);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <Box align="stretch" justify="center">
        <Main align="center">
          <Box align="center" justify="center" pad="medium">
            <Form onSubmit={handleSubmit}>
              <FormField>
                <TextInput
                  placeholder="Enter Room ID:"
                  onChange={handleInput}
                  textAlign="center"
                />
              </FormField>
              <Box align="center" justify="center">
                <Button type="submit" label="Submit" primary color="#BFBFBF" />
              </Box>
            </Form>
          </Box>
          <Box align="center" justify="center" pad="small" fill="vertical">
            {isRName ? (
              <VideoProvider token={token} roomId={rName} />
            ) : (
              <Text>Please enter the Room ID given to you by your proctor</Text>
            )}
          </Box>
        </Main>
      </Box>
    </>
  );
}

export default NewTesterHome;
