import { API } from "aws-amplify";
import {
  Accordion,
  AccordionPanel,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Text,
  Notification,
} from "grommet";
import React, { useState } from "react";
import { updateDbtTester } from "../graphql/mutations";
import { dbtExamGet, dbtExamPost } from "../graphql/queries";

function DbtCodeCard({ name, email, appointment, deliveryId, ident, exam }) {
  const [proctorToken, setProctorToken] = useState("");
  const [takeToken, setTakeToken] = useState("");
  const [isToken, setIsToken] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [examineeId, setExamineeId] = useState(deliveryId);
  const [show, setShow] = useState(false);

  console.log(name, email, appointment, deliveryId, ident, exam);

  async function getToken() {
    if (isToken) {
      setIsToken(false);
    }
    if (examineeId) {
      try {
        const data = await API.graphql({
          query: dbtExamGet,
          variables: {
            id: examineeId,
            exam: exam,
          },
        });
        if (data.data.dbtExamGet) {
          console.log(data.data.dbtExamGet);
          const str = JSON.parse(data.data.dbtExamGet);
          console.log(str);
          setProctorToken(
            "https://scorpion.caveon.com/proctor/" + str.proctor_token
          );
          setTakeToken(
            "https://scorpion.caveon.com/take?launch_token=" + str.launch_token
          );
          setIsToken(true);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const data = await API.graphql({
          query: dbtExamPost,
          variables: {
            name: name,
            email: email,
            id: ident,
            exam: exam,
          },
        });
        if (data.data.dbtExamPost) {
          console.log(data.data.dbtExamPost);
          const str = JSON.parse(data.data.dbtExamPost);
          console.log(str);
          setProctorToken(
            "https://scorpion.caveon.com/proctor/" + str.proctor_token
          );
          setTakeToken(
            "https://scorpion.caveon.com/take?launch_token=" + str.launch_token
          );
          setExamineeId(str.examinee_id);
          setIsToken(true);
          const newData = await API.graphql({
            query: updateDbtTester,
            variables: {
              input: {
                id: ident,
                deliveryId: str.examinee_id,
              },
            },
          });
          console.log(newData);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  function handleClick() {
    navigator.clipboard.writeText(takeToken);
    setIsCopied(true);
  }

  return (
    <>
      <Box>
        {isCopied && (
          <Notification
            status="normal"
            toast
            onClose={() => setIsCopied(false)}
            title="Copied!"
            message="Link Copied To Clipboard, please send to the tester!"
          />
        )}
      </Box>
      <Card pad="medium" width="medium">
        <CardHeader>
          <Heading textAlign="center" level="3">
            {name}
          </Heading>
        </CardHeader>
        <CardBody>
          <Box direction="row" gap="small">
            <Text weight="bold" size="large">
              Email:{" "}
            </Text>
            <Text size="large">{email}</Text>
          </Box>
          <Box direction="row" gap="small">
            <Text weight="bold" size="large">
              Start Date/Time:{" "}
            </Text>
            <Text size="large">{appointment}</Text>
          </Box>
          {isToken && (
            <Box>
              <Accordion>
                <AccordionPanel label="Tester Link">
                  <Box pad="medium" background="light-2" wrap={true}>
                    <Button label="Copy Tester Link" onClick={handleClick} />
                    <Button
                      label="Show Tester Link"
                      onClick={() => setShow(true)}
                    />
                    {show && (
                      <Box>
                        <Text size="small">{takeToken}</Text>
                      </Box>
                    )}
                  </Box>
                </AccordionPanel>
                <AccordionPanel label="Proctor Link">
                  <Box pad="medium" background="light-2">
                    <Button
                      label="Open Proctor Link"
                      onClick={() => window.open(proctorToken, "_blank")}
                    />
                  </Box>
                </AccordionPanel>
              </Accordion>
            </Box>
          )}
        </CardBody>
        <CardFooter align="center" justify="center" pad="small">
          <Button
            label="Generate Exam Links"
            primary
            color="#BFBFBF"
            onClick={getToken}
          />
        </CardFooter>
      </Card>
    </>
  );
}

export default DbtCodeCard;
