import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { API } from "aws-amplify";
import {
  getCmsNonce,
  getCmsScheduleDetails,
  getCmsSessionByRegistrationId,
} from "../graphql/queries";
import {
  createCmsSession,
  updateCmsNonce,
  updateCmsSession,
} from "../graphql/mutations";
import { Box, Button, Notification, Text } from "grommet";
import { v4 as uuidv4 } from "uuid";

function CmsEntryPoint() {
  const [nonceError, setNonceError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [cont, setCont] = useState(true);
  const [exam, setExam] = useState({});
  const [candidate, setCandidate] = useState({});
  const [registration, setRegistration] = useState({});
  const { search } = useLocation();
  const { exam_id, user_id, registration_id, nonce_id } =
    queryString.parse(search);

  useEffect(() => {
    // ISO 8601 date format of today
    const today = new Date();
    const todayISO = today.toISOString();
    const todayDate = todayISO.split("T")[0];
    // check if nonce is valid

    async function entry() {
      try {
        var data = await API.graphql({
          query: getCmsNonce,
          variables: {
            nonce_id: nonce_id,
          },
          authMode: "API_KEY",
        });
      } catch (error) {
        console.log(error);
        setNonceError(true);
      } finally {
        if (data.data.getCmsNonce.expired === false) {
          await API.graphql({
            query: updateCmsNonce,
            variables: {
              input: {
                nonce_id: nonce_id,
                expired: true,
              },
            },
            authMode: "API_KEY",
          });
        } else {
          setNonceError(true);
        }
      }

      try {
        const sessionDetails = await API.graphql({
          query: getCmsScheduleDetails,
          variables: {
            exam_id: exam_id,
            user_id: user_id,
            registration_id: registration_id,
          },
          authMode: "API_KEY",
        });

        let response = true;
        if (sessionDetails.data.getCmsExam !== null) {
          setExam(sessionDetails.data.getCmsExam);
        } else {
          response = false;
        }
        setCandidate(sessionDetails.data.getCmsCandidate);
        if (sessionDetails.data.getCmsCandidate !== null) {
          setCandidate(sessionDetails.data.getCmsCandidate);
        } else {
          response = false;
        }
        if (sessionDetails.data.getCmsRegistration !== null) {
          setRegistration(sessionDetails.data.getCmsRegistration);
        } else {
          response = false;
        }

        if (response) {
          // get appointment data and compare to today date
          const appointmentDate =
            sessionDetails.data.getCmsRegistration.appointment.exam_date.split(
              "T"
            )[0];
          if (appointmentDate === todayDate && nonceError !== true) {
            setCont(true);
          } else {
            setDateError(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    // check if exam is active
    entry();
    // check if appointment day matches today
  }, []);

  async function handleClick() {
    // create session
    //check that a session doesn't already exist

    try {
      const currentSession = await API.graphql({
        query: getCmsSessionByRegistrationId,
        variables: {
          registration_id: registration_id,
        },
        authMode: "API_KEY",
      });
      console.log(currentSession);
      if (currentSession.data.sessionByRegistrationId.items[0] !== undefined) {
        await API.graphql({
          query: updateCmsSession,
          variables: {
            input: {
              session_id:
                currentSession.data.sessionByRegistrationId.items[0].session_id,
              progress: "queue",
            },
          },
          authMode: "API_KEY",
        });

        window.open(
          "https://tawk.to/chat/6318ee5954f06e12d8935af2/1gccm63qf",
          "_blank"
        );
      } else {
        const session = await API.graphql({
          query: createCmsSession,
          variables: {
            input: {
              session_id: uuidv4(),
              exam_id: exam_id,
              user_id: user_id,
              registration_id: registration_id,
              email_address: candidate.email_address,
              first_name: candidate.first_name,
              last_name: candidate.last_name,
              candidate_id: candidate.candidate_id,
              progress: "queue",
            },
          },
          authMode: "API_KEY",
        });
        console.log(session);

        if (session.data.createCmsSession.session_id !== null) {
          // open tawk in new tab
          window.open(
            "https://tawk.to/chat/6318ee5954f06e12d8935af2/1gccm63qf",
            "_blank"
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
    // redirect to tawk
  }

  return (
    <Box align="center" justify="center">
      {nonceError && (
        <Notification
          toast
          status="critical"
          title="Error"
          message="The link you are trying to access is invalid or has expired."
        />
      )}
      {cont && (
        <Box align="center" justify="center" gap="medium">
          <Text textAlign="center" weight="bold" size="larger">
            If you are ready to test, click below to connect to your proctor
          </Text>
          <Button
            type="button"
            label="Connect to Proctor"
            onClick={handleClick}
          />
        </Box>
      )}
    </Box>
  );
}

export default CmsEntryPoint;
