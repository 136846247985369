import { API } from "aws-amplify";
import { Heading, Box, TextInput, Button, Notification } from "grommet";
import React, { useState } from "react";
import { useUser } from "../AuthContext";
import PaymentCard from "../Components/PaymentCard";
import {
  getPaidTesterByRef,
  getPaidTesterByEmail,
  getPaidTesterByLastFour,
} from "../graphql/queries";
import { Search } from "grommet-icons";

function PaymentValidation() {
  const [testers, setTesters] = useState([]);
  const [refId, setRefId] = useState("");
  const [email, setEmail] = useState("");
  const [lastFour, setLastFour] = useState("");
  const [searchError, setSearchError] = useState(false);
  const [blankError, setBlankError] = useState(false);
  const [isTesters, setIsTesters] = useState(false);
  const myUser = useUser();

  const handleClick = async () => {
    setIsTesters(false);
    setTesters([]);
    if (refId.length > 0) {
      await handleRefSearch(refId);
    } else if (email.length > 0) {
      await handleEmailSearch(email);
    } else if (lastFour.length > 0) {
      await handleLastFourSearch(lastFour);
    } else {
      setBlankError(true);
    }
    setRefId("");
    setEmail("");
    setLastFour("");
  };

  const handleRefSearch = async (val) => {
    try {
      const response = await API.graphql({
        query: getPaidTesterByRef,
        variables: {
          referenceId: val,
        },
      });

      if (response.data.testerByReferenceId.items.length > 0) {
        setTesters(response.data.testerByReferenceId.items);
        setIsTesters(true);
      } else {
        setSearchError(true);
      }
    } catch (error) {
      setSearchError(true);
      console.log(error);
    }
  };

  const handleEmailSearch = async (val) => {
    try {
      const response = await API.graphql({
        query: getPaidTesterByEmail,
        variables: {
          cust_email: val,
        },
      });
      console.log(response);
      if (response.data.testerByEmail.items.length > 0) {
        setTesters(response.data.testerByEmail.items);
        setIsTesters(true);
      } else {
        setSearchError(true);
      }
    } catch (error) {
      setSearchError(true);
      console.log(error);
    }
  };

  const handleLastFourSearch = async (val) => {
    try {
      const response = await API.graphql({
        query: getPaidTesterByLastFour,
        variables: {
          lastFour: val,
        },
      });
      console.log(response);
      if (response.data.testerByLastFour.items.length > 0) {
        setTesters(response.data.testerByLastFour.items);
        setIsTesters(true);
      } else {
        setSearchError(true);
      }
    } catch (error) {
      setSearchError(true);
      console.log(error);
    }
  };

  return (
    <Box
      align="center"
      justify="center"
      direction="column"
      fill="horizontal"
      pad="medium"
    >
      {searchError && (
        <Notification
          toast
          title="Error"
          status="critical"
          onClose={() => setSearchError(false)}
          message="Order not found, please try again"
        />
      )}
      {blankError && (
        <Notification
          toast
          title="Error"
          status="critical"
          onClose={() => setBlankError(false)}
          message="Please enter an Order Number, Email, or Last 4 of Credit Card"
        />
      )}
      <Box align="center" justify="center" fill={true} flex pad="small">
        <Heading textAlign="center" level="3" fill>
          Search for an order by Order Number, Email, or Last 4 digits of a
          Credit Card
        </Heading>
      </Box>
      <Box
        align="center"
        justify="center"
        direction="row"
        flex
        gap="medium"
        width="xlarge"
      >
        <TextInput
          placeholder="Order Number"
          value={refId}
          onChange={(e) => setRefId(e.target.value)}
        />
        <TextInput
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextInput
          placeholder="Last 4 of CC"
          value={lastFour}
          onChange={(e) => setLastFour(e.target.value)}
        />

        <Button
          onClick={handleClick}
          hoverIndicator="light-2"
          icon={<Search />}
          label="Search"
          color="light-4"
          primary
          fill="vertical"
          size="large"
        />
      </Box>
      <Box align="center" justify="center" pad="small">
        <Box
          align="center"
          justify="center"
          direction="row-responsive"
          gap="small"
          pad="small"
          wrap="true"
        >
          {isTesters ? (
            testers.map((itm, idx) => (
              <PaymentCard
                test={itm}
                key={idx}
                user={myUser.user.attributes.email}
              />
            ))
          ) : (
            <Box></Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default PaymentValidation;
