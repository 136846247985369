import React from "react";
import { Card, CardBody, CardHeader, Text, Button } from "grommet";
import { API } from "aws-amplify";
import { updateWebcamLink } from "../graphql/mutations";
//SammyCamUserCard
//Used to identify who is where in Sammy Cams for those watching
//Receives Proctor, Tester, RoomID, EmbedId
//Has "Finish Button"
function SammyCamCard(users) {
  // function to finish tester
  async function finishTester(id) {
    try {
      await API.graphql({
        query: updateWebcamLink,
        variables: {
          input: {
            id: id,
            proctor: null,
            tester: null,
            status: false,
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      {users.users.map((user, index) => {
        return (
          <Card key={index}>
            <CardHeader
              align="center"
              direction="row-responsive"
              flex={false}
              justify="center"
              gap="medium"
              pad="small"
            ></CardHeader>
            <CardBody pad="medium" align="center">
              <Text textAlign="start">Proctor: {user.proctor}</Text>
              <Text textAlign="start">Tester: {user.tester} </Text>
              <Text textAlign="start">Room: {user.roomid} </Text>
              <Button
                label="Open Room"
                onClick={() =>
                  window.open(
                    `/sc?embed=${user.embedid}&roomid=${user.roomid}`,
                    "_blank"
                  )
                }
              />
              <Button
                label="Finish Tester"
                onClick={() => finishTester(user.id)}
              />
            </CardBody>
          </Card>
        );
      })}
    </>
  );
}

export default SammyCamCard;
