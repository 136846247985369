import { useState, useCallback } from "react";
import Video from "twilio-video";
export default function useToggleCamera(room, initialState) {
  const [isCameraEnabled, setIsCameraEnabled] = useState(initialState);

  const toggleCamera = useCallback(() => {
    if (room) {
      if (isCameraEnabled) {
        room.localParticipant.videoTracks.forEach((publication) => {
          publication.unpublish();
          publication.track.stop();
        });

        setIsCameraEnabled(false);
      } else {
        Video.createLocalVideoTrack({ width: 100, height: 100 }).then(
          (track) => {
            room.localParticipant.publishTrack(track);
          }
        );
        setIsCameraEnabled(true);
      }
    }
  }, [isCameraEnabled, room]);

  return { isCameraEnabled, toggleCamera };
}
